import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useState, useEffect} from "react";
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import CampChefProSeriesDeluxe2BurnerCampStovePic from './images/CampChefProSeriesDeluxe2BurnerCampStove.png';
import CampChefDeluxeOutdoorCampOvenAndStovePic from './images/CampChefDeluxeOutdoorCampOvenAndStove.png';
import CampChefAlpineHeavyDutyCylinderStovePic from './images/CampChefAlpineHeavyDutyCylinderStove.png';
import GSIOutdoorsGlacierCampStovePic from './images/GSIOutdoorsGlacierCampStove.png';
import CampChefProSeriesDeluxe3BurnerCampStovePic from './images/CampChefProSeriesDeluxe3BurnerCampStove.png';


function CampingStoves () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  //console.log(isSmallScreen);

  const items = [
        {
      manuLink:"https://www.campchef.com/camp-chef-pro-60x-two-burner-cook-system/CC-PRO60X.html",
      imageAlt:"Camp Chef Pro Series Deluxe 2-Burner Camp Stove",
      imageRef:CampChefProSeriesDeluxe2BurnerCampStovePic,
      itemLink:"../Camping/CampingStoves/CampChefProSeriesDeluxe2BurnerCampStove",
      itemBoldText:"Camp Chef Pro Series Deluxe 2-Burner Camp Stove:",
      itemText:"The Camp Chef Pro Series Deluxe 2-Burner Camp Stove is a high-performance outdoor cooking appliance designed for camping, tailgating, and other outdoor activities. It features two powerful burners, each capable of producing up to 30,000 BTUs, providing ample heat for cooking a variety of meals. The stove is built with a durable, rugged design to withstand outdoor conditions and includes features like a matchless ignition system, adjustable heat-control dials, and removable legs for easy transport and storage. It also comes with a three-sided windscreen to protect the flames from wind, ensuring consistent cooking performance."
    },
	    {
      manuLink:"https://www.campchef.com/discontinued-products/deluxe-outdoor-oven/CC-COVEND.html",
      imageAlt:"Camp Chef Deluxe Outdoor Camp Oven And Stove",
      imageRef:CampChefDeluxeOutdoorCampOvenAndStovePic,
      itemLink:"../Camping/CampingStoves/CampChefDeluxeOutdoorCampOvenAndStove",
      itemBoldText:"Camp Chef Deluxe Outdoor Camp Oven And Stove:",
      itemText:"The Camp Chef Deluxe Outdoor Camp Oven and Stove is a versatile cooking appliance designed for outdoor enthusiasts. It combines a dual-burner stovetop with a built-in oven, allowing users to prepare a wide range of meals while camping, tailgating, or enjoying other outdoor activities. The unit is powered by propane and features a compact, portable design that makes it easy to transport and set up in various outdoor settings."
    },
	    {
      manuLink:"https://www.campchef.com/shop/fire-pits/wood-fire-pits/wood-firepits/wood-fire-pits-shop/alpine-heavy-duty-cylinder-system/CC-CS14.html",
      imageAlt:"Camp Chef Alpine Heavy Duty Cylinder Stove",
      imageRef:CampChefAlpineHeavyDutyCylinderStovePic,
      itemLink:"../Camping/CampingStoves/CampChefAlpineHeavyDutyCylinderStove",
      itemBoldText:"Camp Chef Alpine Heavy Duty Cylinder Stove:",
      itemText:"The Camp Chef Alpine Heavy Duty Cylinder Stove is a robust and versatile wood-burning stove designed for outdoor use, particularly in camping and hunting scenarios. It is constructed from durable steel and features a large cooking surface, making it ideal for heating tents and cooking meals in the wilderness. The stove includes adjustable legs for uneven ground, a wide door for easy wood loading, and a chimney pipe for efficient smoke ventilation."
    },
	    {
      manuLink:"https://gsioutdoors.com/products/glacier-camp-stove?srsltid=AfmBOorK9CJVdPCux2x35hEfm9w9s7hVYfmMDOLvW4J5qJVFXhsxf35B",
      imageAlt:"GSI Outdoors Glacier Camp Stove",
      imageRef:GSIOutdoorsGlacierCampStovePic,
      itemLink:"../Camping/CampingStoves/GSIOutdoorsGlacierCampStove",
      itemBoldText:"GSI Outdoors Glacier Camp Stove:",
      itemText:"The GSI Outdoors Glacier Camp Stove is a compact, durable, and efficient camping stove designed for outdoor enthusiasts. It features a robust stainless steel construction, a powerful burner, and a compact design that makes it easy to transport and set up. This stove is ideal for backpackers, campers, and adventurers who need a reliable cooking solution in the wilderness."
    },
	    {
      manuLink:"https://www.campchef.com/camp-chef-pro-90x-three-burner-cooking-system/CC-PRO90X.html",
      imageAlt:"Camp Chef Pro Series Deluxe 3-Burner Camp Stove",
      imageRef:CampChefProSeriesDeluxe3BurnerCampStovePic,
      itemLink:"../Camping/CampingStoves/CampChefProSeriesDeluxe3BurnerCampStove",
      itemBoldText:"Camp Chef Pro Series Deluxe 3-Burner Camp Stove:",
      itemText:"The Camp Chef Pro Series Deluxe 3-Burner Camp Stove is a versatile outdoor cooking appliance designed for camping, tailgating, and other outdoor activities. It features three powerful burners, a large cooking surface, and a durable construction. The stove is known for its high heat output, ease of use, and portability, making it a popular choice among outdoor enthusiasts."
    },
	
    ];

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   {/* <div sx={{marginLeft: isSmallScreen ? '20px': '20px', marginTop: '150px', marginRight: '20px'}}> */}
    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1">
    <h1>Camping Stoves</h1>
      Cooking outdoors is special. Whether it is making breakfast first thing in the morning or cooking steaks at night. Very few things are as relaxing as cooking outdoors. 
      Having the right camp stove can make the difference between having a relaxing or frustrating time. Here are some camp stoves that have made it to the top of the list.

    </Typography>
    <br/>

    {items.map((element)=> (
      <div>
    <Paper elevation={3}
      sx={{
        p: 2,
        margin: 'auto',
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
    <Grid container spacing={12} rowSpacing={1}>
    <Grid item>
    <Link href={element.menuLink} target="_blank">
      <ButtonBase sx={{ width: 128, height: 128 }}>
      <img alt={element.imageAlt} height="160px" className='GridImage' src={element.imageRef}/>
      </ButtonBase>
      </Link>
    </Grid>
    <Grid item xs={12} sm container>
      <Grid item xs container direction="column" spacing={9}>
        <Grid item xs>
          <Typography gutterBottom variant="subtitle1" component="div">
          <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
          
          </Typography>
          <Typography align="center">
            <Link href={element.manuLink} target="_blank">
          <Button variant="contained">More Product Info</Button>
          </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  </Paper>
  <br/>
  </div>
  ))}

    </div>
    </>
  );
}

export default CampingStoves;

