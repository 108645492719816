import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import GarminGPSMAP67HandheldGPSPic from '../images/GarminGPSMAP67HandheldGPS.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function GarminGPSMAP67HandheldGPS () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Garmin GPSMAP 67 Handheld GPS</h1>

            <img alt="Garmin GPSMAP 67 Handheld GPS" height="130px" src={GarminGPSMAP67HandheldGPSPic}/>

    <Typography align="left" variant="body1">
        The Garmin GPSMAP 67 is a rugged and versatile handheld GPS device designed for outdoor enthusiasts, hikers, hunters, and geocachers. It offers advanced navigation features, robust connectivity options, and a durable design to withstand harsh environments. Equipped with multi-band GNSS support, preloaded maps, and a long battery life, the GPSMAP 67 aims to be a reliable companion for all your outdoor adventures.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Multi-Band GNSS Support:</b> Provides accurate positioning by accessing multiple satellite systems (GPS, GLONASS, Galileo, etc.), enhancing reliability in challenging environments.</li>
<li className="ListItemStyle"><b>Preloaded Maps:</b> Comes with TopoActive maps, offering detailed terrain information, points of interest, and routable roads and trails.</li>
<li className="ListItemStyle"><b>Long Battery Life:</b> Offers extended battery life, which is crucial for long trips or multi-day excursions.</li>
<li className="ListItemStyle"><b>Rugged Design:</b> Built to withstand harsh conditions with a durable, water-resistant (IPX7) design.</li>
<li className="ListItemStyle"><b>Large Display:</b> Features a bright, sunlight-readable 3-inch display, making it easy to view maps and data even in bright conditions.</li>
<li className="ListItemStyle"><b>Wireless Connectivity:</b> Supports Bluetooth and ANT+ for connecting to other devices and sensors, such as heart rate monitors and temperature sensors.</li>
<li className="ListItemStyle"><b>Customizable Data Fields:</b> Allows users to customize the data fields to display the information most relevant to their activities.</li>
<li className="ListItemStyle"><b>Geocaching Friendly:</b> Supports paperless geocaching, allowing users to download geocache details directly to the device.</li>
<li className="ListItemStyle"><b>Navigation Features:</b> Includes advanced navigation tools such as a 3-axis compass, barometric altimeter, and multiple waypoint management options.</li>
<li className="ListItemStyle"><b>Garmin Explore App Compatibility:</b> Can sync with the Garmin Explore app for trip planning, data management, and sharing adventures.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Price:</b> The GPSMAP 67 is relatively expensive compared to other handheld GPS devices on the market.</li>
<li className="ListItemStyle"><b>Weight:</b> It is heavier than some other handheld GPS units, which might be a concern for ultralight backpackers.</li>
<li className="ListItemStyle"><b>Complexity:</b> The multitude of features and settings can be overwhelming for beginners or those not tech-savvy.</li>
<li className="ListItemStyle"><b>Limited Internal Storage:</b> Although it has some internal storage, users may need to purchase additional microSD cards for more extensive map data.</li>
<li className="ListItemStyle"><b>Slow Initial Setup:</b> Initial setup and software updates can be time-consuming.</li>
<li className="ListItemStyle"><b>Battery Type:</b> Uses AA batteries, which some users might find less convenient compared to rechargeable built-in batteries.</li>
<li className="ListItemStyle"><b>Screen Resolution:</b> While the display is large and readable, the resolution is not as high as some modern smartphones or other GPS devices.</li>
<li className="ListItemStyle"><b>Software Bugs:</b> Some users have reported occasional software bugs or glitches that require firmware updates to resolve.</li>
<li className="ListItemStyle"><b>Learning Curve:</b> The device has a steep learning curve due to its extensive features and capabilities.</li>
<li className="ListItemStyle"><b>Limited Touchscreen Functionality:</b> Unlike some modern devices, the GPSMAP 67 does not have a touchscreen, which can make navigation through menus slower.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GarminGPSMAP67HandheldGPS;

