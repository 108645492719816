import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useState, useEffect} from "react";
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import AscendHex0LongMummySleepingBagPic from './images/AscendHex0LongMummySleepingBag.png';
import AscendHoodoo20MummySleepingBagPic from './images/AscendHoodoo20MummySleepingBag.png';
import AscendHex0RegularMummySleepingBagPic from './images/AscendHex0RegularMummySleepingBag.png';
import StansportGlacier0MummySleepingBagPic from './images/StansportGlacier0MummySleepingBag.png';
import StansportGrizzly10CanvasSleepingBagPic from './images/StansportGrizzly10CanvasSleepingBag.png';


function SleepingBags () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.cabelas.com/shop/en/ascend-hex-0-long-mummy-sleeping-bag",
      imageAlt:"Ascend Hex 0º Long Mummy Sleeping Bag",
      imageRef:AscendHex0LongMummySleepingBagPic,
      itemLink:"../Camping/SleepingBags/AscendHex0LongMummySleepingBag",
      itemBoldText:"Ascend Hex 0º Long Mummy Sleeping Bag:",
      itemText:"The Ascend Hex 0º Long Mummy Sleeping Bag is designed for outdoor enthusiasts who need reliable warmth in cold weather conditions. This sleeping bag features a mummy shape, which is known for its efficiency in retaining heat. It typically includes features such as a durable outer shell, high-quality insulation, a hood for added warmth, and a draft collar to prevent heat loss. The 0º rating indicates that it is suitable for temperatures as low as 0 degrees Fahrenheit, making it ideal for winter camping or high-altitude adventures."
    },
	    {
      manuLink:"https://www.cabelas.com/shop/en/ascend-hoodoo-20-mummy-sleeping-bag",
      imageAlt:"Ascend Hoodoo 20º Mummy Sleeping Bag",
      imageRef:AscendHoodoo20MummySleepingBagPic,
      itemLink:"../Camping/SleepingBags/AscendHoodoo20MummySleepingBag",
      itemBoldText:"Ascend Hoodoo 20º Mummy Sleeping Bag:",
      itemText:"The Ascend Hoodoo 20º Mummy Sleeping Bag is designed for outdoor enthusiasts who need reliable warmth and comfort in cooler conditions. This mummy-style sleeping bag is rated for temperatures as low as 20ºF, making it suitable for three-season use. It features a snug fit to retain body heat, a durable outer shell, and a soft inner lining for added comfort. The sleeping bag also includes a hood for extra warmth around the head and face, and it typically comes with a compression sack for easy packing and transport."
    },
	    {
      manuLink:"https://www.cabelas.com/shop/en/ascend-hex-0-mummy-sleeping-bag?searchTerm=Ascend%20Hex%200%C2%BA%20Regular%20Mummy%20Sleeping%20Bag",
      imageAlt:"Ascend Hex 0º Regular Mummy Sleeping Bag",
      imageRef:AscendHex0RegularMummySleepingBagPic,
      itemLink:"../Camping/SleepingBags/AscendHex0RegularMummySleepingBag",
      itemBoldText:"Ascend Hex 0º Regular Mummy Sleeping Bag:",
      itemText:"The Ascend Hex 0º Regular Mummy Sleeping Bag is designed for cold-weather camping, offering warmth and comfort in temperatures as low as 0ºF. It features a mummy-style design to maximize heat retention, and is filled with synthetic insulation to provide reliable warmth even in damp conditions. The bag includes a durable outer shell, a soft inner lining, and a range of features aimed at enhancing user comfort and convenience."
    },
	    {
      manuLink:"https://stansport.com/3-1-lbs-glacier-sleeping-bag-518-100/",
      imageAlt:"Stansport Glacier 0° Mummy Sleeping Bag",
      imageRef:StansportGlacier0MummySleepingBagPic,
      itemLink:"../Camping/SleepingBags/StansportGlacier0MummySleepingBag",
      itemBoldText:"Stansport Glacier 0° Mummy Sleeping Bag:",
      itemText:"The Stansport Glacier 0° Mummy Sleeping Bag is designed for cold-weather camping, offering warmth and comfort in temperatures as low as 0°F. This sleeping bag features a mummy-style design that tapers towards the feet to minimize heat loss and includes a hood to keep your head warm. It is filled with high-quality insulation and has a durable outer shell to withstand rugged outdoor conditions. The sleeping bag also includes a draft tube along the zipper to prevent cold air from seeping in."
    },
	    {
      manuLink:"https://stansport.com/6-lbs-grizzly-sleeping-bag-529-100/",
      imageAlt:"Stansport Grizzly -10° Canvas Sleeping Bag",
      imageRef:StansportGrizzly10CanvasSleepingBagPic,
      itemLink:"../Camping/SleepingBags/StansportGrizzly10CanvasSleepingBag",
      itemBoldText:"Stansport Grizzly -10° Canvas Sleeping Bag:",
      itemText:"The Stansport Grizzly -10° Canvas Sleeping Bag is a heavy-duty, cold-weather sleeping bag designed for outdoor enthusiasts who need reliable warmth in extreme conditions. This sleeping bag features a rugged canvas shell, a soft flannel lining, and a generous amount of insulation to keep users warm in temperatures as low as -10°F. It is designed for durability and comfort, making it suitable for camping, hunting, and other outdoor activities in cold climates."
    },
	
    ];

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
      <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1">
    <h1>Sleeping Bags</h1>
    Have you ever been spending the night outdoors without a sleeping bag warm enough for the cold? I have, and it isn’t very much fun. Sleeping bags are a must have when sleeping outdoors, even in the summer in some parts of the world. Below are some great options for keeping you warm on those cold nights.

    </Typography>
    <br/>

    {items.map((element)=> (
      <div>
    <Paper elevation={3}
      sx={{
        p: 2,
        margin: 'auto',
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
    <Grid container spacing={12} rowSpacing={1}>
    <Grid item>
    <Link href={element.menuLink} target="_blank">
      <ButtonBase sx={{ width: 128, height: 128 }}>
      <img alt={element.imageAlt} height="160px" className='GridImage' src={element.imageRef}/>
      </ButtonBase>
      </Link>
    </Grid>
    <Grid item xs={12} sm container>
      <Grid item xs container direction="column" spacing={9}>
        <Grid item xs>
          <Typography gutterBottom variant="subtitle1" component="div">
          <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
          
          </Typography>
          <Typography align="center">
            <Link href={element.manuLink} target="_blank">
          <Button variant="contained">More Product Info</Button>
          </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  </Paper>
  <br/>
  </div>
  ))}

    </div>
    </>
  );
}

export default SleepingBags;

