import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import GCIOutdoorFreestyleRockerCampChairPic from '../images/GCIOutdoorFreestyleRockerCampChair.png';
import { useState, useEffect} from "react";

function GCIOutdoorFreestyleRockerCampChair () {
  const [mobileOpen, setMobileOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{ marginLeft:"20px", marginTop: "150px" , marginRight:"20px"}}>
    <table>
        <tr>
          <td>
          <h1>GCI Outdoor Freestyle Rocker Camp Chair</h1>
          </td>
          <td>
            <img alt="GCI Outdoor Freestyle Rocker Camp Chair" width="50%" height="50%" src={GCIOutdoorFreestyleRockerCampChairPic}/>
          </td>
        </tr>
      </table>
    <Typography align="left" variant="body1">
        The GCI Outdoor Freestyle Rocker Camp Chair is a portable, folding rocking chair designed for outdoor activities such as camping, tailgating, and backyard gatherings. It features a patented spring-action rocking technology, a sturdy powder-coated steel frame, and a convenient carry handle for easy transport. The chair also includes padded armrests, a beverage holder, and a mesh backrest for added comfort and breathability.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Comfortable Rocking Motion:</b> The spring-action rocking technology provides a smooth and relaxing rocking motion, enhancing comfort during use.</li>
<li className="ListItemStyle"><b>Sturdy Construction:</b> Made from a powder-coated steel frame, the chair is durable and can support up to 250 pounds.</li>
<li className="ListItemStyle"><b>Portability:</b> The chair folds flat and includes a built-in carry handle, making it easy to transport and store.</li>
<li className="ListItemStyle"><b>Padded Armrests:</b> The padded armrests add an extra layer of comfort, making it easier to relax for extended periods.</li>
<li className="ListItemStyle"><b>Beverage Holder:</b> The built-in beverage holder is convenient for keeping drinks within reach.</li>
<li className="ListItemStyle"><b>Mesh Backrest:</b> The mesh backrest improves airflow, keeping you cool on hot days.</li>
<li className="ListItemStyle"><b>Easy Setup:</b> The chair is easy to set up and fold down, requiring no assembly.</li>
<li className="ListItemStyle"><b>Versatile Use:</b> Suitable for a variety of outdoor activities, including camping, tailgating, and backyard lounging.</li>
<li className="ListItemStyle"><b>Stable Design:</b> The wide stance and solid construction provide stability, even on uneven ground.</li>
<li className="ListItemStyle"><b>Reasonable Price:</b> Offers good value for the features and quality provided.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Weight:</b> At around 12 pounds, it may be considered heavy for a portable chair, making it less ideal for long hikes or backpacking trips.</li>
<li className="ListItemStyle"><b>Bulky When Folded:</b> Although it folds flat, the chair can still be somewhat bulky, taking up considerable space in a vehicle or storage area.</li>
<li className="ListItemStyle"><b>Limited Weight Capacity:</b> With a maximum weight limit of 250 pounds, it may not be suitable for all users.</li>
<li className="ListItemStyle"><b>No Headrest:</b> The chair lacks a headrest, which might be a drawback for those looking for full upper body support.</li>
<li className="ListItemStyle"><b>Potential for Rust:</b> Despite the powder-coated frame, prolonged exposure to the elements could lead to rust over time.</li>
<li className="ListItemStyle"><b>No Recline Feature:</b> The chair does not recline, limiting its versatility for different seating preferences.</li>
<li className="ListItemStyle"><b>Limited Color Options:</b> The chair may come in limited color choices, which might not appeal to everyone.</li>
<li className="ListItemStyle"><b>Non-Adjustable Armrests:</b> The armrests are not adjustable, which could be a comfort issue for some users.</li>
<li className="ListItemStyle"><b>Potential Squeaking:</b> Over time, the rocking mechanism might develop a squeak, requiring maintenance.</li>
<li className="ListItemStyle"><b>Not Ideal for Tall Users:</b> The chair's dimensions may not be comfortable for very tall individuals, as it lacks extended leg support.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GCIOutdoorFreestyleRockerCampChair;

