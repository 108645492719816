import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import HavalonKnivesPirantaEdgeReplaceableBladeFoldingKnifePic from '../images/HavalonKnivesPirantaEdgeReplaceableBladeFoldingKnife.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function HavalonKnivesPirantaEdgeReplaceableBladeFoldingKnife () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Havalon Knives Piranta-Edge Replaceable Blade Folding Knife</h1>

            <img alt="Havalon Knives Piranta-Edge Replaceable Blade Folding Knife" height="130px" src={HavalonKnivesPirantaEdgeReplaceableBladeFoldingKnifePic}/>

    <Typography align="left" variant="body1">
        The Havalon Knives Piranta-Edge Replaceable Blade Folding Knife is a popular choice among hunters, fishermen, and outdoor enthusiasts. This knife is known for its precision, sharpness, and convenience due to its replaceable blade system. The Piranta-Edge model features a lightweight design, making it easy to carry, and it comes with multiple replacement blades, ensuring that you always have a sharp edge ready for use.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Replaceable Blades:</b> The knife comes with replaceable blades, which means you always have a sharp edge without the need for sharpening.</li>
<li className="ListItemStyle"><b>Precision Cutting:</b> The ultra-sharp blades are ideal for detailed and precise cutting tasks, such as skinning and field dressing.</li>
<li className="ListItemStyle"><b>Lightweight Design:</b> The knife is lightweight, making it easy to carry and handle during outdoor activities.</li>
<li className="ListItemStyle"><b>Durable Construction:</b> Made with high-quality materials, the knife is built to withstand tough conditions.</li>
<li className="ListItemStyle"><b>Ergonomic Handle:</b> The handle is designed for a comfortable and secure grip, reducing hand fatigue during extended use.</li>
<li className="ListItemStyle"><b>Compact Size:</b> Its folding design makes it compact and easy to store in a pocket or pack.</li>
<li className="ListItemStyle"><b>Easy Blade Replacement:</b> The blade replacement process is straightforward and quick, allowing for minimal downtime.</li>
<li className="ListItemStyle"><b>Versatile Use:</b> Suitable for a variety of tasks beyond hunting, such as fishing, camping, and general outdoor use.</li>
<li className="ListItemStyle"><b>Safety Features:</b> The knife includes a locking mechanism to keep the blade securely in place during use.</li>
<li className="ListItemStyle"><b>Cost-Effective:</b> Over time, the replaceable blade system can be more cost-effective than constantly buying new knives or sharpening services.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Blade Fragility:</b> The ultra-sharp blades can be fragile and prone to breaking if used improperly or on tougher materials.</li>
<li className="ListItemStyle"><b>Replacement Blade Cost:</b> While the knife itself is reasonably priced, the cost of replacement blades can add up over time.</li>
<li className="ListItemStyle"><b>Limited Blade Types:</b> The knife primarily uses a specific type of blade, which may not be suitable for all tasks.</li>
<li className="ListItemStyle"><b>Handle Material:</b> Some users may find the handle material less durable or less comfortable compared to other knives.</li>
<li className="ListItemStyle"><b>Blade Disposal:</b> Proper disposal of used blades is necessary to avoid injury, which can be inconvenient.</li>
<li className="ListItemStyle"><b>Locking Mechanism:</b> The locking mechanism can sometimes be stiff or difficult to engage/disengage.</li>
<li className="ListItemStyle"><b>Not Multi-Functional:</b> Unlike multi-tools, this knife is specialized and lacks additional tools or functions.</li>
<li className="ListItemStyle"><b>Maintenance:</b> While the blades are replaceable, the knife still requires regular cleaning and maintenance to ensure longevity.</li>
<li className="ListItemStyle"><b>Availability:</b> Replacement blades may not be readily available in all locations, requiring online orders.</li>
<li className="ListItemStyle"><b>Learning Curve:</b> New users may need time to become proficient in safely replacing blades and using the knife effectively.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default HavalonKnivesPirantaEdgeReplaceableBladeFoldingKnife;

