import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useState, useEffect} from "react";
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import FastFireFireStartersPic from './images/FastFireFireStarters.png';
import ZippoFireStarterComboKitPic from './images/ZippoFireStarterComboKit.png';
import CoghlanSMagnesiumFireStarterPic from './images/CoghlanSMagnesiumFireStarter.png';
import NOMADGrillsFireStartersPic from './images/NOMADGrillsFireStarters.png';
import ZippoTinderShredsFireStarterPic from './images/ZippoTinderShredsFireStarter.png';


function FireStarters () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.fastfirestarter.com/",
      imageAlt:"Fast Fire Fire Starters",
      imageRef:FastFireFireStartersPic,
      itemLink:"../Camping/FireStarters/FastFireFireStarters",
      itemBoldText:"Fast Fire Fire Starters:",
      itemText:"Fast Fire Fire Starters are designed to make starting a fire quick and easy, whether you're camping, grilling, or lighting a fireplace. These fire starters are typically made from a combination of natural and synthetic materials that ignite easily and burn for a sufficient amount of time to get your fire going. They are often marketed as waterproof, lightweight, and convenient to carry, making them a popular choice for outdoor enthusiasts and homeowners alike."
    },
	    {
      manuLink:"https://www.zippo.com/products/fire-starter-combo-kit",
      imageAlt:"Zippo Fire Starter Combo Kit",
      imageRef:ZippoFireStarterComboKitPic,
      itemLink:"../Camping/FireStarters/ZippoFireStarterComboKit",
      itemBoldText:"Zippo Fire Starter Combo Kit:",
      itemText:"The Zippo Fire Starter Combo Kit is designed for outdoor enthusiasts who need a reliable way to start fires in various conditions. The kit typically includes a windproof lighter, fire starter sticks, and a carrying case. Zippo is a well-known brand, recognized for its durable and high-quality products, particularly lighters. This combo kit aims to provide a comprehensive solution for starting fires, whether for camping, hiking, or emergency situations."
    },
	    {
      manuLink:"https://coghlans.com/products/magnesium-fire-starter",
      imageAlt:"Coghlan's Magnesium Fire Starter",
      imageRef:CoghlanSMagnesiumFireStarterPic,
      itemLink:"../Camping/FireStarters/CoghlanSMagnesiumFireStarter",
      itemBoldText:"Coghlan's Magnesium Fire Starter:",
      itemText:"The Coghlan's Magnesium Fire Starter is a compact and reliable tool designed for outdoor enthusiasts, campers, and survivalists. It consists of a block of magnesium with a built-in flint striker. By scraping the magnesium block with a knife or another sharp object, you can create shavings that ignite easily with the sparks produced by the flint. This fire starter is designed to work in various weather conditions, making it a valuable addition to any survival kit."
    },
	    {
      manuLink:"https://nomadgrills.com/products/fire-starters",
      imageAlt:"NOMAD Grills Fire Starters",
      imageRef:NOMADGrillsFireStartersPic,
      itemLink:"../Camping/FireStarters/NOMADGrillsFireStarters",
      itemBoldText:"NOMAD Grills Fire Starters:",
      itemText:"NOMAD Grills Fire Starters are designed to provide a quick, easy, and reliable way to ignite charcoal or wood for grilling and campfires. These fire starters are typically made from natural materials, ensuring they are safe and environmentally friendly. They are compact, lightweight, and easy to use, making them ideal for outdoor enthusiasts, campers, and grill masters who need a dependable fire-starting solution."
    },
	    {
      manuLink:"https://www.zippo.com/products/tinder-shreds-bag",
      imageAlt:"Zippo Tinder Shreds Fire Starter",
      imageRef:ZippoTinderShredsFireStarterPic,
      itemLink:"../Camping/FireStarters/ZippoTinderShredsFireStarter",
      itemBoldText:"Zippo Tinder Shreds Fire Starter:",
      itemText:"The Zippo Tinder Shreds Fire Starter is a compact and convenient fire-starting aid designed for outdoor enthusiasts, campers, and survivalists. It consists of shredded, treated pine that ignites easily and burns hot, making it an effective tool for starting fires in various weather conditions. The shreds come in a resealable pouch, ensuring they remain dry and ready for use whenever needed."
    },
	
    ];

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
    {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
      <Typography align="left" variant="body1">
    <h1>Fire Starters</h1>
    Being able to start a fire when in the outdoors can rarely be a life-threatening situation. However, when in that situation it is critical that you be able to start a fire. Other times it can mean the difference between a great time around a campfire and a not-so-great time sitting out in the cold. Below are some great options for starting a fire when outdoors.

    </Typography>
    <br/>

    {items.map((element)=> (
      <div>
    <Paper elevation={3}
      sx={{
        p: 2,
        margin: 'auto',
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
    <Grid container spacing={12} rowSpacing={1}>
    <Grid item>
    <Link href={element.menuLink} target="_blank">
      <ButtonBase sx={{ width: 128, height: 128 }}>
      <img alt={element.imageAlt} height="160px" className='GridImage' src={element.imageRef}/>
      </ButtonBase>
      </Link>
    </Grid>
    <Grid item xs={12} sm container>
      <Grid item xs container direction="column" spacing={9}>
        <Grid item xs>
          <Typography gutterBottom variant="subtitle1" component="div">
          <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
          
          </Typography>
          <Typography align="center">
            <Link href={element.manuLink} target="_blank">
          <Button variant="contained">More Product Info</Button>
          </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  </Paper>
  <br/>
  </div>
  ))}

    </div>
    </>
  );
}

export default FireStarters;

