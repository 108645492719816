import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CampChefProSeriesDeluxe2BurnerCampStovePic from '../images/CampChefProSeriesDeluxe2BurnerCampStove.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function CampChefProSeriesDeluxe2BurnerCampStove () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
{/*    <div style={{ marginLeft:"20px", marginTop: "150px" , marginRight:"20px"}}> */}
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
          <h1>Camp Chef Pro Series Deluxe 2-Burner Camp Stove</h1>

            <img alt="Camp Chef Pro Series Deluxe 2-Burner Camp Stove" height="130px" src={CampChefProSeriesDeluxe2BurnerCampStovePic}/>
    <Typography align="left" variant="body1">
        The Camp Chef Pro Series Deluxe 2-Burner Camp Stove is a high-performance outdoor cooking appliance designed for camping, tailgating, and other outdoor activities. It features two powerful burners, each capable of producing up to 30,000 BTUs, providing ample heat for cooking a variety of meals. The stove is built with a durable, rugged design to withstand outdoor conditions and includes features like a matchless ignition system, adjustable heat-control dials, and removable legs for easy transport and storage. It also comes with a three-sided windscreen to protect the flames from wind, ensuring consistent cooking performance.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>High Heat Output:</b> Each burner delivers up to 30,000 BTUs, making it suitable for a wide range of cooking tasks, from boiling water to grilling meats.</li>
<li className="ListItemStyle"><b>Durable Construction:</b> Built with high-quality materials that can withstand the rigors of outdoor use.</li>
<li className="ListItemStyle"><b>Matchless Ignition:</b> Easy to start with a matchless ignition system, eliminating the need for matches or lighters.</li>
<li className="ListItemStyle"><b>Adjustable Heat Control:</b> Precise heat control with adjustable dials for each burner, allowing for versatile cooking.</li>
<li className="ListItemStyle"><b>Removable Legs:</b> Legs can be detached for easy transport and storage, making it convenient for camping trips.</li>
<li className="ListItemStyle"><b>Three-Sided Windscreen:</b> Protects the burners from wind, ensuring consistent flame and cooking performance.</li>
<li className="ListItemStyle"><b>Large Cooking Surface:</b> Ample space to accommodate multiple pots and pans, ideal for cooking for groups.</li>
<li className="ListItemStyle"><b>Versatility:</b> Compatible with various Camp Chef accessories, such as griddles and BBQ boxes, enhancing its functionality.</li>
<li className="ListItemStyle"><b>Stability:</b> Sturdy design with stable legs, providing a reliable cooking platform even on uneven ground.</li>
<li className="ListItemStyle"><b>Ease of Cleaning:</b> Removable drip tray and easy-to-clean surfaces make post-cooking cleanup straightforward.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Weight:</b> The stove is relatively heavy, which might be a drawback for those looking for a lightweight, portable option.</li>
<li className="ListItemStyle"><b>Size:</b> Its large size can be cumbersome to transport and may take up significant space in a vehicle.</li>
<li className="ListItemStyle"><b>Price:</b> Higher cost compared to some other camp stoves, which might be a consideration for budget-conscious buyers.</li>
<li className="ListItemStyle"><b>Fuel Consumption:</b> High BTU output can lead to faster fuel consumption, requiring more frequent refills.</li>
<li className="ListItemStyle"><b>Assembly Required:</b> Initial setup and assembly can be time-consuming and may require tools.</li>
<li className="ListItemStyle"><b>No Built-in Thermometer:</b> Lacks a built-in thermometer, which could be useful for precise temperature control.</li>
<li className="ListItemStyle"><b>Limited Wind Protection:</b> While it has a three-sided windscreen, it may not be sufficient in extremely windy conditions.</li>
<li className="ListItemStyle"><b>No Built-in Storage:</b> Does not include built-in storage for accessories or fuel, requiring additional space for these items.</li>
<li className="ListItemStyle"><b>Leg Stability:</b> While generally stable, the detachable legs might feel less secure compared to fixed-leg designs.</li>
<li className="ListItemStyle"><b>Maintenance:</b> Regular maintenance is required to keep the ignition system and burners functioning optimally.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default CampChefProSeriesDeluxe2BurnerCampStove;

