import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CabelasMultiDayHuntingPackPic from '../images/CabelasMultiDayHuntingPack.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function CabelasMultiDayHuntingPack () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Cabela's Multi-Day Hunting Pack</h1>

            <img alt="Cabela's Multi-Day Hunting Pack" height="130px" src={CabelasMultiDayHuntingPackPic}/>

    <Typography align="left" variant="body1">
        The Cabela's Multi-Day Hunting Pack is designed for hunters who need a reliable and durable backpack for extended trips in the wilderness. This pack is built to carry all the essential gear required for multi-day hunts, including food, clothing, and hunting equipment. It features multiple compartments, adjustable straps, and is made from high-quality materials to withstand harsh outdoor conditions.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Durability:</b> Made from high-quality, rugged materials that can withstand harsh outdoor environments.</li>
<li className="ListItemStyle"><b>Capacity:</b> Large storage capacity suitable for multi-day trips, allowing hunters to carry all necessary gear.</li>
<li className="ListItemStyle"><b>Comfort:</b> Ergonomically designed with padded shoulder straps and a hip belt to distribute weight evenly, reducing strain.</li>
<li className="ListItemStyle"><b>Organization:</b> Multiple compartments and pockets for organized storage of gear, making it easy to access items quickly.</li>
<li className="ListItemStyle"><b>Hydration Compatibility:</b> Comes with a hydration bladder compartment, ensuring you can stay hydrated on the go.</li>
<li className="ListItemStyle"><b>Weather Resistance:</b> Often includes features like water-resistant materials and rain covers to protect gear from the elements.</li>
<li className="ListItemStyle"><b>Versatility:</b> Can be used for various outdoor activities beyond hunting, such as hiking and camping.</li>
<li className="ListItemStyle"><b>Quiet Fabric:</b> Designed with quiet fabric to minimize noise, which is crucial for hunting.</li>
<li className="ListItemStyle"><b>Adjustability:</b> Adjustable straps and load-lifters to customize the fit for different body types and preferences.</li>
<li className="ListItemStyle"><b>External Attachment Points:</b> Includes external straps and loops for attaching additional gear like sleeping bags, tents, or hunting equipment.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Weight:</b> The pack itself can be relatively heavy, adding to the overall load.</li>
<li className="ListItemStyle"><b>Price:</b> Can be expensive compared to other hunting packs, which might be a barrier for budget-conscious buyers.</li>
<li className="ListItemStyle"><b>Complexity:</b> The numerous compartments and straps can be confusing to organize and manage.</li>
<li className="ListItemStyle"><b>Bulkiness:</b> When fully loaded, the pack can become bulky and cumbersome, potentially hindering movement.</li>
<li className="ListItemStyle"><b>Ventilation:</b> Some users may find the back panel lacks sufficient ventilation, leading to discomfort during hot weather.</li>
<li className="ListItemStyle"><b>Noise:</b> Despite quiet fabric, zippers and buckles can still make noise, which might alert game.</li>
<li className="ListItemStyle"><b>Hydration Bladder Not Included:</b> Often, the hydration bladder must be purchased separately.</li>
<li className="ListItemStyle"><b>Limited Color Options:</b> May only be available in a few camouflage patterns, limiting personalization.</li>
<li className="ListItemStyle"><b>Maintenance:</b> Cleaning and maintaining the pack can be challenging due to its size and complexity.</li>
<li className="ListItemStyle"><b>Fit Issues:</b> Despite adjustability, it may not fit all body types perfectly, leading to discomfort for some users.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default CabelasMultiDayHuntingPack;

