import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import HavalonTalonHuntReplaceableBladeKnifeKitPic from '../images/HavalonTalonHuntReplaceableBladeKnifeKit.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function HavalonTalonHuntReplaceableBladeKnifeKit () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Havalon Talon Hunt Replaceable Blade Knife Kit</h1>

            <img alt="Havalon Talon Hunt Replaceable Blade Knife Kit" height="130px" src={HavalonTalonHuntReplaceableBladeKnifeKitPic}/>

    <Typography align="left" variant="body1">
        The Havalon Talon Hunt Replaceable Blade Knife Kit is designed for hunters and outdoor enthusiasts who require a versatile and reliable cutting tool. The kit features a handle with a quick-change mechanism that allows users to switch between different types of blades quickly and safely. This system provides the flexibility to use the best blade for various tasks, from skinning and gutting to filleting and general cutting. The kit typically includes multiple blades and a storage case for convenience.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Versatility:</b> The replaceable blade system allows for multiple types of blades to be used with one handle, making it suitable for various tasks.</li>
<li className="ListItemStyle"><b>Sharpness:</b> The blades are extremely sharp, providing clean and precise cuts, which is essential for hunting and outdoor activities.</li>
<li className="ListItemStyle"><b>Ease of Use:</b> The quick-change mechanism makes it easy to switch blades without the need for additional tools.</li>
<li className="ListItemStyle"><b>Portability:</b> The kit often comes with a compact storage case, making it easy to carry and organize.</li>
<li className="ListItemStyle"><b>Durability:</b> The handle is typically made from high-quality materials, ensuring it can withstand rugged outdoor conditions.</li>
<li className="ListItemStyle"><b>Safety:</b> The design includes safety features to prevent accidental blade changes and injuries.</li>
<li className="ListItemStyle"><b>Cost-Effective:</b> Over time, replacing blades can be more economical than sharpening traditional knives.</li>
<li className="ListItemStyle"><b>Hygiene:</b> Replaceable blades can be more hygienic, as they can be easily swapped out for a clean one.</li>
<li className="ListItemStyle"><b>Customization:</b> Users can customize the kit with different types of blades based on their specific needs.</li>
<li className="ListItemStyle"><b>Ergonomics:</b> The handle is often designed to be comfortable to hold, reducing hand fatigue during extended use.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Blade Availability:</b> Depending on the location, replacement blades may not be readily available.</li>
<li className="ListItemStyle"><b>Blade Disposal:</b> Used blades need to be disposed of properly, which can be inconvenient.</li>
<li className="ListItemStyle"><b>Cost of Blades:</b> While the initial kit may be affordable, the cost of replacement blades can add up over time.</li>
<li className="ListItemStyle"><b>Learning Curve:</b> There may be a slight learning curve in mastering the quick-change mechanism.</li>
<li className="ListItemStyle"><b>Blade Security:</b> If not properly secured, blades might come loose during use, posing a safety risk.</li>
<li className="ListItemStyle"><b>Handle Weight:</b> Some users might find the handle heavier than traditional knives, which could be a drawback for extended use.</li>
<li className="ListItemStyle"><b>Environmental Impact:</b> Frequent blade replacements can contribute to environmental waste.</li>
<li className="ListItemStyle"><b>Maintenance:</b> The quick-change mechanism may require occasional maintenance to ensure it functions properly.</li>
<li className="ListItemStyle"><b>Compatibility:</b> Not all blades are compatible with all handles, limiting the versatility if specific blades are needed.</li>
<li className="ListItemStyle"><b>Dependence on Blades:</b> Users become dependent on having a supply of replacement blades, which can be a problem if they run out in the field.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default HavalonTalonHuntReplaceableBladeKnifeKit;

