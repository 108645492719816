import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import GarminETrex22xHandheldGPSUnitPic from '../images/GarminETrex22xHandheldGPSUnit.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function GarminETrex22xHandheldGPSUnit () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Garmin ETrex 22x Handheld GPS Unit</h1>

            <img alt="Garmin ETrex 22x Handheld GPS Unit" height="130px" src={GarminETrex22xHandheldGPSUnitPic}/>

    <Typography align="left" variant="body1">
        The Garmin eTrex 22x is a rugged and reliable handheld GPS unit designed for outdoor enthusiasts. It features a 2.2-inch sunlight-readable color display and comes preloaded with TopoActive maps, which include routable roads and trails for cycling and hiking. The device supports GPS and GLONASS satellite systems, offering enhanced tracking in challenging environments. With 8 GB of internal memory and a microSD card slot, users can store additional maps and data. The eTrex 22x is built to withstand harsh conditions, making it a popular choice for hikers, campers, and adventurers.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Durability:</b> The eTrex 22x is built to withstand tough outdoor conditions, including water, dust, and shock.</li>
<li className="ListItemStyle"><b>Preloaded Maps:</b> Comes with TopoActive maps, providing detailed information on roads and trails.</li>
<li className="ListItemStyle"><b>Satellite Support:</b> Supports both GPS and GLONASS satellite systems for improved accuracy and reliability.</li>
<li className="ListItemStyle"><b>Battery Life:</b> Offers up to 25 hours of battery life on two AA batteries, which are easily replaceable.</li>
<li className="ListItemStyle"><b>Expandable Storage:</b> 8 GB of internal memory with a microSD card slot for additional map storage.</li>
<li className="ListItemStyle"><b>Compact and Lightweight:</b> Easy to carry and handle, making it ideal for long treks.</li>
<li className="ListItemStyle"><b>User-Friendly Interface:</b> Simple and intuitive interface, suitable for both beginners and experienced users.</li>
<li className="ListItemStyle"><b>Waypoint Management:</b> Allows users to mark and navigate to waypoints, making it easier to track locations.</li>
<li className="ListItemStyle"><b>Sunlight-Readable Display:</b> The 2.2-inch color display is readable even in bright sunlight.</li>
<li className="ListItemStyle"><b>Affordable:</b> Offers a good balance of features and performance at a reasonable price point.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Limited Screen Size:</b> The 2.2-inch display may be too small for some users, making it difficult to view detailed maps.</li>
<li className="ListItemStyle"><b>No Touchscreen:</b> Navigation is done via buttons, which can be less convenient than a touchscreen interface.</li>
<li className="ListItemStyle"><b>Basic Features:</b> Lacks some advanced features found in higher-end models, such as barometric altimeter and electronic compass.</li>
<li className="ListItemStyle"><b>No Wireless Connectivity:</b> Does not support Bluetooth or Wi-Fi for easy data transfer and updates.</li>
<li className="ListItemStyle"><b>Slow Map Rendering:</b> Some users report that map rendering can be slow, especially when zooming in and out.</li>
<li className="ListItemStyle"><b>Limited Customization:</b> Fewer options for customizing the display and settings compared to more advanced units.</li>
<li className="ListItemStyle"><b>No Camera:</b> Unlike some other GPS units, it does not have a built-in camera for geotagging photos.</li>
<li className="ListItemStyle"><b>Basic Display Resolution:</b> The display resolution is relatively low, which can affect the clarity of maps and data.</li>
<li className="ListItemStyle"><b>Learning Curve:</b> While user-friendly, there is still a learning curve for those unfamiliar with GPS devices.</li>
<li className="ListItemStyle"><b>Software Updates:</b> Requires connection to a computer for software updates, which can be inconvenient for some users.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GarminETrex22xHandheldGPSUnit;

