import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import GSIOutdoorsGlacierCampStovePic from '../images/GSIOutdoorsGlacierCampStove.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function GSIOutdoorsGlacierCampStove () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
          <h1>GSI Outdoors Glacier Camp Stove</h1>
            <img alt="GSI Outdoors Glacier Camp Stove" height="130px" src={GSIOutdoorsGlacierCampStovePic}/>
    <Typography align="left" variant="body1">
        The GSI Outdoors Glacier Camp Stove is a compact, durable, and efficient camping stove designed for outdoor enthusiasts. It features a robust stainless steel construction, a powerful burner, and a compact design that makes it easy to transport and set up. This stove is ideal for backpackers, campers, and adventurers who need a reliable cooking solution in the wilderness.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Durability:</b> Made from high-quality stainless steel, the stove is built to withstand harsh outdoor conditions and last for years.</li>
<li className="ListItemStyle"><b>Compact Design:</b> Its small size and foldable components make it easy to pack and carry, saving valuable space in your backpack.</li>
<li className="ListItemStyle"><b>Powerful Burner:</b> The stove delivers a strong flame, providing efficient and quick cooking, even in windy conditions.</li>
<li className="ListItemStyle"><b>Stability:</b> The wide base and sturdy construction offer excellent stability, reducing the risk of tipping over while cooking.</li>
<li className="ListItemStyle"><b>Ease of Use:</b> Simple setup and operation make it user-friendly, even for beginners.</li>
<li className="ListItemStyle"><b>Fuel Efficiency:</b> Designed to maximize fuel usage, it ensures longer cooking times with less fuel.</li>
<li className="ListItemStyle"><b>Versatility:</b> Suitable for various types of cooking, from boiling water to simmering meals.</li>
<li className="ListItemStyle"><b>Wind Resistance:</b> The design includes features to protect the flame from wind, ensuring consistent performance.</li>
<li className="ListItemStyle"><b>Safety Features:</b> Includes safety mechanisms to prevent accidental flare-ups and ensure safe operation.</li>
<li className="ListItemStyle"><b>Maintenance:</b> Easy to clean and maintain, with components that can be easily disassembled and reassembled.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Weight:</b> While compact, the stainless steel construction makes it heavier than some ultralight alternatives.</li>
<li className="ListItemStyle"><b>Price:</b> Higher cost compared to some other camping stoves on the market.</li>
<li className="ListItemStyle"><b>Fuel Type Limitation:</b> May be limited to specific types of fuel, which could be less convenient in remote areas.</li>
<li className="ListItemStyle"><b>Ignition:</b> Some models may not include a built-in ignition system, requiring a separate lighter or matches.</li>
<li className="ListItemStyle"><b>Heat Control:</b> Fine-tuning the flame can be challenging, making it harder to achieve precise cooking temperatures.</li>
<li className="ListItemStyle"><b>Setup Time:</b> Although generally easy to set up, it might take longer compared to some instant-start stoves.</li>
<li className="ListItemStyle"><b>Size:</b> While compact, it may still be bulkier than some minimalist stove options.</li>
<li className="ListItemStyle"><b>Noise:</b> The burner can be louder than some other stoves, which might be a concern in quiet camping environments.</li>
<li className="ListItemStyle"><b>Fuel Availability:</b> Depending on the region, the specific fuel type required might not be readily available.</li>
<li className="ListItemStyle"><b>Accessories:</b> Additional accessories or parts might be needed for optimal use, adding to the overall cost and packing requirements.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GSIOutdoorsGlacierCampStove;

