import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import GarminGPSMAP67iGPSHandheldAndSatelliteCommunicatorPic from '../images/GarminGPSMAP67iGPSHandheldAndSatelliteCommunicator.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function GarminGPSMAP67iGPSHandheldAndSatelliteCommunicator () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Garmin GPSMAP 67i GPS Handheld And Satellite Communicator</h1>

            <img alt="Garmin GPSMAP 67i GPS Handheld And Satellite Communicator" height="130px" src={GarminGPSMAP67iGPSHandheldAndSatelliteCommunicatorPic}/>

    <Typography align="left" variant="body1">
        The Garmin GPSMAP 67i GPS Handheld and Satellite Communicator is a versatile and rugged device designed for outdoor enthusiasts, adventurers, and professionals who need reliable navigation and communication capabilities in remote areas. It combines GPS navigation with satellite communication, allowing users to send and receive messages, track their location, and call for emergency assistance via the Iridium satellite network. The device is built to withstand harsh environments and offers a range of features to enhance safety and navigation.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Reliable Satellite Communication:</b> The device supports two-way messaging and SOS alerts via the Iridium satellite network, ensuring communication even in remote areas without cellular coverage.</li>
<li className="ListItemStyle"><b>Robust Navigation Features:</b> It includes detailed topographic maps, multiple GNSS support (GPS, GLONASS, Galileo), and a built-in compass and barometric altimeter for accurate navigation.</li>
<li className="ListItemStyle"><b>Durable Design:</b> The GPSMAP 67i is built to withstand tough conditions, with a rugged, water-resistant (IPX7) design that can handle drops, dust, and water exposure.</li>
<li className="ListItemStyle"><b>Long Battery Life:</b> The device offers an impressive battery life, with up to 165 hours in tracking mode and up to 425 hours in expedition mode.</li>
<li className="ListItemStyle"><b>Large Display:</b> The 3-inch sunlight-readable color display provides clear and easy-to-read maps and data, even in bright conditions.</li>
<li className="ListItemStyle"><b>Preloaded Maps:</b> Comes with preloaded TopoActive maps and access to BirdsEye Satellite Imagery without an annual subscription.</li>
<li className="ListItemStyle"><b>Customizable Interface:</b> Users can customize the interface and data fields to suit their specific needs and preferences.</li>
<li className="ListItemStyle"><b>Wireless Connectivity:</b> Supports Bluetooth and ANT+ for connecting to compatible devices and sensors, such as heart rate monitors and temperature sensors.</li>
<li className="ListItemStyle"><b>Weather Updates:</b> Provides weather forecasts and live weather updates when connected to a compatible smartphone.</li>
<li className="ListItemStyle"><b>Geocaching Support:</b> Integrated geocaching features allow users to download and manage geocaches directly on the device.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>High Cost:</b> The GPSMAP 67i is relatively expensive compared to other handheld GPS devices, which may be a barrier for some users.</li>
<li className="ListItemStyle"><b>Subscription Fees:</b> Satellite communication features require an active subscription plan, adding to the overall cost of ownership.</li>
<li className="ListItemStyle"><b>Weight:</b> The device is heavier than some other handheld GPS units, which might be a consideration for ultralight backpackers.</li>
<li className="ListItemStyle"><b>Complexity:</b> The extensive features and capabilities can be overwhelming for beginners or those who prefer a simpler interface.</li>
<li className="ListItemStyle"><b>Limited Internal Storage:</b> The internal storage may not be sufficient for users who want to load a large number of custom maps or extensive data.</li>
<li className="ListItemStyle"><b>Battery Replacement:</b> The built-in rechargeable battery cannot be easily replaced in the field, which could be a drawback on extended trips without access to charging options.</li>
<li className="ListItemStyle"><b>Screen Size:</b> While the 3-inch display is large for a handheld GPS, some users may find it small compared to smartphone screens.</li>
<li className="ListItemStyle"><b>Learning Curve:</b> Mastering all the features and functions of the device can take time and practice.</li>
<li className="ListItemStyle"><b>Signal Interference:</b> Dense forests, deep canyons, and other challenging environments can still affect satellite signal reception.</li>
<li className="ListItemStyle"><b>Software Updates:</b> Occasional software updates may be required to fix bugs or add new features, which requires access to a computer and internet connection.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GarminGPSMAP67iGPSHandheldAndSatelliteCommunicator;

