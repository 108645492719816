import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CabelasVersaHunt80LHuntingPackPic from '../images/CabelasVersaHunt80LHuntingPack.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function CabelasVersaHunt80LHuntingPack () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Cabela's VersaHunt 80L Hunting Pack</h1>

            <img alt="Cabela's VersaHunt 80L Hunting Pack" height="130px" src={CabelasVersaHunt80LHuntingPackPic}/>

    <Typography align="left" variant="body1">
        The Cabela's VersaHunt 80L Hunting Pack is a versatile and durable backpack designed specifically for hunters. With an 80-liter capacity, it offers ample space for gear, supplies, and game. The pack is constructed from high-quality materials to withstand rugged outdoor conditions and features multiple compartments and pockets for organized storage. It also includes a comfortable, adjustable harness system to distribute weight evenly and reduce fatigue during long treks.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
                <li className="ListItemStyle"><b>Large Capacity:</b> With 80 liters of storage, it can accommodate a significant amount of gear and supplies, making it ideal for extended hunting trips.</li>
                <li className="ListItemStyle"><b>Durable Construction:</b> Made from high-quality, rugged materials that can withstand harsh outdoor conditions.</li>
                <li className="ListItemStyle"><b>Comfortable Harness System:</b> Features an adjustable harness system that helps distribute weight evenly, reducing strain and fatigue.</li>
                <li className="ListItemStyle"><b>Multiple Compartments:</b> Offers various compartments and pockets for organized storage, making it easier to access gear quickly.</li>
                <li className="ListItemStyle"><b>Hydration Compatible:</b> Designed to be compatible with hydration systems, which is essential for staying hydrated during long hunts.</li>
                <li className="ListItemStyle"><b>Versatile Use:</b> Can be used for hunting as well as other outdoor activities like hiking and camping.</li>
                <li className="ListItemStyle"><b>Load-Bearing Capability:</b> Built to carry heavy loads, including game, without compromising comfort or durability.</li>
                <li className="ListItemStyle"><b>Weather-Resistant:</b> Provides some level of protection against the elements, keeping your gear dry and safe.</li>
                <li className="ListItemStyle"><b>MOLLE Webbing:</b> Includes MOLLE webbing for attaching additional pouches and gear, enhancing customization.</li>
                <li className="ListItemStyle"><b>Quiet Fabric:</b> Made with materials that minimize noise, which is crucial for maintaining stealth while hunting.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
                <li className="ListItemStyle"><b>Weight:</b> The pack itself can be relatively heavy, which might be a drawback for those looking to minimize their load.</li>
                <li className="ListItemStyle"><b>Price:</b> It can be on the expensive side, which might not fit everyone's budget.</li>
                <li className="ListItemStyle"><b>Complexity:</b> The numerous compartments and features might be overwhelming for some users, making it less user-friendly.</li>
                <li className="ListItemStyle"><b>Bulkiness:</b> Its large size can be cumbersome in dense or tight hunting environments.</li>
                <li className="ListItemStyle"><b>Limited Color Options:</b> May not offer a wide range of camouflage patterns to suit all hunting terrains.</li>
                <li className="ListItemStyle"><b>Adjustment Learning Curve:</b> The harness system might take some time to adjust properly for optimal comfort.</li>
                <li className="ListItemStyle"><b>Hydration Bladder Not Included:</b> While it is hydration compatible, it typically does not come with a hydration bladder.</li>
                <li className="ListItemStyle"><b>Potential Overpacking:</b> The large capacity might encourage overpacking, leading to unnecessary weight.</li>
                <li className="ListItemStyle"><b>Limited Ventilation:</b> May not have sufficient ventilation, leading to increased sweating and discomfort in hot weather.</li>
                <li className="ListItemStyle"><b>Availability:</b> Depending on the region, it might be difficult to find in local stores, necessitating online purchase and potential shipping delays.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default CabelasVersaHunt80LHuntingPack;

