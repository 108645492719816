import * as React from 'react';
import { useState, useEffect} from "react";
import Typography from '@mui/material/Typography';
import HomePageBackground from '../images/MountainPeak.JPG';
import HomePageBackgroundsm from '../images/MountainPeak-sm.jpg';
import { useMediaQuery } from '@mui/material';

function Home() {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
  <div>
    {isSmallScreen === true &&
            <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
            <img alt="Join Me Outdoors" width="100%" height="auto" src={HomePageBackgroundsm}/>
            <Typography>
              <center>
              Join Me Outdoors has summarized hundreds of user reviews from a variety of websites.<br/>
              <br/>
              No longer do you need to spend hours reading reviews to make product buying decisions.<br/>
              <br/>
              Each product will have a list of Pros and Cons, based on summarizing user feedback.<br/>
              <br/>Use the menu to explore outdoor products and user reviews.
              </center>
            </Typography>
            </div>
    }
    {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
          <img alt="Join Me Outdoors" width="100%" height="auto" src={HomePageBackground}/>
        </div>
      }
    </div> 
  );
}

export default Home;