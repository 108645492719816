import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AscendHex0LongMummySleepingBagPic from '../images/AscendHex0LongMummySleepingBag.png';
import { useState, useEffect} from "react";

function AscendHex0LongMummySleepingBag () {
  const [mobileOpen, setMobileOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{ marginLeft:"20px", marginTop: "150px" , marginRight:"20px"}}>
    <table>
        <tr>
          <td>
          <h1>Ascend Hex 0º Long Mummy Sleeping Bag</h1>
          </td>
          <td>
            <img alt="Ascend Hex 0º Long Mummy Sleeping Bag" width="50%" height="50%" src={AscendHex0LongMummySleepingBagPic}/>
          </td>
        </tr>
      </table>
    <Typography align="left" variant="body1">
        The Ascend Hex 0º Long Mummy Sleeping Bag is designed for outdoor enthusiasts who need reliable warmth in cold weather conditions. This sleeping bag features a mummy shape, which is known for its efficiency in retaining heat. It typically includes features such as a durable outer shell, high-quality insulation, a hood for added warmth, and a draft collar to prevent heat loss. The "0º" rating indicates that it is suitable for temperatures as low as 0 degrees Fahrenheit, making it ideal for winter camping or high-altitude adventures.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Warmth:</b> The 0º temperature rating ensures that the bag can keep you warm in very cold conditions.</li>
<li className="ListItemStyle"><b>Mummy Shape:</b> The mummy design minimizes heat loss and maximizes warmth retention.</li>
<li className="ListItemStyle"><b>Durable Materials:</b> Often made with robust outer shells and high-quality zippers, ensuring longevity.</li>
<li className="ListItemStyle"><b>Insulation:</b> High-quality synthetic or down insulation provides excellent warmth-to-weight ratio.</li>
<li className="ListItemStyle"><b>Hood:</b> The integrated hood adds extra warmth and comfort for your head.</li>
<li className="ListItemStyle"><b>Draft Collar:</b> Prevents cold air from entering the bag, maintaining a warm interior.</li>
<li className="ListItemStyle"><b>Compression Sack:</b> Often comes with a compression sack for easy packing and transport.</li>
<li className="ListItemStyle"><b>Water-Resistant:</b> Many models have water-resistant outer shells to protect against moisture.</li>
<li className="ListItemStyle"><b>Long Size:</b> The long version accommodates taller individuals, providing extra legroom.</li>
<li className="ListItemStyle"><b>Versatility:</b> Suitable for a variety of cold-weather activities, from winter camping to mountaineering.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Weight:</b> Mummy bags with a 0º rating can be heavier and bulkier than those with higher temperature ratings.</li>
<li className="ListItemStyle"><b>Cost:</b> High-quality materials and insulation can make these sleeping bags more expensive.</li>
<li className="ListItemStyle"><b>Restricted Movement:</b> The mummy shape can feel restrictive for some users who prefer more space.</li>
<li className="ListItemStyle"><b>Zipper Snags:</b> Zippers can sometimes snag on the fabric, which can be frustrating.</li>
<li className="ListItemStyle"><b>Ventilation:</b> Limited ventilation options can make the bag too warm in milder conditions.</li>
<li className="ListItemStyle"><b>Packing Size:</b> Even with a compression sack, the bag can still take up significant space in your pack.</li>
<li className="ListItemStyle"><b>Maintenance:</b> Down-filled versions require careful maintenance and cleaning to maintain loft and warmth.</li>
<li className="ListItemStyle"><b>Initial Odor:</b> Some users report an initial odor from the materials, which usually dissipates over time.</li>
<li className="ListItemStyle"><b>Drying Time:</b> Synthetic insulation can take longer to dry if it gets wet.</li>
<li className="ListItemStyle"><b>Compatibility:</b> May not fit well in smaller tents or bivy sacks due to its size and shape.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default AscendHex0LongMummySleepingBag;

