import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import GarminInReachMini2SatelliteCommunicatorPic from '../images/GarminInReachMini2SatelliteCommunicator.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function GarminInReachMini2SatelliteCommunicator () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Garmin InReach Mini 2 Satellite Communicator</h1>

            <img alt="Garmin InReach Mini 2 Satellite Communicator" height="130px" src={GarminInReachMini2SatelliteCommunicatorPic}/>

    <Typography align="left" variant="body1">
    The Garmin inReach Mini 2 is a compact, lightweight satellite communicator designed for adventurers and outdoor enthusiasts who demand reliable connectivity in remote areas. With two-way messaging capabilities via the Iridium satellite network, you can stay in touch with friends and family even when cellular coverage is unavailable.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
              <li className="ListItemStyle"><b>Compact and Lightweight:</b> Weighing just 3.5 ounces, it's easy to carry without adding significant bulk to your gear.</li>
                <li className="ListItemStyle"><b>Global Satellite Coverage:</b> Utilizes the Iridium satellite network, providing reliable communication anywhere in the world.</li>
                <li className="ListItemStyle"><b>Two-Way Messaging:</b> Allows for sending and receiving text messages, which is crucial for staying in touch with loved ones or emergency services.</li>
                <li className="ListItemStyle"><b>SOS Functionality:</b> Features a dedicated SOS button that connects to a 24/7 global monitoring center for emergency assistance.</li>
                <li className="ListItemStyle"><b>Weather Forecasts:</b> Provides weather updates to help plan your activities and stay safe in changing conditions.</li>
                <li className="ListItemStyle"><b>Battery Life:</b> Offers up to 14 days of battery life in 10-minute tracking mode, making it suitable for extended trips.</li>
                <li className="ListItemStyle"><b>Rugged Design:</b> Built to withstand harsh environments with an IPX7 water rating and MIL-STD-810G shock resistance.</li>
                <li className="ListItemStyle"><b>Bluetooth Connectivity:</b> Pairs with smartphones via the Garmin Earthmate app for easier messaging and access to maps.</li>
                <li className="ListItemStyle"><b>Tracking and Sharing:</b> Allows you to share your location with friends and family in real-time.</li>
                <li className="ListItemStyle"><b>Navigation Features:</b> Includes basic navigation tools like a digital compass, waypoints, and breadcrumb trails.</li>
			  
              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
                <li className="ListItemStyle"><b>Subscription Fees:</b> The device requires a monthly subscription for satellite communication services, which can add up over time.</li>
                <li className="ListItemStyle"><b>Limited Battery Life:</b> While the battery lasts a decent amount of time, heavy use, especially for tracking, can deplete it quickly.</li>
                <li className="ListItemStyle"><b>Size and Weight:</b> Although compact, some users may still find it slightly bulky or heavy for ultra-light backpacking.</li>
                <li className="ListItemStyle"><b>Small Display:</b> The screen is quite small, which can make it challenging to read messages or navigate through menus, especially in bright sunlight.</li>
                <li className="ListItemStyle"><b>Dependency</b> on Satellite Coverage: Performance can vary in areas with limited satellite visibility, such as deep canyons or dense forests.</li>
                <li className="ListItemStyle"><b>Slow Messaging:</b> Sending messages can be slower compared to traditional text messaging, which may be frustrating in urgent situations.</li>
                <li className="ListItemStyle"><b>Learning Curve:</b> New users may face a learning curve to fully utilize all features and functionalities, especially if they’re not tech-savvy.</li>
                <li className="ListItemStyle"><b>Limited Features Compared to Larger Models:</b> It lacks some advanced features found in larger Garmin models, such as maps and additional navigation options.</li>
                <li className="ListItemStyle"><b>Cost:</b> The upfront cost, along with subscription fees, can be a significant investment for casual users.</li>
                <li className="ListItemStyle"><b>Not Waterproof:</b> While it is weather-resistant, it is not fully waterproof, which could be a concern in heavy rain or submersion scenarios.</li>			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GarminInReachMini2SatelliteCommunicator;

