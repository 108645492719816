import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useState, useEffect} from "react";
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import CabelasOutfitterG532MPBlackIRTrailCameraComboPic from './images/CabelasOutfitterG532MPBlackIRTrailCameraCombo.png';
import MoultrieMobileEDGECellularTrailCameraPic from './images/MoultrieMobileEDGECellularTrailCamera.png';
import SpyPointFlexMCellularTrailCamera2PackPic from './images/SpyPointFlexMCellularTrailCamera2Pack.png';
import StealthCamRevolver360CellularTrailCameraPic from './images/StealthCamRevolver360CellularTrailCamera.png';
import TactacamRevealXProCellularTrailCameraPic from './images/TactacamRevealXProCellularTrailCamera.png';

function TrailCams () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.cabelas.com/shop/en/cabelas-outfitter-g5-32mp-black-ir-trail-camera-combo",
      imageAlt:"Cabela's Outfitter G5 32MP Black IR Trail Camera Combo",
      imageRef:CabelasOutfitterG532MPBlackIRTrailCameraComboPic,
      itemLink:"../OutdoorGear/TrailCams/CabelasOutfitterG532MPBlackIRTrailCameraCombo",
      itemBoldText:"Cabela's Outfitter G5 32MP Black IR Trail Camera Combo:",
      itemText:"The Cabela's Outfitter G5 32MP Black IR Trail Camera Combo is a high-performance trail camera designed for outdoor enthusiasts and wildlife observers. It features a 32-megapixel camera for capturing high-resolution images and videos. The Black IR (infrared) technology allows for discreet nighttime photography without startling wildlife. This combo package typically includes additional accessories such as batteries and memory cards, making it a convenient choice for those looking to set up their trail camera quickly."
    },
	    {
      manuLink:"https://www.moultrieproducts.com/moultrie-mobile-edge-cellular-camera",
      imageAlt:"Moultrie Mobile EDGE Cellular Trail Camera",
      imageRef:MoultrieMobileEDGECellularTrailCameraPic,
      itemLink:"../OutdoorGear/TrailCams/MoultrieMobileEDGECellularTrailCamera",
      itemBoldText:"Moultrie Mobile EDGE Cellular Trail Camera:",
      itemText:"The Moultrie Mobile EDGE Cellular Trail Camera is a device designed for wildlife monitoring and security purposes. It allows users to capture images and videos of wildlife and send them directly to a mobile device via cellular networks. This camera is equipped with features such as high-resolution image capture, night vision, and motion detection. It is popular among hunters, wildlife enthusiasts, and property owners for its convenience and advanced technology."
    },
	    {
      manuLink:"https://www.spypoint.com/en/products/flex-m-twin-bundle/the-best-value-in-cellular-trail-cameras",
      imageAlt:"SpyPoint Flex-M Cellular Trail Camera 2-Pack",
      imageRef:SpyPointFlexMCellularTrailCamera2PackPic,
      itemLink:"../OutdoorGear/TrailCams/SpyPointFlexMCellularTrailCamera2Pack",
      itemBoldText:"SpyPoint Flex-M Cellular Trail Camera 2-Pack:",
      itemText:"The SpyPoint Flex-M Cellular Trail Camera is designed for outdoor enthusiasts and hunters who want to monitor wildlife activity remotely. This camera offers cellular connectivity, allowing users to receive images and updates directly to their devices. The Flex-M model is known for its ease of use, reliability, and advanced features like high-resolution image capture and fast trigger speed. The 2-pack option provides added value for those needing coverage in multiple locations."
    },
	    {
      manuLink:"https://www.stealthcam.com/products/revolver-pro/?gad_source=1&gclid=CjwKCAjwx4O4BhAnEiwA42SbVIm48HHuCDyqiexIXyTy8AP2XNh8vPElRG6I1y3B96ifnzqd9XvV5RoCfg8QAvD_BwE&gclsrc=aw.ds",
      imageAlt:"Stealth Cam Revolver 360° Cellular Trail Camera",
      imageRef:StealthCamRevolver360CellularTrailCameraPic,
      itemLink:"../OutdoorGear/TrailCams/StealthCamRevolver360CellularTrailCamera",
      itemBoldText:"Stealth Cam Revolver 360° Cellular Trail Camera:",
      itemText:"The Stealth Cam Revolver 360° Cellular Trail Camera is a high-tech device designed for wildlife monitoring and security purposes. It features a 360-degree rotating camera to capture images and videos from all angles, ensuring comprehensive coverage of the area. The camera is equipped with cellular connectivity, allowing users to receive images and videos directly to their mobile devices or computers. This trail camera is suitable for both hunters and property owners who need reliable surveillance in remote locations."
    },
	    {
      manuLink:"https://www.tactacam.com/reveal-x-pro",
      imageAlt:"Tactacam Reveal X Pro Cellular Trail Camera",
      imageRef:TactacamRevealXProCellularTrailCameraPic,
      itemLink:"../OutdoorGear/TrailCams/TactacamRevealXProCellularTrailCamera",
      itemBoldText:"Tactacam Reveal X Pro Cellular Trail Camera:",
      itemText:"The Tactacam Reveal X Pro Cellular Trail Camera is a high-performance trail camera designed for wildlife monitoring and security purposes. It offers cellular connectivity, allowing users to receive images and videos directly to their smartphones or devices. This feature makes it particularly useful for remote monitoring. The camera is known for its ease of use, quality imagery, and robust construction, making it suitable for various outdoor environments."
    },
	
    ];

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1">

	<h1>Trail Cams</h1>
	
  Do you want to see what is going on at your hunting spot when you are not around? Trail cams can do just that. Whether you are trying to capture that big buck or just want to see what type of critters are roaming around, a trail cam is a must. Below are some great choices.

    </Typography>
    <br/>

    {items.map((element)=> (
      <div>
    <Paper elevation={3}
      sx={{
        p: 2,
        margin: 'auto',
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
    <Grid container spacing={12} rowSpacing={1}>
    <Grid item>
    <Link href={element.menuLink} target="_blank">
      <ButtonBase sx={{ width: 128, height: 128 }}>
      <img alt={element.imageAlt} height="160px" className='GridImage' src={element.imageRef}/>
      </ButtonBase>
      </Link>
    </Grid>
    <Grid item xs={12} sm container>
      <Grid item xs container direction="column" spacing={9}>
        <Grid item xs>
          <Typography gutterBottom variant="subtitle1" component="div">
          <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
          
          </Typography>
          <Typography align="center">
            <Link href={element.manuLink} target="_blank">
          <Button variant="contained">More Product Info</Button>
          </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  </Paper>
  <br/>
  </div>
  ))}

    </div>
    </>
  );
}

export default TrailCams;

