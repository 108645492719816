import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import BadlandsATX25HuntingPackPic from '../images/BadlandsATX25HuntingPack.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function BadlandsATX25HuntingPack () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Badlands ATX 25 Hunting Pack</h1>

            <img alt="Badlands ATX 25 Hunting Pack" height="130px" src={BadlandsATX25HuntingPackPic}/>

    <Typography align="left" variant="body1">
        The Badlands ATX 25 Hunting Pack is designed for hunters who require a durable, versatile, and comfortable pack for their outdoor adventures. Known for its high-quality materials and innovative design, the ATX 25 offers ample storage, ergonomic support, and a range of features tailored to meet the needs of serious hunters. It is built to withstand harsh conditions while providing easy access to gear and equipment.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Durability:</b> Made from high-quality, rugged materials that can withstand harsh outdoor conditions.</li>
<li className="ListItemStyle"><b>Comfort:</b> Ergonomically designed with padded shoulder straps and a hip belt to distribute weight evenly.</li>
<li className="ListItemStyle"><b>Storage Capacity:</b> Offers ample storage space with multiple compartments and pockets for organized packing.</li>
<li className="ListItemStyle"><b>Hydration Compatibility:</b> Includes a hydration bladder compartment, making it easy to stay hydrated on the go.</li>
<li className="ListItemStyle"><b>Quiet Fabric:</b> Constructed with noise-reducing fabric to minimize sound, which is crucial for hunting.</li>
<li className="ListItemStyle"><b>Ventilation:</b> Features a breathable back panel to help keep you cool during long treks.</li>
<li className="ListItemStyle"><b>Weapon Carry System:</b> Equipped with a secure system for carrying rifles or bows.</li>
<li className="ListItemStyle"><b>MOLLE Webbing:</b> Allows for customization and attachment of additional gear and pouches.</li>
<li className="ListItemStyle"><b>Rain Cover:</b> Comes with a built-in rain cover to protect your gear in wet conditions.</li>
<li className="ListItemStyle"><b>Warranty:</b> Backed by Badlands' unconditional lifetime warranty, ensuring long-term reliability and customer support.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Weight:</b> Can be relatively heavy, especially when fully loaded, which may be a concern for some users.</li>
<li className="ListItemStyle"><b>Price:</b> Higher price point compared to other hunting packs, which might not fit all budgets.</li>
<li className="ListItemStyle"><b>Complexity:</b> The numerous compartments and features can be overwhelming for users who prefer simplicity.</li>
<li className="ListItemStyle"><b>Size:</b> May be too large for shorter or smaller-framed individuals.</li>
<li className="ListItemStyle"><b>Hydration Bladder Not Included:</b> Requires a separate purchase of a hydration bladder.</li>
<li className="ListItemStyle"><b>Limited Color Options:</b> Typically available in limited color schemes, which may not suit all preferences.</li>
<li className="ListItemStyle"><b>Accessibility:</b> Some users may find it difficult to access certain compartments quickly.</li>
<li className="ListItemStyle"><b>Adjustability:</b> While generally comfortable, the fit may not be perfect for everyone, requiring adjustments.</li>
<li className="ListItemStyle"><b>Noise:</b> Despite the quiet fabric, zippers and buckles can still make noise if not handled carefully.</li>
<li className="ListItemStyle"><b>Learning Curve:</b> New users might need time to familiarize themselves with all the features and compartments.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default BadlandsATX25HuntingPack;

