import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MoultrieMobileEDGECellularTrailCameraPic from '../images/MoultrieMobileEDGECellularTrailCamera.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';


function MoultrieMobileEDGECellularTrailCamera () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Moultrie Mobile EDGE Cellular Trail Camera</h1>

            <img alt="Moultrie Mobile EDGE Cellular Trail Camera" height="130px" src={MoultrieMobileEDGECellularTrailCameraPic}/>

    <Typography align="left" variant="body1">
        The Moultrie Mobile EDGE Cellular Trail Camera is a device designed for wildlife monitoring and security purposes. It allows users to capture images and videos of wildlife and send them directly to a mobile device via cellular networks. This camera is equipped with features such as high-resolution image capture, night vision, and motion detection. It is popular among hunters, wildlife enthusiasts, and property owners for its convenience and advanced technology.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Cellular Connectivity:</b> The camera can send images and videos directly to your smartphone or computer, allowing for real-time monitoring without needing to physically retrieve the SD card.</li>
<li className="ListItemStyle"><b>High-Resolution Images:</b> Offers high-quality image capture, which is crucial for identifying wildlife or intruders clearly.</li>
<li className="ListItemStyle"><b>Night Vision Capability:</b> Equipped with infrared LEDs for capturing clear images and videos at night without disturbing wildlife.</li>
<li className="ListItemStyle"><b>Motion Detection:</b> The camera is triggered by movement, ensuring that it only captures relevant footage, which saves storage and battery life.</li>
<li className="ListItemStyle"><b>User-Friendly App:</b> The accompanying mobile app is intuitive and easy to use, providing seamless access to images and camera settings.</li>
<li className="ListItemStyle"><b>Weatherproof Design:</b> Built to withstand harsh outdoor conditions, making it reliable in various weather scenarios.</li>
<li className="ListItemStyle"><b>Long Battery Life:</b> Designed to operate for extended periods without needing frequent battery changes, which is ideal for remote locations.</li>
<li className="ListItemStyle"><b>Wide Field of View:</b> Offers a broad detection range and field of view, increasing the chances of capturing wildlife activity.</li>
<li className="ListItemStyle"><b>Flexible Data Plans:</b> Provides various data plan options to suit different needs and budgets, making it cost-effective for different users.</li>
<li className="ListItemStyle"><b>Easy Setup:</b> The camera is relatively easy to set up, even for those who are not tech-savvy, with clear instructions provided.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Monthly Data Fees:</b> Requires a subscription for cellular service, which can add to the overall cost of ownership.</li>
<li className="ListItemStyle"><b>Signal Dependency:</b> Performance is dependent on cellular signal strength, which can be an issue in remote areas with poor coverage.</li>
<li className="ListItemStyle"><b>Initial Cost:</b> The upfront cost of the camera can be high compared to non-cellular trail cameras.</li>
<li className="ListItemStyle"><b>Limited Video Length:</b> Some users may find the maximum video recording length to be restrictive.</li>
<li className="ListItemStyle"><b>Potential for False Triggers:</b> Motion detection can sometimes be triggered by non-target movement, such as wind-blown vegetation.</li>
<li className="ListItemStyle"><b>Battery Consumption in Cold Weather:</b> Battery life can be significantly reduced in extremely cold temperatures.</li>
<li className="ListItemStyle"><b>Image Transmission Delay:</b> There can be a delay in receiving images, depending on network conditions.</li>
<li className="ListItemStyle"><b>App Dependence:</b> Full functionality requires the use of a smartphone app, which may not be ideal for all users.</li>
<li className="ListItemStyle"><b>Limited Storage:</b> While it sends images to the cloud, local storage capacity may be limited, affecting offline use.</li>
<li className="ListItemStyle"><b>Firmware Updates:</b> Occasional need for firmware updates, which require user intervention and can be inconvenient.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default MoultrieMobileEDGECellularTrailCamera;

