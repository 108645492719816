import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnologyPicSC from '../images/GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnologySC () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Garmin GPSMAP 66i GPS Handheld And Satellite Communicator With InReach Technology</h1>

            <img alt="Garmin GPSMAP 66i GPS Handheld And Satellite Communicator With InReach Technology" height="130px" src={GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnologyPicSC}/>

    <Typography align="left" variant="body1">
        The Garmin GPSMAP 66i is a robust GPS handheld device that integrates inReach satellite communication technology. It is designed for outdoor enthusiasts, adventurers, and professionals who require reliable navigation and communication capabilities in remote areas. The device offers a combination of GPS navigation, topographic mapping, and satellite messaging, making it a versatile tool for hiking, trekking, and other outdoor activities.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>InReach Technology:</b> Provides two-way satellite messaging and SOS functionality, ensuring you can communicate and get help in emergencies, even in areas without cellular coverage.</li>
<li className="ListItemStyle"><b>Detailed Mapping:</b> Preloaded with TOPO maps and supports multiple global navigation satellite systems (GPS, GLONASS, and Galileo) for accurate positioning.</li>
<li className="ListItemStyle"><b>Rugged Design:</b> Built to withstand harsh environments with a durable, water-resistant (IPX7) construction.</li>
<li className="ListItemStyle"><b>Large Display:</b> 3-inch sunlight-readable color display for easy viewing of maps and data.</li>
<li className="ListItemStyle"><b>Battery Life:</b> Offers up to 35 hours in standard mode and up to 200 hours in expedition mode, making it suitable for extended trips.</li>
<li className="ListItemStyle"><b>Weather Forecasts:</b> Provides detailed weather forecasts, including live updates via satellite, which is crucial for planning outdoor activities.</li>
<li className="ListItemStyle"><b>Navigation Features:</b> Includes a 3-axis compass, barometric altimeter, and accelerometer for precise navigation.</li>
<li className="ListItemStyle"><b>Connectivity:</b> Compatible with the Garmin Explore app for trip planning and data management, and can sync with other Garmin devices.</li>
<li className="ListItemStyle"><b>Customizable:</b> Allows for the download of additional maps and customization of data fields and profiles to suit specific needs.</li>
<li className="ListItemStyle"><b>Geocaching:</b> Supports paperless geocaching, making it easier for users to find and log geocaches.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Price:</b> The device is relatively expensive compared to other handheld GPS units, which might be a barrier for some users.</li>
<li className="ListItemStyle"><b>Subscription Required:</b> InReach satellite communication features require a subscription plan, adding to the overall cost.</li>
<li className="ListItemStyle"><b>Weight:</b> At 8.1 ounces (230 grams), it is heavier than some other handheld GPS devices, which might be a concern for ultralight backpackers.</li>
<li className="ListItemStyle"><b>Complexity:</b> The multitude of features can be overwhelming for beginners, requiring a learning curve to fully utilize the device.</li>
<li className="ListItemStyle"><b>Battery Replacement:</b> The built-in rechargeable battery cannot be easily replaced in the field, which could be a limitation on very long expeditions.</li>
<li className="ListItemStyle"><b>Screen Resolution:</b> While the display is large, some users might find the resolution (240 x 400 pixels) less sharp compared to modern smartphones.</li>
<li className="ListItemStyle"><b>Slow Satellite Acquisition:</b> Some users report that acquiring a satellite signal can be slow in dense forests or deep canyons.</li>
<li className="ListItemStyle"><b>Limited Internal Storage:</b> The device has limited internal storage (16 GB), which might necessitate the use of a microSD card for additional maps and data.</li>
<li className="ListItemStyle"><b>User Interface:</b> The user interface can be less intuitive compared to other modern devices, potentially requiring more time to navigate through menus.</li>
<li className="ListItemStyle"><b>Software Updates:</b> Occasional software updates are necessary to maintain functionality and fix bugs, which can be inconvenient for some users.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnologySC;

