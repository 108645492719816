import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MuddyProSeriesLumbar500HuntingPackPic from '../images/MuddyProSeriesLumbar500HuntingPack.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function MuddyProSeriesLumbar500HuntingPack () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Muddy Pro Series Lumbar 500 Hunting Pack</h1>

            <img alt="Muddy Pro Series Lumbar 500 Hunting Pack" height="130px" src={MuddyProSeriesLumbar500HuntingPackPic}/>

    <Typography align="left" variant="body1">
        The Muddy Pro Series Lumbar 500 Hunting Pack is designed for hunters who need a reliable and functional pack to carry their gear. This lumbar-style pack offers a balance of storage capacity, comfort, and durability, making it suitable for various hunting conditions. It features multiple compartments, adjustable straps, and is constructed from durable materials to withstand the rigors of the outdoors.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Comfortable Design:</b> The lumbar style distributes weight evenly across the lower back, reducing strain and fatigue during long hunts.</li>
<li className="ListItemStyle"><b>Ample Storage:</b> Multiple compartments and pockets provide ample storage space for hunting gear, accessories, and personal items.</li>
<li className="ListItemStyle"><b>Durable Construction:</b> Made from high-quality, durable materials that can withstand harsh outdoor conditions.</li>
<li className="ListItemStyle"><b>Adjustable Straps:</b> Customizable straps ensure a secure and comfortable fit for different body types and preferences.</li>
<li className="ListItemStyle"><b>Quiet Fabric:</b> The pack is made from noise-reducing fabric, which is crucial for maintaining stealth while hunting.</li>
<li className="ListItemStyle"><b>Hydration Compatible:</b> Designed to accommodate hydration systems, making it easier to stay hydrated during long hunts.</li>
<li className="ListItemStyle"><b>Easy Access:</b> Strategically placed zippers and compartments allow for quick and easy access to gear.</li>
<li className="ListItemStyle"><b>Versatile Use:</b> Suitable for various types of hunting, including bowhunting and rifle hunting.</li>
<li className="ListItemStyle"><b>Weather-Resistant:</b> Offers a degree of weather resistance, protecting gear from rain and moisture.</li>
<li className="ListItemStyle"><b>Lightweight:</b> Despite its durability and storage capacity, the pack remains relatively lightweight, enhancing mobility.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Limited Capacity:</b> May not be suitable for hunters who need to carry a large amount of gear or bulky items.</li>
<li className="ListItemStyle"><b>Price Point:</b> Can be relatively expensive compared to other hunting packs with similar features.</li>
<li className="ListItemStyle"><b>Limited Color Options:</b> May not offer a wide range of color or camouflage patterns to choose from.</li>
<li className="ListItemStyle"><b>Hydration Bladder Not Included:</b> While it is hydration compatible, the pack does not come with a hydration bladder.</li>
<li className="ListItemStyle"><b>Potential Fit Issues:</b> Some users may find it challenging to achieve a perfect fit due to the lumbar design.</li>
<li className="ListItemStyle"><b>Limited External Attachment Points:</b> Fewer external attachment points for additional gear compared to some other packs.</li>
<li className="ListItemStyle"><b>Not Fully Waterproof:</b> While weather-resistant, it is not fully waterproof, which could be a drawback in heavy rain.</li>
<li className="ListItemStyle"><b>Zipper Durability:</b> Some users have reported issues with zipper durability over time.</li>
<li className="ListItemStyle"><b>Limited Internal Organization:</b> May lack internal dividers or organizers for smaller items.</li>
<li className="ListItemStyle"><b>Weight Distribution:</b> While generally comfortable, the lumbar design may not distribute weight as evenly as a full backpack for some users.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default MuddyProSeriesLumbar500HuntingPack;

