import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import OZTENTKingKokodaLoungeCampChairPic from '../images/OZTENTKingKokodaLoungeCampChair.png';
import { useState, useEffect} from "react";

function OZTENTKingKokodaLoungeCampChair () {
  const [mobileOpen, setMobileOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{ marginLeft:"20px", marginTop: "150px" , marginRight:"20px"}}>
    <table>
        <tr>
          <td>
          <h1>OZTENT King Kokoda Lounge Camp Chair</h1>
          </td>
          <td>
            <img alt="OZTENT King Kokoda Lounge Camp Chair" width="50%" height="50%" src={OZTENTKingKokodaLoungeCampChairPic}/>
          </td>
        </tr>
      </table>
    <Typography align="left" variant="body1">
        The OZTENT King Kokoda Lounge Camp Chair is a premium camping chair designed for comfort and durability. It features a fully padded seat and backrest, adjustable lumbar support, and a high weight capacity. The chair is built with a strong steel frame and includes convenient features such as a side table with a cup holder and storage pockets. It is designed to provide a comfortable and relaxing seating experience for outdoor enthusiasts.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Comfort:</b> Fully padded seat and backrest offer superior comfort compared to standard camping chairs.</li>
<li className="ListItemStyle"><b>Adjustable Lumbar Support:</b> Allows users to customize the chair to their comfort needs, reducing back strain.</li>
<li className="ListItemStyle"><b>Durability:</b> Strong steel frame construction ensures long-lasting use.</li>
<li className="ListItemStyle"><b>High Weight Capacity:</b> Can support heavier individuals, making it versatile for different users.</li>
<li className="ListItemStyle"><b>Side Table:</b> Built-in side table with a cup holder adds convenience for holding drinks and snacks.</li>
<li className="ListItemStyle"><b>Storage Pockets:</b> Multiple pockets for storing personal items like phones, books, or keys.</li>
<li className="ListItemStyle"><b>Easy Setup:</b> Designed for quick and easy assembly, making it convenient for camping trips.</li>
<li className="ListItemStyle"><b>Portability:</b> Comes with a carry bag for easy transportation and storage.</li>
<li className="ListItemStyle"><b>Stability:</b> Wide base and sturdy legs provide excellent stability on various terrains.</li>
<li className="ListItemStyle"><b>Aesthetic Design:</b> Modern and stylish design that looks good in any outdoor setting.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Price:</b> Higher cost compared to basic camping chairs, which might not fit all budgets.</li>
<li className="ListItemStyle"><b>Weight:</b> Heavier than some other camping chairs, making it less ideal for backpacking.</li>
<li className="ListItemStyle"><b>Bulkiness:</b> Larger size can make it cumbersome to carry for long distances.</li>
<li className="ListItemStyle"><b>Side Table Stability:</b> The side table might not be as stable as desired when holding heavier items.</li>
<li className="ListItemStyle"><b>Limited Color Options:</b> May not be available in a wide range of colors.</li>
<li className="ListItemStyle"><b>Assembly:</b> While generally easy, some users may find the initial setup instructions unclear.</li>
<li className="ListItemStyle"><b>Storage Space:</b> Takes up more storage space when not in use compared to more compact chairs.</li>
<li className="ListItemStyle"><b>Weather Resistance:</b> Padding and fabric may not be as weather-resistant as some other materials.</li>
<li className="ListItemStyle"><b>Replacement Parts:</b> Difficulty in finding replacement parts if any component breaks.</li>
<li className="ListItemStyle"><b>Non-Reclining:</b> Does not offer reclining features, which some users might prefer for added comfort.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default OZTENTKingKokodaLoungeCampChair;

