import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import GCIOutdoorStowawayRockerCampChairPic from '../images/GCIOutdoorStowawayRockerCampChair.png';
import { useState, useEffect} from "react";

function GCIOutdoorStowawayRockerCampChair () {
  const [mobileOpen, setMobileOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

    {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
    <div style={{ marginLeft:"20px", marginTop: "150px" , marginRight:"20px"}}>
    <table>
        <tr>
          <td>
          <h1>GCI Outdoor Stowaway Rocker Camp Chair</h1>
          </td>
          <td>
            <img alt="GCI Outdoor Stowaway Rocker Camp Chair" width="50%" height="50%" src={GCIOutdoorStowawayRockerCampChairPic}/>
          </td>
        </tr>
      </table>
    <Typography align="left" variant="body1">
        The GCI Outdoor Stowaway Rocker Camp Chair is a portable, foldable rocking chair designed for outdoor use. It features a patented Spring-Action Rocking Technology that allows for smooth rocking motion on various surfaces. The chair is constructed with a sturdy powder-coated steel frame and includes a mesh backrest for ventilation. It also has a built-in carry handle and folds flat for easy storage and transport. The chair is designed to provide comfort and convenience for camping, tailgating, and other outdoor activities.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Comfortable Rocking Motion:</b> The Spring-Action Rocking Technology provides a smooth and relaxing rocking experience.</li>
<li className="ListItemStyle"><b>Portable Design:</b> The chair folds flat and includes a built-in carry handle, making it easy to transport and store.</li>
<li className="ListItemStyle"><b>Sturdy Construction:</b> Made with a powder-coated steel frame, the chair is durable and can support a significant amount of weight.</li>
<li className="ListItemStyle"><b>Ventilated Mesh Backrest:</b> The mesh backrest allows for better airflow, keeping you cool in hot weather.</li>
<li className="ListItemStyle"><b>Easy Setup:</b> The chair is easy to unfold and set up, requiring no additional tools.</li>
<li className="ListItemStyle"><b>Versatile Use:</b> Suitable for various outdoor activities like camping, tailgating, and backyard lounging.</li>
<li className="ListItemStyle"><b>Cup Holder:</b> Includes a built-in cup holder for added convenience.</li>
<li className="ListItemStyle"><b>Stable on Uneven Ground:</b> The design allows for stable rocking even on uneven surfaces.</li>
<li className="ListItemStyle"><b>Compact Storage:</b> Folds down to a compact size, making it easy to store in small spaces.</li>
<li className="ListItemStyle"><b>Weight Capacity:</b> Generally supports up to 250 pounds, accommodating most users.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Weight:</b> The chair can be relatively heavy compared to other portable camping chairs, making it less ideal for long hikes.</li>
<li className="ListItemStyle"><b>Bulkiness:</b> Even when folded, it can be bulkier than some other camping chairs, taking up more space in your vehicle.</li>
<li className="ListItemStyle"><b>Price:</b> It can be more expensive than standard camping chairs, which might be a consideration for budget-conscious buyers.</li>
<li className="ListItemStyle"><b>Limited Color Options:</b> May not come in a wide variety of colors or designs.</li>
<li className="ListItemStyle"><b>Rocking Mechanism Noise:</b> The rocking mechanism can sometimes make noise, which might be bothersome to some users.</li>
<li className="ListItemStyle"><b>No Recline Feature:</b> The chair does not recline, which might be a drawback for those looking for adjustable seating positions.</li>
<li className="ListItemStyle"><b>Limited Padding:</b> The chair may lack sufficient padding for extended periods of sitting.</li>
<li className="ListItemStyle"><b>Assembly Required:</b> While minimal, some users might find the initial setup slightly challenging.</li>
<li className="ListItemStyle"><b>Not Ideal for Soft Ground:</b> The rocking mechanism may not work as well on very soft or sandy ground.</li>
<li className="ListItemStyle"><b>Potential Wear and Tear:</b> Over time, the rocking mechanism and mesh backrest may show signs of wear and tear, especially with frequent use.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GCIOutdoorStowawayRockerCampChair;

