import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useState, useEffect} from "react";
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import ACRElectronicsBivyStickSatelliteCommunicationDevicePic from './images/ACRElectronicsBivyStickSatelliteCommunicationDevice.png';
import GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnologyPicSC from './images/GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology.png';
import GarminGPSMAP67iGPSHandheldAndSatelliteCommunicatorPic from './images/GarminGPSMAP67iGPSHandheldAndSatelliteCommunicator.png';
import GarminInReachMini2SatelliteCommunicatorPic from './images/GarminInReachMini2SatelliteCommunicator.png';
import ZOLEOSatelliteCommunicatorPic from './images/ZOLEOSatelliteCommunicator.png';


function SatelliteCommunicators () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.acrartex.com/products/bivy-stick/",
      imageAlt:"ACR Electronics Bivy Stick Satellite Communication Device",
      imageRef:ACRElectronicsBivyStickSatelliteCommunicationDevicePic,
      itemLink:"../Electronics/SatelliteCommunicators/ACRElectronicsBivyStickSatelliteCommunicationDevice",
      itemBoldText:"ACR Electronics Bivy Stick Satellite Communication Device:",
      itemText:"The ACR Electronics Bivy Stick Satellite Communication Device is a compact and portable satellite communication tool designed for outdoor enthusiasts, adventurers, and emergency responders. It allows users to stay connected in remote areas where traditional cellular networks are unavailable. The device pairs with a smartphone to provide messaging, location sharing, and SOS emergency services via satellite. It is particularly useful for hikers, campers, sailors, and anyone who ventures into areas with limited or no cellular coverage."
    },
	    {
      manuLink:"https://www.garmin.com/en-US/p/623975",
      imageAlt:"Garmin GPSMAP 66i GPS Handheld And Satellite Communicator With InReach Technology",
      imageRef:GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnologyPicSC,
      itemLink:"../Electronics/SatelliteCommunicators/GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology",
      itemBoldText:"Garmin GPSMAP 66i GPS Handheld And Satellite Communicator With InReach Technology:",
      itemText:"The Garmin GPSMAP 66i is a robust GPS handheld device that integrates inReach satellite communication technology. It is designed for outdoor enthusiasts, adventurers, and professionals who require reliable navigation and communication capabilities in remote areas. The device offers a combination of GPS navigation, topographic mapping, and satellite messaging, making it a versatile tool for hiking, trekking, and other outdoor activities."
    },
	    {
      manuLink:"https://www.garmin.com/en-US/p/890109",
      imageAlt:"Garmin GPSMAP 67i GPS Handheld And Satellite Communicator",
      imageRef:GarminGPSMAP67iGPSHandheldAndSatelliteCommunicatorPic,
      itemLink:"../Electronics/SatelliteCommunicators/GarminGPSMAP67iGPSHandheldAndSatelliteCommunicator",
      itemBoldText:"Garmin GPSMAP 67i GPS Handheld And Satellite Communicator:",
      itemText:"The Garmin GPSMAP 67i GPS Handheld and Satellite Communicator is a versatile and rugged device designed for outdoor enthusiasts, adventurers, and professionals who need reliable navigation and communication capabilities in remote areas. It combines GPS navigation with satellite communication, allowing users to send and receive messages, track their location, and call for emergency assistance via the Iridium satellite network. The device is built to withstand harsh environments and offers a range of features to enhance safety and navigation."
    },
	    {
      manuLink:"https://www.garmin.com/en-US/p/765374",
      imageAlt:"Garmin InReach Mini 2 Satellite Communicator",
      imageRef:GarminInReachMini2SatelliteCommunicatorPic,
      itemLink:"../Electronics/SatelliteCommunicators/GarminInReachMini2SatelliteCommunicator",
      itemBoldText:"Garmin InReach Mini 2 Satellite Communicator:",
      itemText:"The Garmin inReach Mini 2 is a compact, lightweight satellite communicator designed for adventurers and outdoor enthusiasts who demand reliable connectivity in remote areas. With two-way messaging capabilities via the Iridium satellite network, you can stay in touch with friends and family even when cellular coverage is unavailable."
    },
	    {
      manuLink:"https://www.zoleo.com/en-us/satellite-communicator",
      imageAlt:"ZOLEO Satellite Communicator",
      imageRef:ZOLEOSatelliteCommunicatorPic,
      itemLink:"../Electronics/SatelliteCommunicators/ZOLEOSatelliteCommunicator",
      itemBoldText:"ZOLEO Satellite Communicator:",
      itemText:"The ZOLEO Satellite Communicator is a compact, rugged device designed to keep you connected when you're off the grid. It pairs with your smartphone to provide seamless global messaging via satellite, cellular, and Wi-Fi networks. The device is particularly useful for outdoor enthusiasts, remote workers, and anyone who needs reliable communication in areas without cellular coverage. It also offers SOS alerting and weather forecasting features, making it a versatile tool for safety and convenience."
    },
	
    ];

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
      <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1">

	<h1>Satellite Communicators</h1>
	<br/>
  If you get far off the beaten path, you likely won’t have cell service where you are. Having a satellite communicator with you can provide that link to friends and family, or emergency services, when needed. I have heard many stories of people being in the woods by themselves and getting hurt, without any way to communicate with the outside world. A satellite communicator can bring peace of mind to family members when you are deep in the outdoors. Below are some terrific options to have with you.

    </Typography>
    <br/>

    {items.map((element)=> (
      <div>
    <Paper elevation={3}
      sx={{
        p: 2,
        margin: 'auto',
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
    <Grid container spacing={12} rowSpacing={1}>
    <Grid item>
    <Link href={element.menuLink} target="_blank">
      <ButtonBase sx={{ width: 128, height: 128 }}>
      <img alt={element.imageAlt} height="160px" className='GridImage' src={element.imageRef}/>
      </ButtonBase>
      </Link>
    </Grid>
    <Grid item xs={12} sm container>
      <Grid item xs container direction="column" spacing={9}>
        <Grid item xs>
          <Typography gutterBottom variant="subtitle1" component="div">
          <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
          
          </Typography>
          <Typography align="center">
            <Link href={element.manuLink} target="_blank">
          <Button variant="contained">More Product Info</Button>
          </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  </Paper>
  <br/>
  </div>
  ))}

    </div>
    </>
  );
}

export default SatelliteCommunicators;

