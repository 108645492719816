import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import GCIOutdoorKickbackRockerCampChairPic from '../images/GCIOutdoorKickbackRockerCampChair.png';
import { useState, useEffect} from "react";

function GCIOutdoorKickbackRockerCampChair () {
  const [mobileOpen, setMobileOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{ marginLeft:"20px", marginTop: "150px" , marginRight:"20px"}}>
    <table>
        <tr>
          <td>
          <h1>GCI Outdoor Kickback Rocker Camp Chair</h1>
          </td>
          <td>
            <img alt="GCI Outdoor Kickback Rocker Camp Chair" width="50%" height="50%" src={GCIOutdoorKickbackRockerCampChairPic}/>
          </td>
        </tr>
      </table>
    <Typography align="left" variant="body1">
        The GCI Outdoor Kickback Rocker Camp Chair is a portable, folding camp chair designed for outdoor use. It features a patented Spring-Action Rocking Technology™ that allows for smooth rocking motion on various surfaces. The chair is constructed with a sturdy powder-coated steel frame and includes a mesh backrest for ventilation. It also has a built-in beverage holder and a carry handle for easy transport. The chair is marketed as a comfortable and convenient option for camping, tailgating, or other outdoor activities.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Comfortable Rocking Motion:</b> The Spring-Action Rocking Technology™ provides a smooth and relaxing rocking experience.</li>
<li className="ListItemStyle"><b>Sturdy Construction:</b> Made with a powder-coated steel frame, the chair is durable and can support a significant amount of weight.</li>
<li className="ListItemStyle"><b>Portable:</b> The chair folds easily and includes a carry handle, making it convenient to transport.</li>
<li className="ListItemStyle"><b>Ventilated Backrest:</b> The mesh backrest allows for airflow, keeping you cool in warm weather.</li>
<li className="ListItemStyle"><b>Beverage Holder:</b> A built-in cup holder adds convenience for holding drinks.</li>
<li className="ListItemStyle"><b>Easy Setup:</b> The chair is simple to set up and fold down, requiring no assembly.</li>
<li className="ListItemStyle"><b>Versatile Use:</b> Suitable for various outdoor activities such as camping, tailgating, and backyard gatherings.</li>
<li className="ListItemStyle"><b>Compact Storage:</b> When folded, the chair takes up minimal space, making it easy to store.</li>
<li className="ListItemStyle"><b>Stable on Uneven Ground:</b> The design allows for stability even on uneven surfaces.</li>
<li className="ListItemStyle"><b>Reasonable Price:</b> Generally considered to be good value for the features and quality it offers.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Weight:</b> At around 10-12 pounds, it may be considered heavy for some users, especially for backpacking.</li>
<li className="ListItemStyle"><b>Limited Color Options:</b> The chair may not be available in a wide range of colors.</li>
<li className="ListItemStyle"><b>No Headrest:</b> Lacks a headrest, which might be a drawback for those looking for full head and neck support.</li>
<li className="ListItemStyle"><b>Bulky When Folded:</b> Although it folds, it can still be somewhat bulky compared to other camping chairs.</li>
<li className="ListItemStyle"><b>Rocking Mechanism Noise:</b> The rocking mechanism can sometimes be noisy, which might be bothersome in quiet settings.</li>
<li className="ListItemStyle"><b>Seat Height:</b> The seat is lower to the ground compared to some other camp chairs, which might be uncomfortable for taller individuals.</li>
<li className="ListItemStyle"><b>Limited Weight Capacity:</b> While sturdy, it may not support weights above a certain limit (usually around 250 pounds).</li>
<li className="ListItemStyle"><b>No Recline Feature:</b> The chair does not offer a reclining option, limiting its versatility.</li>
<li className="ListItemStyle"><b>Potential for Wear and Tear:</b> The mesh backrest, while ventilated, may be prone to wear and tear over time.</li>
<li className="ListItemStyle"><b>Not Ideal for Indoor Use:</b> The rocking feature is designed for outdoor use and may not work well on indoor flooring.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GCIOutdoorKickbackRockerCampChair;

