import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ZippoTinderShredsFireStarterPic from '../images/ZippoTinderShredsFireStarter.png';
import { useState, useEffect} from "react";

function ZippoTinderShredsFireStarter () {
  const [mobileOpen, setMobileOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{ marginLeft:"20px", marginTop: "150px" , marginRight:"20px"}}>
    <table>
        <tr>
          <td>
          <h1>Zippo Tinder Shreds Fire Starter</h1>
          </td>
          <td>
            <img alt="Zippo Tinder Shreds Fire Starter" width="50%" height="50%" src={ZippoTinderShredsFireStarterPic}/>
          </td>
        </tr>
      </table>
    <Typography align="left" variant="body1">
        The Zippo Tinder Shreds Fire Starter is a compact and convenient fire-starting aid designed for outdoor enthusiasts, campers, and survivalists. It consists of shredded, treated pine that ignites easily and burns hot, making it an effective tool for starting fires in various weather conditions. The shreds come in a resealable pouch, ensuring they remain dry and ready for use whenever needed.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Ease of Ignition:</b> The shreds ignite quickly and easily, even with a spark from a flint striker.</li>
<li className="ListItemStyle"><b>Compact and Portable:</b> The resealable pouch is lightweight and easy to carry, making it ideal for backpacking and camping.</li>
<li className="ListItemStyle"><b>Weather-Resistant:</b> The treated pine shreds can ignite even in damp conditions, providing reliable fire-starting capability.</li>
<li className="ListItemStyle"><b>Long Burn Time:</b> Once ignited, the shreds burn long enough to help get larger kindling and firewood going.</li>
<li className="ListItemStyle"><b>Eco-Friendly:</b> Made from natural pine, the product is biodegradable and environmentally friendly.</li>
<li className="ListItemStyle"><b>Versatile:</b> Suitable for a variety of outdoor activities, including camping, hiking, and emergency preparedness.</li>
<li className="ListItemStyle"><b>Resealable Pouch:</b> Keeps the tinder dry and protected from the elements until needed.</li>
<li className="ListItemStyle"><b>No Chemical Smell:</b> Unlike some fire starters, these shreds do not emit a strong chemical odor.</li>
<li className="ListItemStyle"><b>Safe to Use:</b> The product is non-toxic and safe to handle.</li>
<li className="ListItemStyle"><b>Cost-Effective:</b> Provides a reliable fire-starting solution at a reasonable price.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Limited Quantity:</b> The amount of tinder in each pouch may not be sufficient for extended trips without carrying multiple packs.</li>
<li className="ListItemStyle"><b>Requires Additional Tools:</b> A lighter, matches, or a flint striker is needed to ignite the shreds.</li>
<li className="ListItemStyle"><b>Not Waterproof:</b> While weather-resistant, the shreds are not fully waterproof and can become less effective if soaked.</li>
<li className="ListItemStyle"><b>Burns Quickly:</b> Although it burns long enough to start a fire, the shreds themselves do not provide sustained heat.</li>
<li className="ListItemStyle"><b>Packaging Durability:</b> The resealable pouch can be prone to tearing if not handled carefully.</li>
<li className="ListItemStyle"><b>Not Ideal for Large Fires:</b> Best suited for starting small to medium fires; may struggle with larger setups.</li>
<li className="ListItemStyle"><b>Availability:</b> May not be readily available in all regions or stores.</li>
<li className="ListItemStyle"><b>Storage Conditions:</b> Needs to be stored in a dry place to maintain effectiveness.</li>
<li className="ListItemStyle"><b>Potential for Overuse:</b> Users may go through the shreds quickly if not used sparingly.</li>
<li className="ListItemStyle"><b>Environmental Impact:</b> While biodegradable, the production and packaging still have some environmental footprint.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default ZippoTinderShredsFireStarter;

