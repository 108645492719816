import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AscendHoodoo20MummySleepingBagPic from '../images/AscendHoodoo20MummySleepingBag.png';
import { useState, useEffect} from "react";

function AscendHoodoo20MummySleepingBag () {
  const [mobileOpen, setMobileOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{ marginLeft:"20px", marginTop: "150px" , marginRight:"20px"}}>
    <table>
        <tr>
          <td>
          <h1>Ascend Hoodoo 20º Mummy Sleeping Bag</h1>
          </td>
          <td>
            <img alt="Ascend Hoodoo 20º Mummy Sleeping Bag" width="50%" height="50%" src={AscendHoodoo20MummySleepingBagPic}/>
          </td>
        </tr>
      </table>
    <Typography align="left" variant="body1">
        The Ascend Hoodoo 20º Mummy Sleeping Bag is designed for outdoor enthusiasts who need reliable warmth and comfort in cooler conditions. This mummy-style sleeping bag is rated for temperatures as low as 20ºF, making it suitable for three-season use. It features a snug fit to retain body heat, a durable outer shell, and a soft inner lining for added comfort. The sleeping bag also includes a hood for extra warmth around the head and face, and it typically comes with a compression sack for easy packing and transport.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Temperature Rating:</b> Effective for temperatures down to 20ºF, making it suitable for three-season camping.</li>
<li className="ListItemStyle"><b>Mummy Design:</b> The snug fit helps to retain body heat more efficiently than rectangular sleeping bags.</li>
<li className="ListItemStyle"><b>Hood:</b> The integrated hood provides extra warmth for the head and neck area.</li>
<li className="ListItemStyle"><b>Durable Outer Shell:</b> Made from robust materials that can withstand rough outdoor conditions.</li>
<li className="ListItemStyle"><b>Soft Inner Lining:</b> Provides a comfortable sleeping experience.</li>
<li className="ListItemStyle"><b>Lightweight:</b> Generally lighter than many other sleeping bags with similar temperature ratings, making it easier to carry.</li>
<li className="ListItemStyle"><b>Compression Sack:</b> Comes with a compression sack for easy packing and space-saving storage.</li>
<li className="ListItemStyle"><b>Draft Collar and Zipper Baffle:</b> Helps to prevent heat loss through the zipper and opening.</li>
<li className="ListItemStyle"><b>Affordable:</b> Often priced competitively compared to other sleeping bags with similar features.</li>
<li className="ListItemStyle"><b>Versatility:</b> Suitable for a variety of outdoor activities, including backpacking, camping, and hiking.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Fit:</b> The mummy design can feel restrictive for some users, especially those who prefer more room to move.</li>
<li className="ListItemStyle"><b>Weight:</b> While lightweight, it may still be heavier than some high-end ultralight sleeping bags.</li>
<li className="ListItemStyle"><b>Bulk:</b> Even with the compression sack, it can be bulkier than some other options.</li>
<li className="ListItemStyle"><b>Zipper Issues:</b> Some users report that the zipper can snag or be difficult to operate.</li>
<li className="ListItemStyle"><b>Warmth Limit:</b> May not be warm enough for winter camping or temperatures significantly below 20ºF.</li>
<li className="ListItemStyle"><b>Material Noisiness:</b> The outer shell can be noisy when moving around inside the bag.</li>
<li className="ListItemStyle"><b>Breathability:</b> May not be as breathable as some other sleeping bags, potentially causing overheating in milder conditions.</li>
<li className="ListItemStyle"><b>Hood Adjustment:</b> The hood may not fit all head sizes comfortably, and adjusting it can be cumbersome.</li>
<li className="ListItemStyle"><b>Pack Size:</b> Even when compressed, it might take up more space in a backpack than some other models.</li>
<li className="ListItemStyle"><b>Durability of Inner Lining:</b> The inner lining, while comfortable, may wear out faster than the outer shell.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default AscendHoodoo20MummySleepingBag;

