import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useState, useEffect} from "react";
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import HavalonTalonHuntReplaceableBladeKnifeKitPic from './images/HavalonTalonHuntReplaceableBladeKnifeKit.png';
import OutdoorEdgeButcherMax11PieceGameProcessingSetPic from './images/OutdoorEdgeButcherMax11PieceGameProcessingSet.png';
import HavalonKnivesPirantaEdgeReplaceableBladeFoldingKnifePic from './images/HavalonKnivesPirantaEdgeReplaceableBladeFoldingKnife.png';
import OutdoorEdgeSwingBlazeSwingBladeDropPointSkinnerBigGameGuttingKnifePic from './images/OutdoorEdgeSwingBlazeSwingBladeDropPointSkinnerBigGameGuttingKnife.png';
import Pursuit7PieceKnifeSetPic from './images/Pursuit7PieceKnifeSet.png';


function HuntingKnives () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.havalon.com/havalon-talon-2?viewmode=grid&orderby=10",
      imageAlt:"Havalon Talon Hunt Replaceable Blade Knife Kit",
      imageRef:HavalonTalonHuntReplaceableBladeKnifeKitPic,
      itemLink:"../OutdoorGear/HuntingKnives/HavalonTalonHuntReplaceableBladeKnifeKit",
      itemBoldText:"Havalon Talon Hunt Replaceable Blade Knife Kit:",
      itemText:"The Havalon Talon Hunt Replaceable Blade Knife Kit is designed for hunters and outdoor enthusiasts who require a versatile and reliable cutting tool. The kit features a handle with a quick-change mechanism that allows users to switch between different types of blades quickly and safely. This system provides the flexibility to use the best blade for various tasks, from skinning and gutting to filleting and general cutting. The kit typically includes multiple blades and a storage case for convenience."
    },
	    {
      manuLink:"https://www.outdooredge.com/products/gamepro?pr_prod_strat=e5_desc&pr_rec_id=8ef2cffd8&pr_rec_pid=5046494330916&pr_ref_pid=5308743747&pr_seq=uniform",
      imageAlt:"Outdoor Edge Butcher Max 11-Piece Game Processing Set",
      imageRef:OutdoorEdgeButcherMax11PieceGameProcessingSetPic,
      itemLink:"../OutdoorGear/HuntingKnives/OutdoorEdgeButcherMax11PieceGameProcessingSet",
      itemBoldText:"Outdoor Edge Butcher Max 11-Piece Game Processing Set:",
      itemText:"The Outdoor Edge Butcher Max 11-Piece Game Processing Set is a comprehensive kit designed for hunters and outdoor enthusiasts who need to process game in the field or at home. The set includes a variety of tools to handle every step of the butchering process, from skinning and gutting to deboning and slicing. The tools are made from high-quality materials and come in a convenient carrying case for easy transport and organization."
    },
	    {
      manuLink:"https://www.havalon.com/piranta-60a-edge-pack-includes",
      imageAlt:"Havalon Knives Piranta-Edge Replaceable Blade Folding Knife",
      imageRef:HavalonKnivesPirantaEdgeReplaceableBladeFoldingKnifePic,
      itemLink:"../OutdoorGear/HuntingKnives/HavalonKnivesPirantaEdgeReplaceableBladeFoldingKnife",
      itemBoldText:"Havalon Knives Piranta-Edge Replaceable Blade Folding Knife:",
      itemText:"The Havalon Knives Piranta-Edge Replaceable Blade Folding Knife is a popular choice among hunters, fishermen, and outdoor enthusiasts. This knife is known for its precision, sharpness, and convenience due to its replaceable blade system. The Piranta-Edge model features a lightweight design, making it easy to carry, and it comes with multiple replacement blades, ensuring that you always have a sharp edge ready for use."
    },
	    {
      manuLink:"https://www.outdooredge.com/products/swingblade-sb-10n?srsltid=AfmBOooq05xwt2BHPcilQ0QO_pB1XFH4t5dH8nao9hSQluzHrfCce3Qa",
      imageAlt:"Outdoor Edge SwingBlaze SwingBlade Drop Point Skinner/Big Game Gutting Knife",
      imageRef:OutdoorEdgeSwingBlazeSwingBladeDropPointSkinnerBigGameGuttingKnifePic,
      itemLink:"../OutdoorGear/HuntingKnives/OutdoorEdgeSwingBlazeSwingBladeDropPointSkinnerBigGameGuttingKnife",
      itemBoldText:"Outdoor Edge SwingBlaze SwingBlade Drop Point Skinner/Big Game Gutting Knife:",
      itemText:"The Outdoor Edge SwingBlaze SwingBlade is a versatile hunting knife designed for big game hunters. It features a unique dual-blade design: a drop-point skinner blade and a gutting blade. The blades can be easily switched with the push of a button, making it a convenient tool for field dressing. The knife is made from high-quality stainless steel and comes with a rubberized handle for a secure grip. It also includes a nylon sheath for safe storage and transport."
    },
	    {
      manuLink:"https://www.cabelas.com/shop/en/pursuit-7-piece-knife-set",
      imageAlt:"Pursuit 7-Piece Knife Set",
      imageRef:Pursuit7PieceKnifeSetPic,
      itemLink:"../OutdoorGear/HuntingKnives/Pursuit7PieceKnifeSet",
      itemBoldText:"Pursuit 7-Piece Knife Set:",
      itemText:"The Pursuit 7-Piece Knife Set is a comprehensive collection designed for both amateur cooks and professional chefs. This set typically includes a variety of knives such as a chef's knife, bread knife, utility knife, paring knife, and more, along with a knife block or storage solution. The knives are crafted from high-quality materials, often featuring stainless steel blades and ergonomic handles for comfortable use. The set aims to provide versatility and durability, making it a valuable addition to any kitchen."
    },
	
    ];

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
      <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1">

    <h1>Hunting Knives</h1>
    The right hunting knife is an incredibly valuable tool used to finish out the hunt. Processing the game you’ve worked so hard to harvest is where the work begins. That work can be made much easier when you have a great knife for the job. Below are some great options to finish out your successful hunt.

    </Typography>
    <br/>

    {items.map((element)=> (
      <div>
    <Paper elevation={3}
      sx={{
        p: 2,
        margin: 'auto',
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
    <Grid container spacing={12} rowSpacing={1}>
    <Grid item>
    <Link href={element.menuLink} target="_blank">
      <ButtonBase sx={{ width: 128, height: 128 }}>
      <img alt={element.imageAlt} height="160px" className='GridImage' src={element.imageRef}/>
      </ButtonBase>
      </Link>
    </Grid>
    <Grid item xs={12} sm container>
      <Grid item xs container direction="column" spacing={9}>
        <Grid item xs>
          <Typography gutterBottom variant="subtitle1" component="div">
          <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
          
          </Typography>
          <Typography align="center">
            <Link href={element.manuLink} target="_blank">
          <Button variant="contained">More Product Info</Button>
          </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  </Paper>
  <br/>
  </div>
  ))}

    </div>
    </>
  );
}

export default HuntingKnives;

