import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MotorolaTalkaboutT1102WayRadiosPic from '../images/MotorolaTalkaboutT1102WayRadios.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function MotorolaTalkaboutT1102WayRadios () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Motorola Talkabout T110 2-Way Radios</h1>

            <img alt="Motorola Talkabout T110 2-Way Radios" height="130px" src={MotorolaTalkaboutT1102WayRadiosPic}/>

    <Typography align="left" variant="body1">
        The Motorola Talkabout T110 2-Way Radios are compact, easy-to-use communication devices designed for personal and recreational use. These radios are ideal for activities such as hiking, camping, and neighborhood communication. They offer a range of features that make them suitable for keeping in touch with family and friends over short distances.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Ease of Use:</b> Simple interface and controls make it user-friendly for all ages.</li>
<li className="ListItemStyle"><b>Compact Design:</b> Lightweight and portable, making it easy to carry around.</li>
<li className="ListItemStyle"><b>Affordable:</b> Budget-friendly option for basic communication needs.</li>
<li className="ListItemStyle"><b>Good Range:</b> Effective range for short-distance communication, typically up to 16 miles in optimal conditions.</li>
<li className="ListItemStyle"><b>Durability:</b> Sturdy build quality that can withstand minor drops and rough handling.</li>
<li className="ListItemStyle"><b>Battery Life:</b> Decent battery life, often lasting up to 18 hours on a full charge.</li>
<li className="ListItemStyle"><b>Clear Sound Quality:</b> Provides clear and crisp audio, minimizing misunderstandings.</li>
<li className="ListItemStyle"><b>Channel Options:</b> Multiple channels to choose from, reducing the likelihood of interference.</li>
<li className="ListItemStyle"><b>NOAA Weather Alerts:</b> Some models come with weather alerts to keep you informed of changing conditions.</li>
<li className="ListItemStyle"><b>Hands-Free Operation:</b> VOX (Voice-Activated Transmission) feature allows hands-free communication.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Limited Range in Urban Areas:</b> Range can be significantly reduced in areas with many obstructions.</li>
<li className="ListItemStyle"><b>Basic Features:</b> Lacks advanced features found in higher-end models, such as GPS or Bluetooth.</li>
<li className="ListItemStyle"><b>Non-Rechargeable Batteries:</b> Some models require disposable batteries, which can be inconvenient and costly over time.</li>
<li className="ListItemStyle"><b>No Privacy Codes:</b> Limited privacy options, making it easier for others to listen in on conversations.</li>
<li className="ListItemStyle"><b>Weather Resistance:</b> Not fully waterproof, limiting use in extreme weather conditions.</li>
<li className="ListItemStyle"><b>No Display Backlight:</b> Can be difficult to read the display in low-light conditions.</li>
<li className="ListItemStyle"><b>Limited Accessories:</b> Fewer accessory options compared to more advanced models.</li>
<li className="ListItemStyle"><b>Fixed Antenna:</b> Non-removable antenna limits the ability to upgrade for better range.</li>
<li className="ListItemStyle"><b>No Emergency Features:</b> Lacks emergency alert features that could be useful in critical situations.</li>
<li className="ListItemStyle"><b>Basic Audio Alerts:</b> Limited to basic beeps and tones, with no customizable alert options.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default MotorolaTalkaboutT1102WayRadios;

