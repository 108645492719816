import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useState, useEffect} from "react";
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import BadlandsATX25HuntingPackPic from './images/BadlandsATX25HuntingPack.png';
import BadlandsRiseHuntingPackPic from './images/BadlandsRiseHuntingPack.png';
import CabelasMultiDayHuntingPackPic from './images/CabelasMultiDayHuntingPack.png';
import CabelasVersaHunt80LHuntingPackPic from './images/CabelasVersaHunt80LHuntingPack.png';
import MuddyProSeriesLumbar500HuntingPackPic from './images/MuddyProSeriesLumbar500HuntingPack.png';


function HuntingPacks () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.badlandsgear.com/products/atx-25?srsltid=AfmBOopmIR4jHQ-pUKdebjLjmGaHP3uXGmjMUhoUcW_vTFM_XS4U2swz",
      imageAlt:"Badlands ATX 25 Hunting Pack",
      imageRef:BadlandsATX25HuntingPackPic,
      itemLink:"../OutdoorGear/HuntingPacks/BadlandsATX25HuntingPack",
      itemBoldText:"Badlands ATX 25 Hunting Pack:",
      itemText:"The Badlands ATX 25 Hunting Pack is designed for hunters who require a durable, versatile, and comfortable pack for their outdoor adventures. Known for its high-quality materials and innovative design, the ATX 25 offers ample storage, ergonomic support, and a range of features tailored to meet the needs of serious hunters. It is built to withstand harsh conditions while providing easy access to gear and equipment."
    },
	    {
      manuLink:"https://www.badlandsgear.com/products/rise-pack?_pos=1&_sid=c9c6a72f7&_ss=r",
      imageAlt:"Badlands Rise Hunting Pack",
      imageRef:BadlandsRiseHuntingPackPic,
      itemLink:"../OutdoorGear/HuntingPacks/BadlandsRiseHuntingPack",
      itemBoldText:"Badlands Rise Hunting Pack:",
      itemText:"The Badlands Rise Hunting Pack is designed for hunters who need a reliable, durable, and comfortable backpack for their outdoor adventures. Known for its rugged construction and thoughtful features, this pack aims to provide ample storage and easy access to essential gear. It typically includes a variety of compartments, hydration compatibility, and ergonomic design elements to ensure comfort during long treks."
    },
	    {
      manuLink:"https://www.cabelas.com/shop/en/cabelas-multi-day-hunting-pack",
      imageAlt:"Cabela's Multi-Day Hunting Pack",
      imageRef:CabelasMultiDayHuntingPackPic,
      itemLink:"../OutdoorGear/HuntingPacks/CabelasMultiDayHuntingPack",
      itemBoldText:"Cabela's Multi-Day Hunting Pack:",
      itemText:"The Cabela's Multi-Day Hunting Pack is designed for hunters who need a reliable and durable backpack for extended trips in the wilderness. This pack is built to carry all the essential gear required for multi-day hunts, including food, clothing, and hunting equipment. It features multiple compartments, adjustable straps, and is made from high-quality materials to withstand harsh outdoor conditions."
    },
	    {
      manuLink:"https://www.cabelas.com/shop/en/cabelas-versahunt-80l-hunting-pack?searchTerm=VersaHunt%2080L",
      imageAlt:"Cabela's VersaHunt 80L Hunting Pack",
      imageRef:CabelasVersaHunt80LHuntingPackPic,
      itemLink:"../OutdoorGear/HuntingPacks/CabelasVersaHunt80LHuntingPack",
      itemBoldText:"Cabela's VersaHunt 80L Hunting Pack:",
      itemText:"The Cabela's VersaHunt 80L Hunting Pack is a versatile and durable backpack designed specifically for hunters. With an 80-liter capacity, it offers ample space for gear, supplies, and game. The pack is constructed from high-quality materials to withstand rugged outdoor conditions and features multiple compartments and pockets for organized storage. It also includes a comfortable, adjustable harness system to distribute weight evenly and reduce fatigue during long treks."
    },
	    {
      manuLink:"https://www.gomuddy.com/products/pro-lumbar-500-pack/?srsltid=AfmBOoob47O0MlbarOfiW22Xzs_7B0dDncnEoTh7eY_mgwzHgWj43leY",
      imageAlt:"Muddy Pro Series Lumbar 500 Hunting Pack",
      imageRef:MuddyProSeriesLumbar500HuntingPackPic,
      itemLink:"../OutdoorGear/HuntingPacks/MuddyProSeriesLumbar500HuntingPack",
      itemBoldText:"Muddy Pro Series Lumbar 500 Hunting Pack:",
      itemText:"The Muddy Pro Series Lumbar 500 Hunting Pack is designed for hunters who need a reliable and functional pack to carry their gear. This lumbar-style pack offers a balance of storage capacity, comfort, and durability, making it suitable for various hunting conditions. It features multiple compartments, adjustable straps, and is constructed from durable materials to withstand the rigors of the outdoors."
    },
	
    ];

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
      <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1">

	<h1>Hunting Packs</h1>

  Having a comfortable hunting pack that has the necessary storage to carry everything you need can make a hunt much more enjoyable. Whether you are on a day hunt or plan to be out several days, your pack is an important thing to have. Below are some fantastic options for your next hunt.

    </Typography>
    <br/>

    {items.map((element)=> (
      <div>
    <Paper elevation={3}
      sx={{
        p: 2,
        margin: 'auto',
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
    <Grid container spacing={12} rowSpacing={1}>
    <Grid item>
    <Link href={element.menuLink} target="_blank">
      <ButtonBase sx={{ width: 128, height: 128 }}>
      <img alt={element.imageAlt} height="160px" className='GridImage' src={element.imageRef}/>
      </ButtonBase>
      </Link>
    </Grid>
    <Grid item xs={12} sm container>
      <Grid item xs container direction="column" spacing={9}>
        <Grid item xs>
          <Typography gutterBottom variant="subtitle1" component="div">
          <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
          
          </Typography>
          <Typography align="center">
            <Link href={element.manuLink} target="_blank">
          <Button variant="contained">More Product Info</Button>
          </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  </Paper>
  <br/>
  </div>
  ))}

    </div>
    </>
  );
}

export default HuntingPacks;

