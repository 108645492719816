import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MidlandT77VP5XTalkerExtreme2WayRadios2PackPic from '../images/MidlandT77VP5XTalkerExtreme2WayRadios2Pack.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function MidlandT77VP5XTalkerExtreme2WayRadios2Pack () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Midland T77VP5 X-Talker Extreme 2-Way Radios 2-Pack</h1>

            <img alt="Midland T77VP5 X-Talker Extreme 2-Way Radios 2-Pack" height="130px" src={MidlandT77VP5XTalkerExtreme2WayRadios2PackPic}/>

    <Typography align="left" variant="body1">
        The Midland T77VP5 X-Talker Extreme 2-Way Radios are designed for reliable communication in a variety of outdoor and indoor settings. This 2-pack set of radios offers a range of features that make it suitable for activities such as hiking, camping, and event coordination. The radios come with a range of up to 38 miles in open areas with little or no obstruction, and they include 36 channels and 121 privacy codes to minimize interference. Additional features include NOAA weather alerts, hands-free operation, and a rugged design for durability.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Long Range:</b> Offers up to 38 miles of communication range in open areas, which is ideal for outdoor activities.</li>
<li className="ListItemStyle"><b>Multiple Channels:</b> Includes 36 channels and 121 privacy codes to reduce interference and ensure clear communication.</li>
<li className="ListItemStyle"><b>NOAA Weather Alerts:</b> Provides real-time weather updates and alerts, which is crucial for outdoor safety.</li>
<li className="ListItemStyle"><b>Durable Design:</b> Built to withstand harsh conditions, making it suitable for rugged outdoor use.</li>
<li className="ListItemStyle"><b>Hands-Free Operation:</b> Features eVOX technology for voice-activated transmission, allowing for hands-free communication.</li>
<li className="ListItemStyle"><b>Battery Life:</b> Comes with rechargeable batteries and a dual desktop charger, ensuring long-lasting use.</li>
<li className="ListItemStyle"><b>Clear Sound Quality:</b> Offers clear audio quality, even at extended ranges.</li>
<li className="ListItemStyle"><b>Compact and Lightweight:</b> Easy to carry and handle, making it convenient for various activities.</li>
<li className="ListItemStyle"><b>Emergency Features:</b> Includes an SOS siren for emergency situations.</li>
<li className="ListItemStyle"><b>Accessories Included:</b> Comes with a variety of accessories, including belt clips, headsets, and a carrying case.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Range Limitations:</b> The advertised 38-mile range is only achievable in ideal conditions; actual range may be significantly less in urban or obstructed areas.</li>
<li className="ListItemStyle"><b>Battery Life:</b> While rechargeable, the battery life may not be sufficient for extended use without access to charging facilities.</li>
<li className="ListItemStyle"><b>Complexity:</b> The multitude of features and settings can be overwhelming for first-time users.</li>
<li className="ListItemStyle"><b>Build Quality:</b> Some users have reported issues with the durability of certain components, such as the belt clips.</li>
<li className="ListItemStyle"><b>Price:</b> Higher cost compared to some other 2-way radios on the market.</li>
<li className="ListItemStyle"><b>Weather Alert Sensitivity:</b> NOAA weather alerts can be overly sensitive, leading to frequent notifications.</li>
<li className="ListItemStyle"><b>Limited Waterproofing:</b> Not fully waterproof, which can be a drawback for water-based activities.</li>
<li className="ListItemStyle"><b>Charging Time:</b> Takes a considerable amount of time to fully charge the batteries.</li>
<li className="ListItemStyle"><b>Interference:</b> Despite privacy codes, some users have experienced interference in crowded areas.</li>
<li className="ListItemStyle"><b>Size:</b> Although compact, the radios may still be bulkier compared to some ultra-compact models.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default MidlandT77VP5XTalkerExtreme2WayRadios2Pack;

