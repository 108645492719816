import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useState, useEffect} from "react";
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import GCIOutdoorFreestyleRockerCampChairPic from './images/GCIOutdoorFreestyleRockerCampChair.png';
import GCIOutdoorKickbackRockerCampChairPic from './images/GCIOutdoorKickbackRockerCampChair.png';
import AscendRestLightCampChairPic from './images/AscendRestLightCampChair.png';
import GCIOutdoorStowawayRockerCampChairPic from './images/GCIOutdoorStowawayRockerCampChair.png';
import OZTENTKingKokodaLoungeCampChairPic from './images/OZTENTKingKokodaLoungeCampChair.png';


function CampChairs () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://gcioutdoor.com/collections/rockers/products/freestyle-rocker-rocking-chair",
      imageAlt:"GCI Outdoor Freestyle Rocker Camp Chair",
      imageRef:GCIOutdoorFreestyleRockerCampChairPic,
      itemLink:"../Camping/CampChairs/GCIOutdoorFreestyleRockerCampChair",
      itemBoldText:"GCI Outdoor Freestyle Rocker Camp Chair:",
      itemText:"The GCI Outdoor Freestyle Rocker Camp Chair is a portable, folding rocking chair designed for outdoor activities such as camping, tailgating, and backyard gatherings. It features a patented spring-action rocking technology, a sturdy powder-coated steel frame, and a convenient carry handle for easy transport. The chair also includes padded armrests, a beverage holder, and a mesh backrest for added comfort and breathability."
    },
	    {
      manuLink:"https://gcioutdoor.com/collections/rockers/products/kickback-rocker",
      imageAlt:"GCI Outdoor Kickback Rocker Camp Chair",
      imageRef:GCIOutdoorKickbackRockerCampChairPic,
      itemLink:"../Camping/CampChairs/GCIOutdoorKickbackRockerCampChair",
      itemBoldText:"GCI Outdoor Kickback Rocker Camp Chair:",
      itemText:"The GCI Outdoor Kickback Rocker Camp Chair is a portable, folding camp chair designed for outdoor use. It features a patented Spring-Action Rocking Technology™ that allows for smooth rocking motion on various surfaces. The chair is constructed with a sturdy powder-coated steel frame and includes a mesh backrest for ventilation. It also has a built-in beverage holder and a carry handle for easy transport. The chair is marketed as a comfortable and convenient option for camping, tailgating, or other outdoor activities."
    },
	    {
      manuLink:"https://www.cabelas.com/shop/en/ascend-rest-light-camp-chair",
      imageAlt:"Ascend Rest/Light Camp Chair",
      imageRef:AscendRestLightCampChairPic,
      itemLink:"../Camping/CampChairs/AscendRestLightCampChair",
      itemBoldText:"Ascend Rest/Light Camp Chair:",
      itemText:"The Ascend Rest/Light Camp Chair is a lightweight, portable chair designed for outdoor enthusiasts. It is ideal for camping, hiking, fishing, and other outdoor activities where comfort and portability are essential. The chair features a durable frame, a comfortable seating area, and is easy to set up and pack away. Its compact design ensures that it can be conveniently carried in a backpack or with other camping gear."
    },
	    {
      manuLink:"https://gcioutdoor.com/collections/rockers/products/stowaway-rocker",
      imageAlt:"GCI Outdoor Stowaway Rocker Camp Chair",
      imageRef:GCIOutdoorStowawayRockerCampChairPic,
      itemLink:"../Camping/CampChairs/GCIOutdoorStowawayRockerCampChair",
      itemBoldText:"GCI Outdoor Stowaway Rocker Camp Chair:",
      itemText:"The GCI Outdoor Stowaway Rocker Camp Chair is a portable, foldable rocking chair designed for outdoor use. It features a patented Spring-Action Rocking Technology that allows for smooth rocking motion on various surfaces. The chair is constructed with a sturdy powder-coated steel frame and includes a mesh backrest for ventilation. It also has a built-in carry handle and folds flat for easy storage and transport. The chair is designed to provide comfort and convenience for camping, tailgating, and other outdoor activities."
    },
	    {
      manuLink:"https://oztent.com/products/kingkokoda-chair",
      imageAlt:"OZTENT King Kokoda Lounge Camp Chair",
      imageRef:OZTENTKingKokodaLoungeCampChairPic,
      itemLink:"../Camping/CampChairs/OZTENTKingKokodaLoungeCampChair",
      itemBoldText:"OZTENT King Kokoda Lounge Camp Chair:",
      itemText:"The OZTENT King Kokoda Lounge Camp Chair is a premium camping chair designed for comfort and durability. It features a fully padded seat and backrest, adjustable lumbar support, and a high weight capacity. The chair is built with a strong steel frame and includes convenient features such as a side table with a cup holder and storage pockets. It is designed to provide a comfortable and relaxing seating experience for outdoor enthusiasts."
    },
	
    ];

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1">
    <h1>Camp Chairs</h1>
    Very few things are as peaceful as sitting around a campfire in your favorite chair with a group of friends. Here are some camp chairs that can help add to that experience.

    </Typography>
    <br/>

    {items.map((element)=> (
      <div>
    <Paper elevation={3}
      sx={{
        p: 2,
        margin: 'auto',
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
    <Grid container spacing={12} rowSpacing={1}>
    <Grid item>
    <Link href={element.menuLink} target="_blank">
      <ButtonBase sx={{ width: 128, height: 128 }}>
      <img alt={element.imageAlt} height="160px" className='GridImage' src={element.imageRef}/>
      </ButtonBase>
      </Link>
    </Grid>
    <Grid item xs={12} sm container>
      <Grid item xs container direction="column" spacing={9}>
        <Grid item xs>
          <Typography gutterBottom variant="subtitle1" component="div">
          <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
          
          </Typography>
          <Typography align="center">
            <Link href={element.manuLink} target="_blank">
          <Button variant="contained">More Product Info</Button>
          </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  </Paper>
  <br/>
  </div>
  ))}

    </div>
    </>
  );
}

export default CampChairs;

