import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import OutdoorEdgeSwingBlazeSwingBladeDropPointSkinnerBigGameGuttingKnifePic from '../images/OutdoorEdgeSwingBlazeSwingBladeDropPointSkinnerBigGameGuttingKnife.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function OutdoorEdgeSwingBlazeSwingBladeDropPointSkinnerBigGameGuttingKnife () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Outdoor Edge SwingBlaze SwingBlade Drop Point Skinner/Big Game Gutting Knife</h1>

            <img alt="Outdoor Edge SwingBlaze SwingBlade Drop Point Skinner/Big Game Gutting Knife" height="130px" src={OutdoorEdgeSwingBlazeSwingBladeDropPointSkinnerBigGameGuttingKnifePic}/>

    <Typography align="left" variant="body1">
        The Outdoor Edge SwingBlaze SwingBlade is a versatile hunting knife designed for big game hunters. It features a unique dual-blade design: a drop-point skinner blade and a gutting blade. The blades can be easily switched with the push of a button, making it a convenient tool for field dressing. The knife is made from high-quality stainless steel and comes with a rubberized handle for a secure grip. It also includes a nylon sheath for safe storage and transport.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Dual-Blade Design:</b> The SwingBlaze features both a drop-point skinner blade and a gutting blade, providing versatility in the field.</li>
<li className="ListItemStyle"><b>Easy Blade Switching:</b> The push-button mechanism allows for quick and easy switching between blades.</li>
<li className="ListItemStyle"><b>High-Quality Material:</b> Made from high-quality stainless steel, the blades are durable and maintain their sharpness.</li>
<li className="ListItemStyle"><b>Ergonomic Handle:</b> The rubberized handle offers a secure and comfortable grip, even in wet conditions.</li>
<li className="ListItemStyle"><b>Safety Features:</b> The knife includes a locking mechanism to ensure the blades stay securely in place during use.</li>
<li className="ListItemStyle"><b>Nylon Sheath:</b> Comes with a durable nylon sheath for safe storage and easy transport.</li>
<li className="ListItemStyle"><b>Corrosion-Resistant:</b> The stainless steel construction is resistant to rust and corrosion, increasing the knife's longevity.</li>
<li className="ListItemStyle"><b>Lightweight:</b> Despite its dual-blade design, the knife is relatively lightweight, making it easy to carry.</li>
<li className="ListItemStyle"><b>Easy to Clean:</b> The design allows for easy cleaning and maintenance, which is crucial for hygiene.</li>
<li className="ListItemStyle"><b>Reputation:</b> Outdoor Edge is a well-known brand in the hunting community, known for producing reliable and high-quality knives.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Price:</b> The SwingBlaze can be more expensive compared to other single-blade hunting knives.</li>
<li className="ListItemStyle"><b>Complex Mechanism:</b> The push-button mechanism, while convenient, adds complexity and potential points of failure.</li>
<li className="ListItemStyle"><b>Blade Length:</b> Some users might find the blade length insufficient for larger game.</li>
<li className="ListItemStyle"><b>Weight:</b> Although relatively lightweight, it is heavier than some single-blade knives.</li>
<li className="ListItemStyle"><b>Maintenance:</b> The dual-blade system requires more maintenance to keep both blades in optimal condition.</li>
<li className="ListItemStyle"><b>Sheath Quality:</b> Some users have reported that the nylon sheath is not as durable as they would like.</li>
<li className="ListItemStyle"><b>Handle Material:</b> While rubberized for grip, the handle material may wear down over time with heavy use.</li>
<li className="ListItemStyle"><b>Blade Sharpness:</b> Out of the box, the blades may require additional sharpening for optimal performance.</li>
<li className="ListItemStyle"><b>Switching Mechanism:</b> The button mechanism can be difficult to operate with gloves on.</li>
<li className="ListItemStyle"><b>Bulkiness:</b> The dual-blade design makes the knife bulkier than some hunters prefer for their field gear.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default OutdoorEdgeSwingBlazeSwingBladeDropPointSkinnerBigGameGuttingKnife;

