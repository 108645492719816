import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CabelasOutfitterG532MPBlackIRTrailCameraComboPic from '../images/CabelasOutfitterG532MPBlackIRTrailCameraCombo.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';


function CabelasOutfitterG532MPBlackIRTrailCameraCombo () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Cabela's Outfitter G5 32MP Black IR Trail Camera Combo</h1>

            <img alt="Cabela's Outfitter G5 32MP Black IR Trail Camera Combo" height="130px" src={CabelasOutfitterG532MPBlackIRTrailCameraComboPic}/>

    <Typography align="left" variant="body1">
        The Cabela's Outfitter G5 32MP Black IR Trail Camera Combo is a high-performance trail camera designed for outdoor enthusiasts and wildlife observers. It features a 32-megapixel camera for capturing high-resolution images and videos. The Black IR (infrared) technology allows for discreet nighttime photography without startling wildlife. This combo package typically includes additional accessories such as batteries and memory cards, making it a convenient choice for those looking to set up their trail camera quickly.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>High Resolution:</b> The 32MP camera provides clear and detailed images, which is excellent for wildlife monitoring.</li>
<li className="ListItemStyle"><b>Black IR Technology:</b> This feature allows for stealthy nighttime photography without emitting visible light, reducing the chance of spooking animals.</li>
<li className="ListItemStyle"><b>Fast Trigger Speed:</b> Quick trigger speed ensures that fast-moving animals are captured without missing the shot.</li>
<li className="ListItemStyle"><b>Durable Design:</b> Built to withstand harsh outdoor conditions, making it suitable for long-term use in various environments.</li>
<li className="ListItemStyle"><b>Combo Package:</b> Often includes essential accessories like batteries and memory cards, offering convenience and value.</li>
<li className="ListItemStyle"><b>Long Battery Life:</b> Efficient power usage allows for extended periods of operation without frequent battery changes.</li>
<li className="ListItemStyle"><b>Easy Setup:</b> User-friendly interface and setup process make it accessible even for beginners.</li>
<li className="ListItemStyle"><b>Wide Detection Range:</b> Capable of detecting motion over a large area, ensuring comprehensive coverage.</li>
<li className="ListItemStyle"><b>Time-Lapse Mode:</b> Offers the ability to capture time-lapse sequences, which can be useful for observing changes over time.</li>
<li className="ListItemStyle"><b>Weatherproof:</b> Designed to be resistant to water and dust, ensuring reliable performance in various weather conditions.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Price:</b> The high-quality features and combo package can make it more expensive compared to basic models.</li>
<li className="ListItemStyle"><b>Size and Weight:</b> May be bulkier than some other trail cameras, which could be a consideration for portability.</li>
<li className="ListItemStyle"><b>Learning Curve:</b> While setup is generally easy, mastering all features may take some time for new users.</li>
<li className="ListItemStyle"><b>Limited Night Range:</b> Although the Black IR is discreet, its range might be shorter compared to other lighting technologies.</li>
<li className="ListItemStyle"><b>Memory Card Limitations:</b> Some users may find the maximum supported memory card size limiting for extended use.</li>
<li className="ListItemStyle"><b>Battery Dependency:</b> Requires regular battery changes, especially if not using high-capacity batteries.</li>
<li className="ListItemStyle"><b>No Wireless Connectivity:</b> Lacks features like Wi-Fi or Bluetooth, which can be found in more advanced models for remote access.</li>
<li className="ListItemStyle"><b>Video Quality:</b> While image quality is high, video resolution may not meet the expectations of all users.</li>
<li className="ListItemStyle"><b>Trigger Sensitivity:</b> In some cases, the sensor might trigger on non-animal movements, like branches or leaves.</li>
<li className="ListItemStyle"><b>Customer Support:</b> Some users have reported mixed experiences with customer service and support.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default CabelasOutfitterG532MPBlackIRTrailCameraCombo;

