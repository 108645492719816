import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import GarminETrexSEHandheldGPSNavigationUnitPic from '../images/GarminETrexSEHandheldGPSNavigationUnit.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function GarminETrexSEHandheldGPSNavigationUnit () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Garmin ETrex SE Handheld GPS Navigation Unit</h1>

            <img alt="Garmin ETrex SE Handheld GPS Navigation Unit" height="130px" src={GarminETrexSEHandheldGPSNavigationUnitPic}/>

    <Typography align="left" variant="body1">
        The Garmin eTrex SE is a compact, rugged, and reliable handheld GPS navigation unit designed for outdoor enthusiasts. It features a high-sensitivity GPS receiver, a durable design, and a user-friendly interface. The device is suitable for activities such as hiking, geocaching, and other outdoor adventures. It offers essential navigation features, including waypoint marking, route tracking, and a built-in compass. The eTrex SE is known for its long battery life and ability to perform well in challenging environments.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Durability:</b> The eTrex SE is built to withstand harsh outdoor conditions, including water, dust, and shock.</li>
<li className="ListItemStyle"><b>High-Sensitivity GPS Receiver:</b> Provides accurate location tracking even in challenging environments like dense forests or deep canyons.</li>
<li className="ListItemStyle"><b>Long Battery Life:</b> Can last up to 25 hours on a pair of AA batteries, making it ideal for extended outdoor trips.</li>
<li className="ListItemStyle"><b>User-Friendly Interface:</b> Simple and intuitive menu system that is easy to navigate.</li>
<li className="ListItemStyle"><b>Compact and Lightweight:</b> Easy to carry and handle, making it convenient for long hikes and outdoor activities.</li>
<li className="ListItemStyle"><b>Waypoint Marking and Route Tracking:</b> Allows users to mark important locations and track their routes for easy navigation.</li>
<li className="ListItemStyle"><b>Built-In Compass:</b> Helps users maintain their bearings even when standing still.</li>
<li className="ListItemStyle"><b>Affordable:</b> Offers a good balance of features and performance at a reasonable price point.</li>
<li className="ListItemStyle"><b>Customizable Data Fields:</b> Users can customize the display to show the information most relevant to their needs.</li>
<li className="ListItemStyle"><b>Geocaching-Friendly:</b> Supports paperless geocaching, allowing users to download and view geocache details directly on the device.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Limited Mapping Capabilities:</b> The eTrex SE does not support detailed topographic maps, which may be a drawback for some users.</li>
<li className="ListItemStyle"><b>Small Screen Size:</b> The display is relatively small, which can make it difficult to view detailed information at a glance.</li>
<li className="ListItemStyle"><b>No Touchscreen:</b> Navigation is done via buttons, which can be less intuitive compared to touchscreen devices.</li>
<li className="ListItemStyle"><b>Basic Display Resolution:</b> The screen resolution is lower compared to more advanced GPS units, affecting the clarity of the display.</li>
<li className="ListItemStyle"><b>Limited Internal Memory:</b> The device has limited storage capacity for maps and waypoints.</li>
<li className="ListItemStyle"><b>No Camera:</b> Unlike some higher-end models, the eTrex SE does not include a built-in camera for taking photos.</li>
<li className="ListItemStyle"><b>No Wireless Connectivity:</b> Lacks Bluetooth or Wi-Fi, which means no wireless data transfer or connectivity with other devices.</li>
<li className="ListItemStyle"><b>Basic Compass:</b> The built-in compass is not as advanced as those found in higher-end models, lacking features like tilt compensation.</li>
<li className="ListItemStyle"><b>No Barometric Altimeter:</b> Does not include a barometric altimeter for more accurate elevation readings.</li>
<li className="ListItemStyle"><b>Limited Customization:</b> While it offers some customization, it is not as extensive as what is available on more advanced GPS units.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GarminETrexSEHandheldGPSNavigationUnit;

