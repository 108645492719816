import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ZOLEOSatelliteCommunicatorPic from '../images/ZOLEOSatelliteCommunicator.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function ZOLEOSatelliteCommunicator () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>ZOLEO Satellite Communicator</h1>

            <img alt="ZOLEO Satellite Communicator" height="130px" src={ZOLEOSatelliteCommunicatorPic}/>

    <Typography align="left" variant="body1">
        The ZOLEO Satellite Communicator is a compact, rugged device designed to keep you connected when you're off the grid. It pairs with your smartphone to provide seamless global messaging via satellite, cellular, and Wi-Fi networks. The device is particularly useful for outdoor enthusiasts, remote workers, and anyone who needs reliable communication in areas without cellular coverage. It also offers SOS alerting and weather forecasting features, making it a versatile tool for safety and convenience.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Global Coverage:</b> Provides seamless messaging via satellite, cellular, and Wi-Fi networks, ensuring you stay connected almost anywhere in the world.</li>
<li className="ListItemStyle"><b>SOS Functionality:</b> Includes an SOS button that connects you to a 24/7 emergency response center, offering peace of mind in critical situations.</li>
<li className="ListItemStyle"><b>Weather Forecasts:</b> Delivers weather updates, which are crucial for planning outdoor activities.</li>
<li className="ListItemStyle"><b>Rugged Design:</b> Built to withstand harsh environments, with an IP68 rating for water and dust resistance.</li>
<li className="ListItemStyle"><b>Long Battery Life:</b> Offers up to 200+ hours of battery life in standby mode, reducing the need for frequent recharging.</li>
<li className="ListItemStyle"><b>Two-Way Messaging:</b> Allows for two-way text messaging, so you can both send and receive messages.</li>
<li className="ListItemStyle"><b>Location Sharing:</b> Enables you to share your GPS location with friends and family, enhancing safety.</li>
<li className="ListItemStyle"><b>Affordable Plans:</b> Offers various subscription plans to fit different needs and budgets.</li>
<li className="ListItemStyle"><b>User-Friendly App:</b> The companion app is intuitive and easy to use, making it accessible even for non-tech-savvy users.</li>
<li className="ListItemStyle"><b>Compact and Lightweight:</b> Easy to carry, making it ideal for backpackers and travelers.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Subscription Required:</b> Requires a subscription plan for full functionality, which can be an ongoing cost.</li>
<li className="ListItemStyle"><b>Initial Cost:</b> The device itself can be pricey, which might be a barrier for some users.</li>
<li className="ListItemStyle"><b>Limited Messaging:</b> Text messages are limited to 160 characters, which can be restrictive.</li>
<li className="ListItemStyle"><b>No Voice Communication:</b> Does not support voice calls, which might be a drawback for some users.</li>
<li className="ListItemStyle"><b>Dependent on Smartphone:</b> Requires a smartphone for most functionalities, limiting its use if your phone battery dies.</li>
<li className="ListItemStyle"><b>Slow Satellite Connection:</b> Satellite messaging can be slower compared to cellular or Wi-Fi, leading to delays.</li>
<li className="ListItemStyle"><b>No Web Browsing:</b> Does not support web browsing, limiting its utility for some users.</li>
<li className="ListItemStyle"><b>Limited App Compatibility:</b> Only compatible with the ZOLEO app, restricting integration with other apps.</li>
<li className="ListItemStyle"><b>Size:</b> While compact, it may still be considered bulky compared to some other communication devices.</li>
<li className="ListItemStyle"><b>Learning Curve:</b> Some users may find the initial setup and learning process challenging.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default ZOLEOSatelliteCommunicator;

