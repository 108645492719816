import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CampChefProSeriesDeluxe3BurnerCampStovePic from '../images/CampChefProSeriesDeluxe3BurnerCampStove.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function CampChefProSeriesDeluxe3BurnerCampStove () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Camp Chef Pro Series Deluxe 3-Burner Camp Stove</h1>

            <img alt="Camp Chef Pro Series Deluxe 3-Burner Camp Stove" height="130px" src={CampChefProSeriesDeluxe3BurnerCampStovePic}/>

    <Typography align="left" variant="body1">
        The Camp Chef Pro Series Deluxe 3-Burner Camp Stove is a versatile outdoor cooking appliance designed for camping, tailgating, and other outdoor activities. It features three powerful burners, a large cooking surface, and a durable construction. The stove is known for its high heat output, ease of use, and portability, making it a popular choice among outdoor enthusiasts.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>High Heat Output:</b> Each burner delivers up to 30,000 BTUs, providing ample heat for cooking a variety of meals quickly and efficiently.</li>
<li className="ListItemStyle"><b>Large Cooking Surface:</b> The spacious cooking area allows you to prepare multiple dishes simultaneously, making it ideal for group cooking.</li>
<li className="ListItemStyle"><b>Durable Construction:</b> Made with high-quality materials, the stove is built to withstand harsh outdoor conditions.</li>
<li className="ListItemStyle"><b>Portability:</b> Despite its size, the stove is relatively easy to transport, thanks to its foldable legs and manageable weight.</li>
<li className="ListItemStyle"><b>Versatility:</b> Suitable for a wide range of cooking methods, including grilling, boiling, and frying.</li>
<li className="ListItemStyle"><b>Adjustable Heat Control:</b> Each burner has its own control knob, allowing for precise temperature adjustments.</li>
<li className="ListItemStyle"><b>Wind Protection:</b> The stove comes with a three-sided windscreen, which helps maintain consistent heat even in windy conditions.</li>
<li className="ListItemStyle"><b>Easy Setup:</b> The stove is designed for quick and straightforward assembly, so you can start cooking in no time.</li>
<li className="ListItemStyle"><b>Compatibility:</b> Works with a variety of Camp Chef accessories, such as griddles and BBQ boxes, enhancing its functionality.</li>
<li className="ListItemStyle"><b>Stable Design:</b> The sturdy legs and robust construction provide a stable cooking platform, reducing the risk of tipping.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Weight:</b> At around 50 pounds, the stove can be heavy to carry for some users, especially over long distances.</li>
<li className="ListItemStyle"><b>Fuel Consumption:</b> High heat output means it can consume a lot of propane, which may require frequent refueling on extended trips.</li>
<li className="ListItemStyle"><b>Price:</b> It is relatively expensive compared to other camp stoves, which might be a deterrent for budget-conscious buyers.</li>
<li className="ListItemStyle"><b>Size:</b> The large cooking surface, while beneficial, also means it takes up more space in your vehicle and campsite.</li>
<li className="ListItemStyle"><b>Assembly Required:</b> While setup is generally easy, some users may find the initial assembly process cumbersome.</li>
<li className="ListItemStyle"><b>Maintenance:</b> Keeping the burners and cooking surface clean can be challenging, especially after cooking greasy foods.</li>
<li className="ListItemStyle"><b>No Built-in Ignition:</b> Some models do not come with a built-in ignition system, requiring you to use a match or lighter.</li>
<li className="ListItemStyle"><b>Limited Portability Accessories:</b> The stove does not come with a carrying case, which could make transportation more difficult.</li>
<li className="ListItemStyle"><b>Propane Tank Not Included:</b> You need to purchase a propane tank separately, adding to the overall cost.</li>
<li className="ListItemStyle"><b>Noise Level:</b> The burners can be quite loud when operating at full capacity, which might be bothersome in a quiet camping environment.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default CampChefProSeriesDeluxe3BurnerCampStove;

