import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import NOMADGrillsFireStartersPic from '../images/NOMADGrillsFireStarters.png';
import { useState, useEffect} from "react";

function NOMADGrillsFireStarters () {
  const [mobileOpen, setMobileOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{ marginLeft:"20px", marginTop: "150px" , marginRight:"20px"}}>
    <table>
        <tr>
          <td>
          <h1>NOMAD Grills Fire Starters</h1>
          </td>
          <td>
            <img alt="NOMAD Grills Fire Starters" width="50%" height="50%" src={NOMADGrillsFireStartersPic}/>
          </td>
        </tr>
      </table>
    <Typography align="left" variant="body1">
        NOMAD Grills Fire Starters are designed to provide a quick, easy, and reliable way to ignite charcoal or wood for grilling and campfires. These fire starters are typically made from natural materials, ensuring they are safe and environmentally friendly. They are compact, lightweight, and easy to use, making them ideal for outdoor enthusiasts, campers, and grill masters who need a dependable fire-starting solution.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Ease of Use:</b> Simple to ignite, making the fire-starting process quick and hassle-free.</li>
<li className="ListItemStyle"><b>Natural Materials:</b> Often made from eco-friendly materials, reducing environmental impact.</li>
<li className="ListItemStyle"><b>Compact and Lightweight:</b> Easy to carry and store, perfect for camping and outdoor activities.</li>
<li className="ListItemStyle"><b>Reliable Ignition:</b> Consistently lights up charcoal or wood, even in challenging conditions.</li>
<li className="ListItemStyle"><b>Odorless:</b> Burns cleanly without producing unpleasant odors that could affect food flavor.</li>
<li className="ListItemStyle"><b>Water-Resistant:</b> Some versions are designed to work even when damp, ensuring reliability in various weather conditions.</li>
<li className="ListItemStyle"><b>Long Burn Time:</b> Provides a sustained flame to ensure the fire catches properly.</li>
<li className="ListItemStyle"><b>No Chemicals:</b> Free from harmful chemicals, making it safe for cooking and the environment.</li>
<li className="ListItemStyle"><b>Versatile:</b> Suitable for use with grills, fire pits, and campfires.</li>
<li className="ListItemStyle"><b>Cost-Effective:</b> Generally affordable, offering good value for the convenience they provide.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Limited Quantity:</b> Packs may contain a limited number of fire starters, requiring frequent repurchase.</li>
<li className="ListItemStyle"><b>Burn Time:</b> While sufficient for most needs, the burn time may not be long enough for larger fires.</li>
<li className="ListItemStyle"><b>Storage Conditions:</b> May require specific storage conditions to maintain effectiveness, such as keeping them dry.</li>
<li className="ListItemStyle"><b>Initial Ignition:</b> Some users might find it slightly challenging to ignite in very windy conditions.</li>
<li className="ListItemStyle"><b>Price Variation:</b> Prices can vary widely depending on the retailer, potentially leading to higher costs.</li>
<li className="ListItemStyle"><b>Availability:</b> May not be available in all regions or stores, necessitating online purchases.</li>
<li className="ListItemStyle"><b>Packaging:</b> Some users might find the packaging not durable enough for repeated outdoor use.</li>
<li className="ListItemStyle"><b>Single Use:</b> Designed for one-time use, which might not be ideal for extended trips without resupply.</li>
<li className="ListItemStyle"><b>Size:</b> Small size might be inconvenient for those with larger hands or when wearing gloves.</li>
<li className="ListItemStyle"><b>Performance Variability:</b> Performance might vary slightly depending on the type of wood or charcoal used.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default NOMADGrillsFireStarters;

