import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import OutdoorEdgeButcherMax11PieceGameProcessingSetPic from '../images/OutdoorEdgeButcherMax11PieceGameProcessingSet.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function OutdoorEdgeButcherMax11PieceGameProcessingSet () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Outdoor Edge Butcher Max 11-Piece Game Processing Set</h1>

            <img alt="Outdoor Edge Butcher Max 11-Piece Game Processing Set" height="130px" src={OutdoorEdgeButcherMax11PieceGameProcessingSetPic}/>

    <Typography align="left" variant="body1">
        The Outdoor Edge Butcher Max 11-Piece Game Processing Set is a comprehensive kit designed for hunters and outdoor enthusiasts who need to process game in the field or at home. The set includes a variety of tools to handle every step of the butchering process, from skinning and gutting to deboning and slicing. The tools are made from high-quality materials and come in a convenient carrying case for easy transport and organization.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Comprehensive Set:</b> Includes 11 pieces, covering all necessary tools for game processing.</li>
<li className="ListItemStyle"><b>High-Quality Materials:</b> Blades are made from stainless steel, ensuring durability and sharpness.</li>
<li className="ListItemStyle"><b>Ergonomic Design:</b> Handles are designed for comfort and reduce hand fatigue during extended use.</li>
<li className="ListItemStyle"><b>Versatility:</b> Suitable for a wide range of game, from small to large animals.</li>
<li className="ListItemStyle"><b>Portable:</b> Comes with a hard-sided carrying case for easy transport and organization.</li>
<li className="ListItemStyle"><b>Safety Features:</b> Includes a sharpener to maintain blade edges and ensure safe cutting.</li>
<li className="ListItemStyle"><b>Non-Slip Handles:</b> Handles are textured to provide a secure grip, even when wet.</li>
<li className="ListItemStyle"><b>Easy to Clean:</b> Tools are designed for easy cleaning, which is crucial for hygiene.</li>
<li className="ListItemStyle"><b>Cost-Effective:</b> Offers good value for money given the number of tools included.</li>
<li className="ListItemStyle"><b>User-Friendly:</b> Suitable for both beginners and experienced hunters.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Weight:</b> The set can be relatively heavy to carry, especially on long hunting trips.</li>
<li className="ListItemStyle"><b>Case Durability:</b> The carrying case may not be as durable as the tools themselves.</li>
<li className="ListItemStyle"><b>Blade Sharpness:</b> Some users report that the blades require frequent sharpening.</li>
<li className="ListItemStyle"><b>Handle Material:</b> Handles are made from plastic, which may not be as durable as other materials.</li>
<li className="ListItemStyle"><b>Tool Redundancy:</b> Some users may find that they do not use all the tools included in the set.</li>
<li className="ListItemStyle"><b>Price:</b> While cost-effective, it may still be expensive for casual hunters.</li>
<li className="ListItemStyle"><b>Size:</b> The set can be bulky, taking up significant space in your gear.</li>
<li className="ListItemStyle"><b>No Bone Saw:</b> Some users might miss having a bone saw included in the set.</li>
<li className="ListItemStyle"><b>Learning Curve:</b> Beginners may need time to learn how to use all the tools effectively.</li>
<li className="ListItemStyle"><b>Maintenance:</b> Requires regular maintenance to keep tools in optimal condition.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default OutdoorEdgeButcherMax11PieceGameProcessingSet;

