import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FastFireFireStartersPic from '../images/FastFireFireStarters.png';
import { useState, useEffect} from "react";

function FastFireFireStarters () {
  const [mobileOpen, setMobileOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{ marginLeft:"20px", marginTop: "150px" , marginRight:"20px"}}>
    <table>
        <tr>
          <td>
          <h1>Fast Fire Fire Starters</h1>
          </td>
          <td>
            <img alt="Fast Fire Fire Starters" width="50%" height="50%" src={FastFireFireStartersPic}/>
          </td>
        </tr>
      </table>
    <Typography align="left" variant="body1">
        Fast Fire Fire Starters are designed to make starting a fire quick and easy, whether you're camping, grilling, or lighting a fireplace. These fire starters are typically made from a combination of natural and synthetic materials that ignite easily and burn for a sufficient amount of time to get your fire going. They are often marketed as waterproof, lightweight, and convenient to carry, making them a popular choice for outdoor enthusiasts and homeowners alike.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Ease of Use:</b> Fast Fire Fire Starters are simple to use, requiring no special skills or tools to ignite.</li>
<li className="ListItemStyle"><b>Quick Ignition:</b> They ignite quickly, saving time and effort compared to traditional methods like kindling and matches.</li>
<li className="ListItemStyle"><b>Long Burn Time:</b> Many users report that these fire starters burn long enough to get larger logs and coals ignited.</li>
<li className="ListItemStyle"><b>Waterproof:</b> They are often waterproof, making them reliable in wet conditions.</li>
<li className="ListItemStyle"><b>Lightweight:</b> Easy to carry, they are ideal for camping, hiking, and other outdoor activities.</li>
<li className="ListItemStyle"><b>Compact Size:</b> Their small size makes them convenient to store and transport.</li>
<li className="ListItemStyle"><b>Consistent Performance:</b> They provide a consistent and reliable flame, reducing the frustration of failed attempts.</li>
<li className="ListItemStyle"><b>Multi-Purpose:</b> Suitable for use in fireplaces, wood stoves, campfires, and grills.</li>
<li className="ListItemStyle"><b>Safe to Use:</b> Generally considered safe, with less risk of flare-ups compared to liquid fire starters.</li>
<li className="ListItemStyle"><b>Eco-Friendly Options:</b> Some versions are made from natural, biodegradable materials, reducing environmental impact.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Cost:</b> They can be more expensive than traditional fire-starting methods like newspaper or kindling.</li>
<li className="ListItemStyle"><b>Chemical Smell:</b> Some users report a chemical odor when first ignited.</li>
<li className="ListItemStyle"><b>Residue:</b> They may leave behind residue or ash that needs to be cleaned up.</li>
<li className="ListItemStyle"><b>Limited Burn Time:</b> While sufficient for starting a fire, the burn time may not be long enough for larger or damp wood.</li>
<li className="ListItemStyle"><b>Single-Use:</b> They are typically single-use products, which can add up in cost over time.</li>
<li className="ListItemStyle"><b>Packaging Waste:</b> The packaging can contribute to waste, especially if individually wrapped.</li>
<li className="ListItemStyle"><b>Availability:</b> May not be readily available in all stores, requiring online purchase.</li>
<li className="ListItemStyle"><b>Storage:</b> Need to be stored in a dry place to maintain effectiveness, despite being waterproof.</li>
<li className="ListItemStyle"><b>Environmental Impact:</b> Not all versions are eco-friendly, and some may contain synthetic materials.</li>
<li className="ListItemStyle"><b>Potential for Misuse:</b> If not used according to instructions, there is a risk of improper ignition or fire hazards.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default FastFireFireStarters;

