import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import StansportGrizzly10CanvasSleepingBagPic from '../images/StansportGrizzly10CanvasSleepingBag.png';
import { useState, useEffect} from "react";

function StansportGrizzly10CanvasSleepingBag () {
  const [mobileOpen, setMobileOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{ marginLeft:"20px", marginTop: "150px" , marginRight:"20px"}}>
    <table>
        <tr>
          <td>
          <h1>Stansport Grizzly -10° Canvas Sleeping Bag</h1>
          </td>
          <td>
            <img alt="Stansport Grizzly -10° Canvas Sleeping Bag" width="50%" height="50%" src={StansportGrizzly10CanvasSleepingBagPic}/>
          </td>
        </tr>
      </table>
    <Typography align="left" variant="body1">
        The Stansport Grizzly -10° Canvas Sleeping Bag is a heavy-duty, cold-weather sleeping bag designed for outdoor enthusiasts who need reliable warmth in extreme conditions. This sleeping bag features a rugged canvas shell, a soft flannel lining, and a generous amount of insulation to keep users warm in temperatures as low as -10°F. It is designed for durability and comfort, making it suitable for camping, hunting, and other outdoor activities in cold climates.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Warmth:</b> Rated for temperatures as low as -10°F, providing excellent insulation for cold weather.</li>
<li className="ListItemStyle"><b>Durable Materials:</b> The heavy-duty canvas shell is tough and resistant to wear and tear.</li>
<li className="ListItemStyle"><b>Comfortable Lining:</b> The soft flannel lining adds a layer of comfort and warmth.</li>
<li className="ListItemStyle"><b>Spacious:</b> Generously sized to accommodate larger individuals or those who prefer more room.</li>
<li className="ListItemStyle"><b>Heavy-Duty Zipper:</b> Equipped with a robust zipper that is less likely to snag or break.</li>
<li className="ListItemStyle"><b>Draft Tubes:</b> Features draft tubes along the zipper to prevent cold air from entering.</li>
<li className="ListItemStyle"><b>Water-Resistant:</b> The canvas shell offers some resistance to moisture, keeping the interior dry.</li>
<li className="ListItemStyle"><b>Compression Straps:</b> Comes with compression straps for easier packing and storage.</li>
<li className="ListItemStyle"><b>Hood Design:</b> Includes a hood to keep your head warm and reduce heat loss.</li>
<li className="ListItemStyle"><b>Versatility:</b> Suitable for various outdoor activities such as camping, hunting, and trekking in cold conditions.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Weight:</b> The bag is quite heavy, making it less ideal for backpacking or long hikes.</li>
<li className="ListItemStyle"><b>Bulkiness:</b> Its large size can make it cumbersome to pack and transport.</li>
<li className="ListItemStyle"><b>Price:</b> Higher cost compared to other sleeping bags, which might be a consideration for budget-conscious buyers.</li>
<li className="ListItemStyle"><b>Zipper Issues:</b> Some users report that the zipper can be difficult to operate, especially in cold conditions.</li>
<li className="ListItemStyle"><b>Non-Machine Washable:</b> Cleaning the sleeping bag can be challenging as it is not machine washable.</li>
<li className="ListItemStyle"><b>Limited Temperature Range:</b> While excellent for extreme cold, it may be too warm for milder conditions.</li>
<li className="ListItemStyle"><b>Stiffness:</b> The canvas material can be stiff and less flexible compared to other fabrics.</li>
<li className="ListItemStyle"><b>Drying Time:</b> Takes a long time to dry if it gets wet due to the thick insulation and canvas material.</li>
<li className="ListItemStyle"><b>Storage Space:</b> Requires significant storage space when not in use.</li>
<li className="ListItemStyle"><b>Noisy Material:</b> The canvas can be noisy when moving around, which might disturb light sleepers.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default StansportGrizzly10CanvasSleepingBag;

