import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ACRElectronicsBivyStickSatelliteCommunicationDevicePic from '../images/ACRElectronicsBivyStickSatelliteCommunicationDevice.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function ACRElectronicsBivyStickSatelliteCommunicationDevice () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
        <tr>
          <td>
          <h1>ACR Electronics Bivy Stick Satellite Communication Device</h1>
          </td>
          <td>
            <img alt="ACR Electronics Bivy Stick Satellite Communication Device" height="130px" src={ACRElectronicsBivyStickSatelliteCommunicationDevicePic}/>
          </td>
        </tr>
      </table>
    <Typography align="left" variant="body1">
        The ACR Electronics Bivy Stick Satellite Communication Device is a compact and portable satellite communication tool designed for outdoor enthusiasts, adventurers, and emergency responders. It allows users to stay connected in remote areas where traditional cellular networks are unavailable. The device pairs with a smartphone to provide messaging, location sharing, and SOS emergency services via satellite. It is particularly useful for hikers, campers, sailors, and anyone who ventures into areas with limited or no cellular coverage.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Global Coverage:</b> Utilizes the Iridium satellite network, providing reliable communication virtually anywhere on the planet.</li>
<li className="ListItemStyle"><b>Compact and Lightweight:</b> Easy to carry and fits comfortably in a backpack or pocket.</li>
<li className="ListItemStyle"><b>Two-Way Messaging:</b> Allows for sending and receiving text messages even in remote locations.</li>
<li className="ListItemStyle"><b>SOS Functionality:</b> Includes an emergency SOS button that connects users to a 24/7 search and rescue monitoring center.</li>
<li className="ListItemStyle"><b>Location Sharing:</b> Users can share their GPS location with friends and family for added safety.</li>
<li className="ListItemStyle"><b>Weather Updates:</b> Provides weather forecasts to help users make informed decisions during their adventures.</li>
<li className="ListItemStyle"><b>Battery Life:</b> Long-lasting battery life, suitable for extended trips.</li>
<li className="ListItemStyle"><b>Durable Design:</b> Built to withstand harsh outdoor conditions, including water and dust resistance.</li>
<li className="ListItemStyle"><b>User-Friendly App:</b> Pairs with a smartphone app that is intuitive and easy to use.</li>
<li className="ListItemStyle"><b>Subscription Flexibility:</b> Offers various subscription plans to suit different needs and budgets.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Subscription Costs:</b> Requires a subscription plan for satellite services, which can be expensive.</li>
<li className="ListItemStyle"><b>Initial Cost:</b> The device itself can be pricey compared to other communication tools.</li>
<li className="ListItemStyle"><b>Dependent on Smartphone:</b> Requires a smartphone to access most features, which could be a limitation if the phone battery dies or malfunctions.</li>
<li className="ListItemStyle"><b>Message Delays:</b> There can be delays in sending and receiving messages due to satellite communication.</li>
<li className="ListItemStyle"><b>Limited Functionality Without Subscription:</b> The device's capabilities are significantly reduced without an active subscription.</li>
<li className="ListItemStyle"><b>Data Limits:</b> Some subscription plans have data limits, which can restrict usage.</li>
<li className="ListItemStyle"><b>Learning Curve:</b> May require some time to fully understand and utilize all features effectively.</li>
<li className="ListItemStyle"><b>Signal Obstruction:</b> Performance can be affected by dense foliage, deep canyons, or heavy cloud cover.</li>
<li className="ListItemStyle"><b>No Voice Communication:</b> Limited to text messaging and does not support voice calls.</li>
<li className="ListItemStyle"><b>App Dependency:</b> The need to use a specific app may not appeal to all users, especially those who prefer standalone devices.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default ACRElectronicsBivyStickSatelliteCommunicationDevice;

