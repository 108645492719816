import * as React from 'react';

//import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import '../components/jmo.css';
import '../components/campingmenu';
import { NavLink } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

function ProductMenu() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      <Accordion expanded={expanded === 'panel1'} 
        onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>Camping</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <List sx={{ marginTop: '-30px !important', marginBottom: '-20px !important' }}>
            <ListItem key="CampingStoves" disablePadding>
                    <ListItemButton color="#002E62" LinkComponent={NavLink} to="/Camping/CampingStoves">
                    <ListItemText color="#002E62" primary="Camping Stoves"/>
                    </ListItemButton>
            </ListItem>
            <ListItem key="CampChairs" disablePadding>
                <ListItemButton color="#002E62" LinkComponent={NavLink} to="/Camping/CampChairs">
                <ListItemText color="#002E62" primary="Camp Chairs"/>
                </ListItemButton>
            </ListItem>
            <ListItem key="FireStarters" disablePadding>
                <ListItemButton color="#002E62" LinkComponent={NavLink} to="/Camping/FireStarters">
                <ListItemText color="#002E62" primary="Fire Starters"/>
                </ListItemButton>
            </ListItem>
            <ListItem key="SleepingBags" disablePadding >
                <ListItemButton color="#002E62" LinkComponent={NavLink} to="/Camping/SleepingBags">
                <ListItemText color="#002E62" primary="Sleeping Bags"/>
                </ListItemButton>
            </ListItem>


        </List>
        </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} 
        onChange={handleChange('panel2')}>

        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>Electronics</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <List sx={{ marginTop: '-30px !important', marginBottom: '-20px !important' }}>
        <ListItem key="Handheld GPS" disablePadding>
          <ListItemButton color="#002E62" LinkComponent={NavLink} to="/Electronics/HandheldGPS">
            <ListItemText color="#002E62" primary="Handheld GPS"/>
          </ListItemButton>
        </ListItem>
        <ListItem key="SatelliteCommunicators" disablePadding>
          <ListItemButton color="#002E62" LinkComponent={NavLink} to="/Electronics/SatelliteCommunicators">
            <ListItemText color="#002E62" primary="Satellite Communicators"/>
          </ListItemButton>
        </ListItem>
        <ListItem key="2WayRadios" disablePadding>
          <ListItemButton color="#002E62" LinkComponent={NavLink} to="/Electronics/2WayRadios">
            <ListItemText color="#002E62" primary="2-Way Radios"/>
          </ListItemButton>
        </ListItem>
        </List>
        <List>

        </List>
        </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel3'} 
        onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography><b>Outdoor Gear</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <List sx={{ marginTop: '-30px !important', marginBottom: '-20px !important' }}>

          <ListItem key="Hunting Knives" disablePadding>
            <ListItemButton color="#002E62" LinkComponent={NavLink} to="/OutdoorGear/HuntingKnives">
              <ListItemText color="#002E62" primary="Hunting Knives"/>
            </ListItemButton>
          </ListItem>
          <ListItem key="Hunting Packs" disablePadding>
            <ListItemButton color="#002E62" LinkComponent={NavLink} to="/OutdoorGear/HuntingPacks">
              <ListItemText color="#002E62" primary="Hunting Packs"/>
            </ListItemButton>
          </ListItem>
          <ListItem key="Trail Cams" disablePadding>
            <ListItemButton color="#002E62" LinkComponent={NavLink} to="/OutdoorGear/TrailCams">
              <ListItemText color="#002E62" primary="Trail Cams"/>
            </ListItemButton>
          </ListItem>
        </List>
        <List>

        </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default ProductMenu;
