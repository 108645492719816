import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Cobra30MileWalkieTalkie2WayRadio2PackSetPic from '../images/Cobra30MileWalkieTalkie2WayRadio2PackSet.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function Cobra30MileWalkieTalkie2WayRadio2PackSet () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Cobra 30-Mile Walkie Talkie 2-Way Radio 2-Pack Set</h1>

            <img alt="Cobra 30-Mile Walkie Talkie 2-Way Radio 2-Pack Set" height="130px" src={Cobra30MileWalkieTalkie2WayRadio2PackSetPic}/>

    <Typography align="left" variant="body1">
        The Cobra 30-Mile Walkie Talkie 2-Way Radio 2-Pack Set is designed for outdoor enthusiasts, adventurers, and anyone needing reliable communication over long distances. These radios offer a range of up to 30 miles under optimal conditions and come with a variety of features to enhance usability and performance. They are ideal for hiking, camping, and other outdoor activities where cell phone service may be unreliable.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Long Range:</b> Offers up to 30 miles of communication range in ideal conditions, making it suitable for various outdoor activities.</li>
<li className="ListItemStyle"><b>Weather Alerts:</b> Equipped with NOAA weather channels to keep you informed about severe weather conditions.</li>
<li className="ListItemStyle"><b>Battery Life:</b> Long-lasting battery life, with options for rechargeable batteries or standard AA batteries.</li>
<li className="ListItemStyle"><b>Privacy Codes:</b> Includes multiple privacy codes to minimize interference from other users on the same channel.</li>
<li className="ListItemStyle"><b>Hands-Free Operation:</b> VOX (Voice-Activated Transmission) feature allows for hands-free communication.</li>
<li className="ListItemStyle"><b>Durability:</b> Built to withstand rugged outdoor conditions, including water resistance.</li>
<li className="ListItemStyle"><b>Multiple Channels:</b> Offers a wide range of channels to choose from, reducing the likelihood of interference.</li>
<li className="ListItemStyle"><b>Backlit Display:</b> Easy-to-read backlit LCD display for use in low-light conditions.</li>
<li className="ListItemStyle"><b>Call Alerts:</b> Different call alert tones to distinguish between different incoming calls.</li>
<li className="ListItemStyle"><b>Compact Design:</b> Lightweight and portable, making it easy to carry during outdoor activities.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Range Limitations:</b> The 30-mile range is often only achievable under optimal conditions (e.g., open fields, no obstructions), and real-world range may be significantly less.</li>
<li className="ListItemStyle"><b>Battery Replacement:</b> Frequent use may require regular battery replacement or recharging, which can be inconvenient.</li>
<li className="ListItemStyle"><b>Interference:</b> Despite privacy codes, interference from other users can still occur, especially in crowded areas.</li>
<li className="ListItemStyle"><b>Audio Quality:</b> Audio clarity can be affected by distance and environmental factors.</li>
<li className="ListItemStyle"><b>Size:</b> While compact, some users may find the size still a bit bulky for certain activities.</li>
<li className="ListItemStyle"><b>Learning Curve:</b> The various features and settings may require a learning curve for new users.</li>
<li className="ListItemStyle"><b>Price:</b> Can be more expensive compared to other basic walkie-talkies.</li>
<li className="ListItemStyle"><b>Limited Waterproofing:</b> While water-resistant, they are not fully waterproof and may not withstand heavy rain or submersion.</li>
<li className="ListItemStyle"><b>Accessory Costs:</b> Additional accessories like headsets or extra batteries can add to the overall cost.</li>
<li className="ListItemStyle"><b>Channel Scanning:</b> Scanning through channels can be slow, making it time-consuming to find an open or clear channel.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default Cobra30MileWalkieTalkie2WayRadio2PackSet;

