import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AscendRestLightCampChairPic from '../images/AscendRestLightCampChair.png';
import { useState, useEffect} from "react";

function AscendRestLightCampChair () {
  const [mobileOpen, setMobileOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{ marginLeft:"20px", marginTop: "150px" , marginRight:"20px"}}>
    <table>
        <tr>
          <td>
          <h1>Ascend Rest/Light Camp Chair</h1>
          </td>
          <td>
            <img alt="Ascend Rest/Light Camp Chair" width="50%" height="50%" src={AscendRestLightCampChairPic}/>
          </td>
        </tr>
      </table>
    <Typography align="left" variant="body1">
        The Ascend Rest/Light Camp Chair is a lightweight, portable chair designed for outdoor enthusiasts. It is ideal for camping, hiking, fishing, and other outdoor activities where comfort and portability are essential. The chair features a durable frame, a comfortable seating area, and is easy to set up and pack away. Its compact design ensures that it can be conveniently carried in a backpack or with other camping gear.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Lightweight:</b> Easy to carry, making it ideal for hiking and backpacking trips.</li>
<li className="ListItemStyle"><b>Portable:</b> Compact design allows it to be packed away without taking up much space.</li>
<li className="ListItemStyle"><b>Durable:</b> Made with high-quality materials that can withstand outdoor conditions.</li>
<li className="ListItemStyle"><b>Comfortable:</b> Provides good support and comfort for extended sitting periods.</li>
<li className="ListItemStyle"><b>Easy Setup:</b> Quick and straightforward to assemble and disassemble.</li>
<li className="ListItemStyle"><b>Stable:</b> Sturdy frame ensures stability on various terrains.</li>
<li className="ListItemStyle"><b>Weather-Resistant:</b> Materials are resistant to weather conditions, adding to its longevity.</li>
<li className="ListItemStyle"><b>Versatile:</b> Suitable for a variety of outdoor activities, from camping to beach outings.</li>
<li className="ListItemStyle"><b>Aesthetic Design:</b> Modern and appealing design that looks good in any setting.</li>
<li className="ListItemStyle"><b>Affordable:</b> Reasonably priced compared to similar products on the market.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Weight Capacity:</b> May have a lower weight limit compared to some other camping chairs.</li>
<li className="ListItemStyle"><b>Seat Height:</b> The seat might be too low for some users, making it difficult to get in and out of.</li>
<li className="ListItemStyle"><b>Padding:</b> Limited padding might not be comfortable for everyone, especially for long periods.</li>
<li className="ListItemStyle"><b>Storage Pockets:</b> Lack of storage pockets for personal items.</li>
<li className="ListItemStyle"><b>Armrests:</b> Absence of armrests can reduce comfort for some users.</li>
<li className="ListItemStyle"><b>Back Support:</b> Might not provide adequate back support for taller individuals.</li>
<li className="ListItemStyle"><b>Ground Contact:</b> Legs may sink into soft ground, making it less stable.</li>
<li className="ListItemStyle"><b>Assembly:</b> While generally easy, some users might find the assembly process tricky at first.</li>
<li className="ListItemStyle"><b>Color Options:</b> Limited color choices may not appeal to all users.</li>
<li className="ListItemStyle"><b>Replacement Parts:</b> Difficulty in finding replacement parts if any component breaks.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default AscendRestLightCampChair;

