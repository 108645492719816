import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import StansportGlacier0MummySleepingBagPic from '../images/StansportGlacier0MummySleepingBag.png';
import { useState, useEffect} from "react";

function StansportGlacier0MummySleepingBag () {
  const [mobileOpen, setMobileOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{ marginLeft:"20px", marginTop: "150px" , marginRight:"20px"}}>
    <table>
        <tr>
          <td>
          <h1>Stansport Glacier 0° Mummy Sleeping Bag</h1>
          </td>
          <td>
            <img alt="Stansport Glacier 0° Mummy Sleeping Bag" width="50%" height="50%" src={StansportGlacier0MummySleepingBagPic}/>
          </td>
        </tr>
      </table>
    <Typography align="left" variant="body1">
        The Stansport Glacier 0° Mummy Sleeping Bag is designed for cold-weather camping, offering warmth and comfort in temperatures as low as 0°F. This sleeping bag features a mummy-style design that tapers towards the feet to minimize heat loss and includes a hood to keep your head warm. It is filled with high-quality insulation and has a durable outer shell to withstand rugged outdoor conditions. The sleeping bag also includes a draft tube along the zipper to prevent cold air from seeping in.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Warmth:</b> Rated for temperatures as low as 0°F, making it suitable for cold-weather camping.</li>
<li className="ListItemStyle"><b>Mummy Design:</b> The tapered shape and hood help retain body heat effectively.</li>
<li className="ListItemStyle"><b>Insulation:</b> High-quality insulation material provides excellent thermal efficiency.</li>
<li className="ListItemStyle"><b>Durable Outer Shell:</b> Made from robust materials that can withstand rough outdoor conditions.</li>
<li className="ListItemStyle"><b>Draft Tube:</b> Prevents cold air from entering through the zipper, enhancing warmth.</li>
<li className="ListItemStyle"><b>Lightweight:</b> Relatively light for a sleeping bag designed for such low temperatures.</li>
<li className="ListItemStyle"><b>Compression Sack:</b> Comes with a compression sack for easy packing and transport.</li>
<li className="ListItemStyle"><b>Water-Resistant:</b> The outer shell offers some resistance to moisture, keeping you dry.</li>
<li className="ListItemStyle"><b>Affordable:</b> Generally more budget-friendly compared to other high-end cold-weather sleeping bags.</li>
<li className="ListItemStyle"><b>Versatile:</b> Suitable for a variety of outdoor activities, including camping, hiking, and backpacking.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Bulkiness:</b> Despite being lightweight, it can be bulky when packed, taking up significant space.</li>
<li className="ListItemStyle"><b>Fit:</b> The mummy design may feel restrictive for some users, especially those who prefer more room to move.</li>
<li className="ListItemStyle"><b>Zipper Issues:</b> Some users report that the zipper can snag or be difficult to operate.</li>
<li className="ListItemStyle"><b>Limited Temperature Range:</b> Not suitable for warmer weather, as it can be too hot.</li>
<li className="ListItemStyle"><b>Compression Sack Quality:</b> The included compression sack may not be as durable as the sleeping bag itself.</li>
<li className="ListItemStyle"><b>Drying Time:</b> Takes a long time to dry if it gets wet, which can be inconvenient.</li>
<li className="ListItemStyle"><b>Weight:</b> While relatively light for a 0°F bag, it is still heavier than bags designed for milder conditions.</li>
<li className="ListItemStyle"><b>Odor:</b> Some users have reported a chemical smell when the bag is new.</li>
<li className="ListItemStyle"><b>Inner Lining:</b> The inner lining material may not be as soft or comfortable as some users would like.</li>
<li className="ListItemStyle"><b>Size Limitations:</b> May not be suitable for taller individuals, as it can feel cramped.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default StansportGlacier0MummySleepingBag;

