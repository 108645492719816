import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MidlandXTALKERT299VP4Outfitter2WayRadioPackPic from '../images/MidlandXTALKERT299VP4Outfitter2WayRadioPack.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function MidlandXTALKERT299VP4Outfitter2WayRadioPack () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Midland X-TALKER T299VP4 Outfitter 2-Way Radio Pack</h1>

            <img alt="Midland X-TALKER T299VP4 Outfitter 2-Way Radio Pack" height="130px" src={MidlandXTALKERT299VP4Outfitter2WayRadioPackPic}/>

    <Typography align="left" variant="body1">
        The Midland X-TALKER T299VP4 Outfitter 2-Way Radio Pack is a set of compact, durable, and easy-to-use two-way radios designed for outdoor enthusiasts, families, and professionals who need reliable communication in various environments. These radios offer a range of features, including long-range communication, weather alerts, and multiple channels, making them suitable for hiking, camping, hunting, and other outdoor activities.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Long Range Communication:</b> Offers a range of up to 26 miles in optimal conditions, making it suitable for outdoor adventures.</li>
<li className="ListItemStyle"><b>Weather Alerts:</b> Equipped with NOAA weather alert channels to keep you informed about severe weather conditions.</li>
<li className="ListItemStyle"><b>Multiple Channels:</b> Provides 22 channels and 121 privacy codes to minimize interference and ensure clear communication.</li>
<li className="ListItemStyle"><b>Durable Design:</b> Built to withstand rugged outdoor conditions, including light rain and dust.</li>
<li className="ListItemStyle"><b>Battery Life:</b> Long battery life with both rechargeable batteries and the option to use standard AA batteries.</li>
<li className="ListItemStyle"><b>Hands-Free Operation:</b> Features eVOX for hands-free communication, which is useful during activities like hiking or biking.</li>
<li className="ListItemStyle"><b>Compact Size:</b> Lightweight and portable, making it easy to carry during outdoor activities.</li>
<li className="ListItemStyle"><b>Easy to Use:</b> Simple interface with clear buttons and a backlit display for easy operation, even in low light.</li>
<li className="ListItemStyle"><b>Affordable:</b> Offers good value for money with a reasonable price point for the features provided.</li>
<li className="ListItemStyle"><b>Accessory Compatibility:</b> Compatible with a variety of Midland accessories, including headsets and microphones.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Range Limitations:</b> The advertised 26-mile range is under optimal conditions; actual range may be significantly less in urban or heavily wooded areas.</li>
<li className="ListItemStyle"><b>Audio Quality:</b> Some users report that the audio quality can be inconsistent, especially at longer ranges.</li>
<li className="ListItemStyle"><b>Build Quality:</b> While durable, the plastic construction may not feel as premium as some higher-end models.</li>
<li className="ListItemStyle"><b>Battery Life with AA Batteries:</b> Using standard AA batteries may result in shorter battery life compared to the rechargeable option.</li>
<li className="ListItemStyle"><b>Limited Advanced Features:</b> Lacks some advanced features found in more expensive models, such as GPS or digital encryption.</li>
<li className="ListItemStyle"><b>Channel Interference:</b> Despite privacy codes, interference from other radio users can still occur in crowded areas.</li>
<li className="ListItemStyle"><b>Weather Alert Sensitivity:</b> Weather alerts can sometimes be overly sensitive, leading to frequent notifications.</li>
<li className="ListItemStyle"><b>Volume Control:</b> Some users find the volume control to be less precise, making it difficult to find the perfect volume level.</li>
<li className="ListItemStyle"><b>No Waterproof Rating:</b> While resistant to light rain, it is not fully waterproof, limiting its use in heavy rain or water-based activities.</li>
<li className="ListItemStyle"><b>Limited Customization:</b> Fewer customization options compared to some other models, such as programmable buttons or advanced scanning features.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default MidlandXTALKERT299VP4Outfitter2WayRadioPack;

