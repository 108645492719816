import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import BadlandsRiseHuntingPackPic from '../images/BadlandsRiseHuntingPack.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function BadlandsRiseHuntingPack () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Badlands Rise Hunting Pack</h1>

            <img alt="Badlands Rise Hunting Pack" height="130px" src={BadlandsRiseHuntingPackPic}/>

    <Typography align="left" variant="body1">
        The Badlands Rise Hunting Pack is designed for hunters who need a reliable, durable, and comfortable backpack for their outdoor adventures. Known for its rugged construction and thoughtful features, this pack aims to provide ample storage and easy access to essential gear. It typically includes a variety of compartments, hydration compatibility, and ergonomic design elements to ensure comfort during long treks.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Durability:</b> Constructed with high-quality materials that withstand harsh outdoor conditions.</li>
<li className="ListItemStyle"><b>Comfort:</b> Ergonomic design with padded shoulder straps and a ventilated back panel.</li>
<li className="ListItemStyle"><b>Storage Capacity:</b> Multiple compartments and pockets for organized storage of hunting gear.</li>
<li className="ListItemStyle"><b>Hydration Compatibility:</b> Includes a compartment for a hydration bladder, making it easy to stay hydrated.</li>
<li className="ListItemStyle"><b>Quiet Fabric:</b> Made from noise-reducing materials to avoid startling game.</li>
<li className="ListItemStyle"><b>Versatility:</b> Suitable for various types of hunting and outdoor activities.</li>
<li className="ListItemStyle"><b>Load Distribution:</b> Features a well-designed frame and hip belt to distribute weight evenly.</li>
<li className="ListItemStyle"><b>MOLLE Webbing:</b> Allows for customization and attachment of additional gear.</li>
<li className="ListItemStyle"><b>Weather Resistance:</b> Water-resistant materials and zippers to protect contents from the elements.</li>
<li className="ListItemStyle"><b>Warranty:</b> Often comes with a robust warranty, reflecting the manufacturer's confidence in the product.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Price:</b> Can be expensive compared to other hunting packs on the market.</li>
<li className="ListItemStyle"><b>Weight:</b> Some users may find it heavier than other packs, even when empty.</li>
<li className="ListItemStyle"><b>Complexity:</b> The numerous compartments and features can be overwhelming for some users.</li>
<li className="ListItemStyle"><b>Size:</b> May be too large for hunters who prefer a more compact pack.</li>
<li className="ListItemStyle"><b>Hydration Bladder Not Included:</b> Often, the hydration bladder must be purchased separately.</li>
<li className="ListItemStyle"><b>Limited Color Options:</b> Typically available in limited camouflage patterns, which may not suit all preferences.</li>
<li className="ListItemStyle"><b>Accessibility:</b> Some pockets and compartments can be difficult to access quickly.</li>
<li className="ListItemStyle"><b>Adjustment Period:</b> May require some time to adjust and fit the pack properly.</li>
<li className="ListItemStyle"><b>Noise from Zippers:</b> Despite the quiet fabric, zippers can still make noise.</li>
<li className="ListItemStyle"><b>Bulkiness:</b> Can be cumbersome in dense brush or tight spaces.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default BadlandsRiseHuntingPack;

