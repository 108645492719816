import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CoghlanSMagnesiumFireStarterPic from '../images/CoghlanSMagnesiumFireStarter.png';
import { useState, useEffect} from "react";

function CoghlanSMagnesiumFireStarter () {
  const [mobileOpen, setMobileOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{ marginLeft:"20px", marginTop: "150px" , marginRight:"20px"}}>
    <table>
        <tr>
          <td>
          <h1>Coghlan's Magnesium Fire Starter</h1>
          </td>
          <td>
            <img alt="Coghlan's Magnesium Fire Starter" width="50%" height="50%" src={CoghlanSMagnesiumFireStarterPic}/>
          </td>
        </tr>
      </table>
    <Typography align="left" variant="body1">
        The Coghlan's Magnesium Fire Starter is a compact and reliable tool designed for outdoor enthusiasts, campers, and survivalists. It consists of a block of magnesium with a built-in flint striker. By scraping the magnesium block with a knife or another sharp object, you can create shavings that ignite easily with the sparks produced by the flint. This fire starter is designed to work in various weather conditions, making it a valuable addition to any survival kit.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Reliability:</b> Works in various weather conditions, including wet and windy environments.</li>
<li className="ListItemStyle"><b>Compact and Lightweight:</b> Easy to carry in a backpack or pocket without adding significant weight.</li>
<li className="ListItemStyle"><b>Durable:</b> Made from robust materials that can withstand rough handling.</li>
<li className="ListItemStyle"><b>Long-lasting:</b> Can be used multiple times, providing great value for money.</li>
<li className="ListItemStyle"><b>Ease of Use:</b> Simple to operate with basic instructions, suitable for beginners and experienced users alike.</li>
<li className="ListItemStyle"><b>Versatile:</b> Can be used to start fires for cooking, warmth, or signaling for help.</li>
<li className="ListItemStyle"><b>Safety:</b> Produces a controlled spark, reducing the risk of accidental fires.</li>
<li className="ListItemStyle"><b>Cost-effective:</b> Generally affordable compared to other fire-starting tools.</li>
<li className="ListItemStyle"><b>Essential Survival Tool:</b> An important addition to any emergency or survival kit.</li>
<li className="ListItemStyle"><b>No Need for Fuel:</b> Unlike lighters or matches, it doesn't require fuel, making it more reliable in long-term scenarios.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Skill Required:</b> Requires some practice to use effectively, especially for beginners.</li>
<li className="ListItemStyle"><b>Physical Effort:</b> Scraping magnesium and striking the flint can be physically demanding.</li>
<li className="ListItemStyle"><b>Messy:</b> Magnesium shavings can be messy and may require careful handling.</li>
<li className="ListItemStyle"><b>Tool Dependency:</b> Requires a knife or another sharp object to scrape the magnesium.</li>
<li className="ListItemStyle"><b>Time-consuming:</b> Can take longer to start a fire compared to matches or lighters.</li>
<li className="ListItemStyle"><b>Small Size:</b> Easy to lose due to its compact size.</li>
<li className="ListItemStyle"><b>Potential for Misuse:</b> Inexperienced users might waste magnesium shavings.</li>
<li className="ListItemStyle"><b>Weather Impact:</b> While it works in various conditions, extreme cold can make it harder to use.</li>
<li className="ListItemStyle"><b>Maintenance:</b> The flint striker can wear down over time and may need replacement.</li>
<li className="ListItemStyle"><b>Not Instant:</b> Unlike a lighter, it doesn't provide an immediate flame, requiring more preparation.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default CoghlanSMagnesiumFireStarter;

