import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CampChefDeluxeOutdoorCampOvenAndStovePic from '../images/CampChefDeluxeOutdoorCampOvenAndStove.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function CampChefDeluxeOutdoorCampOvenAndStove () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
          <h1>Camp Chef Deluxe Outdoor Camp Oven And Stove</h1>
            <img alt="Camp Chef Deluxe Outdoor Camp Oven And Stove" height="130px" src={CampChefDeluxeOutdoorCampOvenAndStovePic}/>

    <Typography align="left" variant="body1">
        The Camp Chef Deluxe Outdoor Camp Oven and Stove is a versatile cooking appliance designed for outdoor enthusiasts. It combines a dual-burner stovetop with a built-in oven, allowing users to prepare a wide range of meals while camping, tailgating, or enjoying other outdoor activities. The unit is powered by propane and features a compact, portable design that makes it easy to transport and set up in various outdoor settings.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Versatility:</b> Combines a two-burner stovetop and an oven, allowing for a wide range of cooking options.</li>
<li className="ListItemStyle"><b>Portability:</b> Compact and relatively lightweight, making it easy to transport and set up.</li>
<li className="ListItemStyle"><b>Propane-Powered:</b> Uses standard propane tanks, which are widely available and easy to use.</li>
<li className="ListItemStyle"><b>Temperature Control:</b> Features adjustable heat settings for both the stovetop and oven, providing precise cooking control.</li>
<li className="ListItemStyle"><b>Durability:</b> Built with sturdy materials that can withstand outdoor conditions.</li>
<li className="ListItemStyle"><b>Ease of Use:</b> Simple setup and operation, even for those who are not experienced with outdoor cooking equipment.</li>
<li className="ListItemStyle"><b>Oven Capacity:</b> The oven is spacious enough to accommodate a variety of dishes, including baking bread or roasting meats.</li>
<li className="ListItemStyle"><b>Wind Protection:</b> The stovetop includes wind guards to help maintain consistent flame and cooking temperatures.</li>
<li className="ListItemStyle"><b>Convenience:</b> Allows for the preparation of complex meals that would be difficult with a single-burner stove or grill.</li>
<li className="ListItemStyle"><b>Safety Features:</b> Includes safety measures such as a built-in oven thermometer and flame control knobs.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Fuel Consumption:</b> Propane can be consumed quickly, especially when using both the oven and stovetop simultaneously.</li>
<li className="ListItemStyle"><b>Weight:</b> While portable, it is heavier than single-burner stoves, which might be a drawback for backpackers.</li>
<li className="ListItemStyle"><b>Price:</b> Higher cost compared to basic camping stoves or portable grills.</li>
<li className="ListItemStyle"><b>Setup Time:</b> Requires more setup time than simpler cooking devices.</li>
<li className="ListItemStyle"><b>Cleaning:</b> Can be more challenging to clean due to its multiple components and cooking surfaces.</li>
<li className="ListItemStyle"><b>Space:</b> Takes up more space in a vehicle or campsite compared to smaller cooking units.</li>
<li className="ListItemStyle"><b>Heat Distribution:</b> Some users report uneven heat distribution in the oven.</li>
<li className="ListItemStyle"><b>Ignition:</b> The ignition system may not always work reliably, necessitating manual lighting.</li>
<li className="ListItemStyle"><b>Accessories:</b> Limited accessories included; additional purchases may be necessary for full functionality.</li>
<li className="ListItemStyle"><b>Maintenance:</b> Requires regular maintenance to ensure long-term performance, which can be more involved than simpler stoves.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default CampChefDeluxeOutdoorCampOvenAndStove;

