import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ZippoFireStarterComboKitPic from '../images/ZippoFireStarterComboKit.png';
import { useState, useEffect} from "react";

function ZippoFireStarterComboKit () {
  const [mobileOpen, setMobileOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{ marginLeft:"20px", marginTop: "150px" , marginRight:"20px"}}>
    <table>
        <tr>
          <td>
          <h1>Zippo Fire Starter Combo Kit</h1>
          </td>
          <td>
            <img alt="Zippo Fire Starter Combo Kit" width="50%" height="50%" src={ZippoFireStarterComboKitPic}/>
          </td>
        </tr>
      </table>
    <Typography align="left" variant="body1">
        The Zippo Fire Starter Combo Kit is designed for outdoor enthusiasts who need a reliable way to start fires in various conditions. The kit typically includes a windproof lighter, fire starter sticks, and a carrying case. Zippo is a well-known brand, recognized for its durable and high-quality products, particularly lighters. This combo kit aims to provide a comprehensive solution for starting fires, whether for camping, hiking, or emergency situations.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Reliability:</b> Zippo lighters are known for their reliability and can work in various weather conditions, including wind and rain.</li>
<li className="ListItemStyle"><b>Durability:</b> Made with high-quality materials, the components of the kit are built to last.</li>
<li className="ListItemStyle"><b>Ease of Use:</b> The fire starter sticks and the lighter are straightforward to use, even for beginners.</li>
<li className="ListItemStyle"><b>Portability:</b> The kit is compact and easy to carry, making it ideal for outdoor adventures.</li>
<li className="ListItemStyle"><b>Refillable Lighter:</b> The Zippo lighter is refillable, which makes it a long-term investment.</li>
<li className="ListItemStyle"><b>Versatility:</b> Suitable for a variety of outdoor activities such as camping, hiking, and emergency preparedness.</li>
<li className="ListItemStyle"><b>Windproof Lighter:</b> The lighter is designed to work in windy conditions, which is a significant advantage in the outdoors.</li>
<li className="ListItemStyle"><b>All-in-One Solution:</b> The combo kit provides everything you need to start a fire, eliminating the need to purchase additional items.</li>
<li className="ListItemStyle"><b>Brand Reputation:</b> Zippo is a trusted brand with a long history of producing quality products.</li>
<li className="ListItemStyle"><b>Safety:</b> The fire starter sticks are designed to burn cleanly and safely, reducing the risk of uncontrolled fires.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Price:</b> The kit can be more expensive compared to other fire-starting solutions.</li>
<li className="ListItemStyle"><b>Maintenance:</b> The Zippo lighter requires regular maintenance, including refilling with lighter fluid and replacing flints.</li>
<li className="ListItemStyle"><b>Weight:</b> While portable, the kit is heavier than some other fire-starting options like matches or a small fire steel.</li>
<li className="ListItemStyle"><b>Lighter Fluid:</b> The need for lighter fluid can be inconvenient and adds an extra item to carry.</li>
<li className="ListItemStyle"><b>Learning Curve:</b> Some users may find the lighter tricky to use initially, especially if they are not familiar with Zippo lighters.</li>
<li className="ListItemStyle"><b>Environmental Impact:</b> The use of lighter fluid and disposable fire starter sticks can have a negative environmental impact.</li>
<li className="ListItemStyle"><b>Size:</b> The kit, while compact, may still take up more space than simpler alternatives.</li>
<li className="ListItemStyle"><b>Fuel Evaporation:</b> Zippo lighters can lose fuel through evaporation, requiring more frequent refills.</li>
<li className="ListItemStyle"><b>Not Waterproof:</b> While the lighter is windproof, it is not waterproof, which could be a drawback in wet conditions.</li>
<li className="ListItemStyle"><b>Limited Fire Starter Sticks:</b> The kit comes with a limited number of fire starter sticks, which may not be sufficient for extended trips.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default ZippoFireStarterComboKit;

