import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import StealthCamRevolver360CellularTrailCameraPic from '../images/StealthCamRevolver360CellularTrailCamera.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';


function StealthCamRevolver360CellularTrailCamera () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Stealth Cam Revolver 360° Cellular Trail Camera</h1>

            <img alt="Stealth Cam Revolver 360° Cellular Trail Camera" height="130px" src={StealthCamRevolver360CellularTrailCameraPic}/>

    <Typography align="left" variant="body1">
        The Stealth Cam Revolver 360° Cellular Trail Camera is a high-tech device designed for wildlife monitoring and security purposes. It features a 360-degree rotating camera to capture images and videos from all angles, ensuring comprehensive coverage of the area. The camera is equipped with cellular connectivity, allowing users to receive images and videos directly to their mobile devices or computers. This trail camera is suitable for both hunters and property owners who need reliable surveillance in remote locations.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>360-Degree Coverage:</b> The rotating camera provides a full 360-degree view, eliminating blind spots and offering comprehensive monitoring.</li>
<li className="ListItemStyle"><b>Cellular Connectivity:</b> Allows for real-time image and video transmission to your devices, enabling remote monitoring.</li>
<li className="ListItemStyle"><b>High-Resolution Imaging:</b> Captures high-quality images and videos, which are crucial for identifying wildlife or intruders.</li>
<li className="ListItemStyle"><b>Night Vision:</b> Equipped with infrared LEDs for clear night-time images without disturbing wildlife.</li>
<li className="ListItemStyle"><b>Durable Design:</b> Built to withstand harsh weather conditions, making it suitable for outdoor use.</li>
<li className="ListItemStyle"><b>User-Friendly App:</b> The accompanying app is intuitive, allowing easy setup and management of the camera's settings.</li>
<li className="ListItemStyle"><b>Long Battery Life:</b> Designed to operate for extended periods without frequent battery changes.</li>
<li className="ListItemStyle"><b>Motion Detection:</b> Features advanced motion sensors to trigger recording, ensuring you capture important events.</li>
<li className="ListItemStyle"><b>Versatile Mounting Options:</b> Can be mounted on various surfaces, providing flexibility in placement.</li>
<li className="ListItemStyle"><b>Data Storage Options:</b> Offers both cloud storage and local storage options, providing flexibility in managing captured data.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Cost:</b> The initial purchase price and potential subscription fees for cellular service can be high.</li>
<li className="ListItemStyle"><b>Complex Setup:</b> Initial setup may be complicated for users unfamiliar with technology.</li>
<li className="ListItemStyle"><b>Cellular Coverage Dependency:</b> Requires a strong cellular signal to function optimally, which may be an issue in remote areas.</li>
<li className="ListItemStyle"><b>Data Usage:</b> Cellular data plans can become expensive, especially if the camera captures a large volume of images and videos.</li>
<li className="ListItemStyle"><b>Limited Battery in Cold Weather:</b> Battery life may be reduced in extremely cold conditions.</li>
<li className="ListItemStyle"><b>Potential for False Triggers:</b> Motion sensors might trigger on non-target movements, such as wind-blown foliage.</li>
<li className="ListItemStyle"><b>Size and Weight:</b> Larger and heavier than some other trail cameras, which may limit portability.</li>
<li className="ListItemStyle"><b>Security Concerns:</b> As with any connected device, there is a risk of hacking or unauthorized access.</li>
<li className="ListItemStyle"><b>Maintenance:</b> Regular maintenance is required to ensure the camera remains functional, especially in harsh environments.</li>
<li className="ListItemStyle"><b>Subscription Requirement:</b> Full functionality may require a subscription to a cellular service plan, adding to ongoing costs.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default StealthCamRevolver360CellularTrailCamera;

