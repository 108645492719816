import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useState, useEffect} from "react";
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import GarminETrex22xHandheldGPSUnitPic from './images/GarminETrex22xHandheldGPSUnit.png';
import GarminETrexSEHandheldGPSNavigationUnitPic from './images/GarminETrexSEHandheldGPSNavigationUnit.png';
import GarminGPSMAP64sxHandheldGPSWithNavigationSensorsPic from './images/GarminGPSMAP64sxHandheldGPSWithNavigationSensors.png';
import GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnologyPic from './images/GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology.png';
import GarminGPSMAP67HandheldGPSPic from './images/GarminGPSMAP67HandheldGPS.png';



function HandheldGPS () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.garmin.com/en-US/p/669244",
      imageAlt:"Garmin ETrex 22x Handheld GPS Unit",
      imageRef:GarminETrex22xHandheldGPSUnitPic,
      itemLink:"../Electronics/HandheldGPS/GarminETrex22xHandheldGPSUnit",
      itemBoldText:"Garmin ETrex 22x Handheld GPS Unit:",
      itemText:"The Garmin eTrex 22x is a rugged and reliable handheld GPS unit designed for outdoor enthusiasts. It features a 2.2-inch sunlight-readable color display and comes preloaded with TopoActive maps, which include routable roads and trails for cycling and hiking. The device supports GPS and GLONASS satellite systems, offering enhanced tracking in challenging environments. With 8 GB of internal memory and a microSD card slot, users can store additional maps and data. The eTrex 22x is built to withstand harsh conditions, making it a popular choice for hikers, campers, and adventurers."
    },
	    {
      manuLink:"https://www.garmin.com/en-US/p/835742",
      imageAlt:"Garmin ETrex SE Handheld GPS Navigation Unit",
      imageRef:GarminETrexSEHandheldGPSNavigationUnitPic,
      itemLink:"../Electronics/HandheldGPS/GarminETrexSEHandheldGPSNavigationUnit",
      itemBoldText:"Garmin ETrex SE Handheld GPS Navigation Unit:",
      itemText:"The Garmin eTrex SE is a compact, rugged, and reliable handheld GPS navigation unit designed for outdoor enthusiasts. It features a high-sensitivity GPS receiver, a durable design, and a user-friendly interface. The device is suitable for activities such as hiking, geocaching, and other outdoor adventures. It offers essential navigation features, including waypoint marking, route tracking, and a built-in compass. The eTrex SE is known for its long battery life and ability to perform well in challenging environments."
    },
	    {
      manuLink:"https://www.garmin.com/en-US/p/669284",
      imageAlt:"Garmin GPSMAP 64sx Handheld GPS With Navigation Sensors",
      imageRef:GarminGPSMAP64sxHandheldGPSWithNavigationSensorsPic,
      itemLink:"../Electronics/HandheldGPS/GarminGPSMAP64sxHandheldGPSWithNavigationSensors",
      itemBoldText:"Garmin GPSMAP 64sx Handheld GPS With Navigation Sensors:",
      itemText:"The Garmin GPSMAP 64sx is a rugged, handheld GPS device designed for outdoor enthusiasts. It features a high-sensitivity GPS and GLONASS receiver, a quad-helix antenna for superior reception, and a 2.6-inch sunlight-readable color display. The device includes built-in navigation sensors such as a 3-axis compass and barometric altimeter, and it supports wireless connectivity for data sharing and smart notifications. The GPSMAP 64sx is preloaded with TopoActive maps and offers expandable storage via a microSD card slot."
    },
	    {
      manuLink:"https://www.garmin.com/en-US/p/623975",
      imageAlt:"Garmin GPSMAP 66i GPS Handheld And Satellite Communicator With InReach Technology",
      imageRef:GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnologyPic,
      itemLink:"../Electronics/HandheldGPS/GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology",
      itemBoldText:"Garmin GPSMAP 66i GPS Handheld And Satellite Communicator With InReach Technology:",
      itemText:"The Garmin GPSMAP 66i is a versatile GPS handheld device that combines advanced GPS navigation with satellite communication capabilities, thanks to its integration with inReach technology. This device is designed for outdoor enthusiasts, adventurers, and professionals who require reliable navigation and communication tools in remote areas. It offers features such as topographic maps, weather updates, two-way messaging, SOS alerts, and more, making it a comprehensive tool for various outdoor activities."
    },
	    {
      manuLink:"https://www.garmin.com/en-US/p/890189",
      imageAlt:"Garmin GPSMAP 67 Handheld GPS",
      imageRef:GarminGPSMAP67HandheldGPSPic,
      itemLink:"../Electronics/HandheldGPS/GarminGPSMAP67HandheldGPS",
      itemBoldText:"Garmin GPSMAP 67 Handheld GPS:",
      itemText:"The Garmin GPSMAP 67 is a rugged and versatile handheld GPS device designed for outdoor enthusiasts, hikers, hunters, and geocachers. It offers advanced navigation features, robust connectivity options, and a durable design to withstand harsh environments. Equipped with multi-band GNSS support, preloaded maps, and a long battery life, the GPSMAP 67 aims to be a reliable companion for all your outdoor adventures."
    },
	
    ];

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
      <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1">

	<h1>Handheld GPS</h1>
	<br/>
  Handheld GPS devices are a fun tool to have in the outdoors. They can help you navigate from point A to point B and mark items along the way. Do you need help getting back to your truck? A handheld GPS can help make that happen. Below are some great options for your next handheld device. <i>(Note: never rely solely on electronic devices when in the outdoors. Electronic devices can fail, so always have a backup.)</i>

    </Typography>
    <br/>

    {items.map((element)=> (
      <div>
    <Paper elevation={3}
      sx={{
        p: 2,
        margin: 'auto',
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
    <Grid container spacing={12} rowSpacing={1}>
    <Grid item>
    <Link href={element.menuLink} target="_blank">
      <ButtonBase sx={{ width: 128, height: 128 }}>
      <img alt={element.imageAlt} height="160px" className='GridImage' src={element.imageRef} />
      </ButtonBase>
      </Link>
    </Grid>
    <Grid item xs={12} sm container>
      <Grid item xs container direction="column" spacing={9}>
        <Grid item xs>
          <Typography gutterBottom variant="subtitle1" component="div">
          <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
          
          </Typography>
          <Typography align="center">
            <Link href={element.manuLink} target="_blank">
          <Button variant="contained">More Product Info</Button>
          </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  </Paper>
  <br/>
  </div>
  ))}

    </div>
    </>
  );
}

export default HandheldGPS;

