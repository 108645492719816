import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Pursuit7PieceKnifeSetPic from '../images/Pursuit7PieceKnifeSet.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function Pursuit7PieceKnifeSet () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Pursuit 7-Piece Knife Set</h1>

            <img alt="Pursuit 7-Piece Knife Set" height="130px" src={Pursuit7PieceKnifeSetPic}/>

    <Typography align="left" variant="body1">
        The Pursuit 7-Piece Knife Set is a comprehensive collection designed for both amateur cooks and professional chefs. This set typically includes a variety of knives such as a chef's knife, bread knife, utility knife, paring knife, and more, along with a knife block or storage solution. The knives are crafted from high-quality materials, often featuring stainless steel blades and ergonomic handles for comfortable use. The set aims to provide versatility and durability, making it a valuable addition to any kitchen.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Variety of Knives:</b> The set includes multiple types of knives, catering to a wide range of kitchen tasks from chopping and slicing to dicing and peeling.</li>
<li className="ListItemStyle"><b>High-Quality Materials:</b> Often made from durable stainless steel, the knives are resistant to rust and corrosion, ensuring longevity.</li>
<li className="ListItemStyle"><b>Ergonomic Handles:</b> Designed for comfort, the handles reduce hand fatigue during extended use.</li>
<li className="ListItemStyle"><b>Sharpness:</b> The blades are typically very sharp out of the box, allowing for precise and efficient cutting.</li>
<li className="ListItemStyle"><b>Aesthetic Appeal:</b> The set often comes with a stylish knife block or storage solution, adding a touch of elegance to the kitchen.</li>
<li className="ListItemStyle"><b>Balance and Weight:</b> Well-balanced knives make for easier handling and better control.</li>
<li className="ListItemStyle"><b>Easy Maintenance:</b> Stainless steel blades are generally easy to clean and maintain.</li>
<li className="ListItemStyle"><b>Safety Features:</b> Some sets include safety features like blade guards or non-slip handles.</li>
<li className="ListItemStyle"><b>Versatility:</b> Suitable for a variety of cooking styles and techniques, making it a versatile addition to any kitchen.</li>
<li className="ListItemStyle"><b>Gift-Worthy:</b> The set makes for an excellent gift for culinary enthusiasts or newlyweds.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Price:</b> High-quality knife sets can be expensive, which might be a deterrent for budget-conscious buyers.</li>
<li className="ListItemStyle"><b>Maintenance:</b> Despite being easy to clean, the knives may require regular sharpening to maintain their edge.</li>
<li className="ListItemStyle"><b>Storage Space:</b> The knife block or storage solution can take up considerable counter space.</li>
<li className="ListItemStyle"><b>Weight:</b> Some users might find the knives too heavy, making them difficult to handle for extended periods.</li>
<li className="ListItemStyle"><b>Handle Material:</b> If the handles are not made from high-quality materials, they can wear out or become slippery when wet.</li>
<li className="ListItemStyle"><b>Blade Fragility:</b> Thin, sharp blades can be prone to chipping or breaking if not used properly.</li>
<li className="ListItemStyle"><b>Not Dishwasher Safe:</b> Many high-quality knives are recommended to be hand-washed only, which can be inconvenient.</li>
<li className="ListItemStyle"><b>Overkill for Some Users:</b> Casual cooks might find the variety of knives unnecessary and prefer a simpler set.</li>
<li className="ListItemStyle"><b>Initial Sharpness:</b> While sharp out of the box, some users report that the knives can lose their edge relatively quickly.</li>
<li className="ListItemStyle"><b>Brand Dependence:</b> Quality can vary significantly between brands, so it's essential to research and choose a reputable manufacturer.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default Pursuit7PieceKnifeSet;

