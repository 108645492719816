import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnologyPic from '../images/GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Garmin GPSMAP 66i GPS Handheld And Satellite Communicator With InReach Technology</h1>

            <img alt="Garmin GPSMAP 66i GPS Handheld And Satellite Communicator With InReach Technology" height="130px" src={GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnologyPic}/>

    <Typography align="left" variant="body1">
        The Garmin GPSMAP 66i is a versatile GPS handheld device that combines advanced GPS navigation with satellite communication capabilities, thanks to its integration with inReach technology. This device is designed for outdoor enthusiasts, adventurers, and professionals who require reliable navigation and communication tools in remote areas. It offers features such as topographic maps, weather updates, two-way messaging, SOS alerts, and more, making it a comprehensive tool for various outdoor activities.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>InReach Technology:</b> Provides two-way messaging and SOS alerts via the Iridium satellite network, ensuring communication even in remote areas without cellular coverage.</li>
<li className="ListItemStyle"><b>Robust Navigation:</b> Includes preloaded TOPO maps, multi-GNSS support (GPS, GLONASS, and Galileo), and a 3-axis compass for accurate navigation.</li>
<li className="ListItemStyle"><b>Weather Updates:</b> Offers real-time weather forecasts, including animated weather radar, which is crucial for planning and safety.</li>
<li className="ListItemStyle"><b>Durability:</b> Built to military standards for thermal, shock, and water performance (MIL-STD-810), making it suitable for harsh environments.</li>
<li className="ListItemStyle"><b>Battery Life:</b> Provides up to 35 hours of battery life in standard mode and up to 200 hours in expedition mode, reducing the need for frequent recharging.</li>
<li className="ListItemStyle"><b>Large Display:</b> Features a 3-inch sunlight-readable color display, which is easy to read in various lighting conditions.</li>
<li className="ListItemStyle"><b>Mapping Options:</b> Supports multiple map formats, including BirdsEye Satellite Imagery (subscription required) and custom maps.</li>
<li className="ListItemStyle"><b>Waypoint and Route Management:</b> Allows users to create, store, and navigate waypoints, routes, and tracks efficiently.</li>
<li className="ListItemStyle"><b>Wireless Connectivity:</b> Compatible with Bluetooth and ANT+ for connecting to other devices and sensors, enhancing functionality.</li>
<li className="ListItemStyle"><b>Garmin Explore App:</b> Syncs with the Garmin Explore app for trip planning, data management, and sharing your adventures.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Cost:</b> The device is relatively expensive, which may be a barrier for some users.</li>
<li className="ListItemStyle"><b>Subscription Fees:</b> Requires a subscription for inReach satellite communication services, adding to the overall cost.</li>
<li className="ListItemStyle"><b>Weight:</b> Heavier than some other handheld GPS devices, which might be a concern for ultralight backpackers.</li>
<li className="ListItemStyle"><b>Complexity:</b> The multitude of features can be overwhelming for beginners or those who prefer simpler devices.</li>
<li className="ListItemStyle"><b>Battery Replacement:</b> The built-in rechargeable battery cannot be easily replaced in the field, which could be a drawback on extended trips.</li>
<li className="ListItemStyle"><b>Learning Curve:</b> Users may need time to learn how to use all the features effectively, especially the inReach functions.</li>
<li className="ListItemStyle"><b>Screen Size:</b> While the display is large, some users might prefer an even bigger screen for detailed map viewing.</li>
<li className="ListItemStyle"><b>Signal Dependency:</b> InReach communication relies on satellite visibility, which can be obstructed by dense foliage or deep canyons.</li>
<li className="ListItemStyle"><b>Software Updates:</b> Occasional software updates can be necessary to fix bugs and improve performance, requiring internet access.</li>
<li className="ListItemStyle"><b>Limited Internal Storage:</b> The device has limited internal storage, necessitating the use of a microSD card for additional map data.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology;

