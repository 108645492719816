import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AscendHex0RegularMummySleepingBagPic from '../images/AscendHex0RegularMummySleepingBag.png';
import { useState, useEffect} from "react";

function AscendHex0RegularMummySleepingBag () {
  const [mobileOpen, setMobileOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{ marginLeft:"20px", marginTop: "150px" , marginRight:"20px"}}>
    <table>
        <tr>
          <td>
          <h1>Ascend Hex 0º Regular Mummy Sleeping Bag</h1>
          </td>
          <td>
            <img alt="Ascend Hex 0º Regular Mummy Sleeping Bag" width="50%" height="50%" src={AscendHex0RegularMummySleepingBagPic}/>
          </td>
        </tr>
      </table>
    <Typography align="left" variant="body1">
        The Ascend Hex 0º Regular Mummy Sleeping Bag is designed for cold-weather camping, offering warmth and comfort in temperatures as low as 0ºF. It features a mummy-style design to maximize heat retention, and is filled with synthetic insulation to provide reliable warmth even in damp conditions. The bag includes a durable outer shell, a soft inner lining, and a range of features aimed at enhancing user comfort and convenience.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Warmth:</b> Rated for 0ºF, it provides excellent insulation for cold-weather camping.</li>
<li className="ListItemStyle"><b>Mummy Design:</b> The mummy shape helps to retain body heat more effectively.</li>
<li className="ListItemStyle"><b>Synthetic Insulation:</b> Performs well even when damp, unlike down insulation.</li>
<li className="ListItemStyle"><b>Durable Outer Shell:</b> Made from tough materials that can withstand rough conditions.</li>
<li className="ListItemStyle"><b>Soft Inner Lining:</b> Offers a comfortable and cozy sleeping experience.</li>
<li className="ListItemStyle"><b>Hood and Draft Collar:</b> Helps to seal in warmth around the head and neck.</li>
<li className="ListItemStyle"><b>Compression Sack Included:</b> Makes it easier to pack and carry.</li>
<li className="ListItemStyle"><b>Zipper Guard:</b> Prevents snags and ensures smooth operation.</li>
<li className="ListItemStyle"><b>Affordable Price:</b> Generally more budget-friendly compared to high-end down bags.</li>
<li className="ListItemStyle"><b>Versatility:</b> Suitable for a variety of outdoor activities, including camping, backpacking, and mountaineering.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Weight:</b> Heavier than down sleeping bags, which can be a drawback for backpackers.</li>
<li className="ListItemStyle"><b>Bulkiness:</b> Even with a compression sack, it can be bulkier than some alternatives.</li>
<li className="ListItemStyle"><b>Breathability:</b> Synthetic materials may not breathe as well as natural down.</li>
<li className="ListItemStyle"><b>Less Compressible:</b> Takes up more space in your pack compared to down-filled bags.</li>
<li className="ListItemStyle"><b>Zipper Issues:</b> Some users report that the zipper can be finicky or prone to snagging.</li>
<li className="ListItemStyle"><b>Fit:</b> The mummy design can feel restrictive for some users.</li>
<li className="ListItemStyle"><b>Temperature Rating:</b> While rated for 0ºF, some users may find it less warm in extreme conditions.</li>
<li className="ListItemStyle"><b>Moisture Accumulation:</b> Synthetic materials can sometimes retain moisture, leading to discomfort.</li>
<li className="ListItemStyle"><b>Durability of Inner Lining:</b> The inner lining, while soft, may not be as durable as the outer shell.</li>
<li className="ListItemStyle"><b>Limited Color Options:</b> May not offer a wide range of color choices for those who prefer variety.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default AscendHex0RegularMummySleepingBag;

