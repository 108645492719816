import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MidlandMXT275MicroMobile2WayRadioPic from '../images/MidlandMXT275MicroMobile2WayRadio.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function MidlandMXT275MicroMobile2WayRadio () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Midland MXT275 MicroMobile 2-Way Radio</h1>

            <img alt="Midland MXT275 MicroMobile 2-Way Radio" height="130px" src={MidlandMXT275MicroMobile2WayRadioPic}/>

    <Typography align="left" variant="body1">
        The Midland MXT275 MicroMobile 2-Way Radio is a compact and powerful GMRS (General Mobile Radio Service) radio designed for reliable communication over long distances. It is particularly popular among outdoor enthusiasts, off-roaders, and those who need dependable communication in remote areas. The MXT275 features a fully integrated control microphone, making it easy to install and use in vehicles with limited space. It offers 15 high-power GMRS channels and 8 repeater channels, ensuring extensive coverage and clear communication. The radio also includes NOAA weather channels, allowing users to stay informed about weather conditions.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Compact Design:</b> The all-in-one control microphone design saves space and is ideal for vehicles with limited dashboard space.</li>
<li className="ListItemStyle"><b>High Power Output:</b> With 15 watts of power, it provides a strong signal and extended range, making it suitable for long-distance communication.</li>
<li className="ListItemStyle"><b>NOAA Weather Channels:</b> Access to NOAA weather channels keeps users informed about weather conditions, which is crucial for outdoor activities.</li>
<li className="ListItemStyle"><b>Repeater Capable:</b> The inclusion of 8 repeater channels extends the communication range even further by using repeater stations.</li>
<li className="ListItemStyle"><b>Easy Installation:</b> The radio is designed for simple installation, with a magnetic mount antenna and straightforward wiring.</li>
<li className="ListItemStyle"><b>Clear Audio Quality:</b> Users report clear and crisp audio quality, which is essential for effective communication.</li>
<li className="ListItemStyle"><b>Privacy Codes:</b> The 142 CTCSS/DCS privacy codes help minimize interference from other users on the same channel.</li>
<li className="ListItemStyle"><b>Durability:</b> The radio is built to withstand rugged conditions, making it suitable for off-road and outdoor use.</li>
<li className="ListItemStyle"><b>Versatility:</b> It can be used in various settings, including vehicles, ATVs, and even in base station setups.</li>
<li className="ListItemStyle"><b>User-Friendly Interface:</b> The controls are intuitive and easy to use, even for those who are not familiar with two-way radios.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>License Requirement:</b> Users need an FCC GMRS license to operate the radio legally, which may be a hassle for some.</li>
<li className="ListItemStyle"><b>Limited to GMRS:</b> It only operates on GMRS frequencies, which may not be suitable for all users or applications.</li>
<li className="ListItemStyle"><b>Antenna Limitations:</b> The included magnetic mount antenna may not provide the best performance in all situations, and upgrading can be an additional cost.</li>
<li className="ListItemStyle"><b>No Built-in Battery:</b> The radio requires a 12V power source, limiting its portability compared to handheld models.</li>
<li className="ListItemStyle"><b>Price:</b> It is more expensive than some other two-way radios, which might be a consideration for budget-conscious buyers.</li>
<li className="ListItemStyle"><b>Learning Curve:</b> Some users may find the initial setup and programming of channels and privacy codes to be complex.</li>
<li className="ListItemStyle"><b>Range Limitations:</b> While it offers good range, it is still subject to the limitations of GMRS, such as terrain and obstructions.</li>
<li className="ListItemStyle"><b>No Bluetooth:</b> The lack of Bluetooth connectivity means it cannot be paired with wireless headsets or other Bluetooth devices.</li>
<li className="ListItemStyle"><b>Speaker Quality:</b> Some users report that the built-in speaker could be louder, especially in noisy environments.</li>
<li className="ListItemStyle"><b>Display Size:</b> The small display on the microphone can be hard to read for some users, especially in low-light conditions.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default MidlandMXT275MicroMobile2WayRadio;

