import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";

import JMOMenu from "./components/jmomenu.js";
/* import Layout from "./components/Layout.js"; */
import FloatingDiv from "./components/FloatingDiv.js"

import CampingStoves from "./pages/Camping/CampingStoves.js";
import CampChefProSeriesDeluxe2BurnerCampStove from "./pages/Camping/CampingStoves/CampChefProSeriesDeluxe2BurnerCampStove.js";
import CampChefDeluxeOutdoorCampOvenAndStove from "./pages/Camping/CampingStoves/CampChefDeluxeOutdoorCampOvenAndStove.js";
import CampChefAlpineHeavyDutyCylinderStove from "./pages/Camping/CampingStoves/CampChefAlpineHeavyDutyCylinderStove.js";
import GSIOutdoorsGlacierCampStove from "./pages/Camping/CampingStoves/GSIOutdoorsGlacierCampStove.js";
import CampChefProSeriesDeluxe3BurnerCampStove from "./pages/Camping/CampingStoves/CampChefProSeriesDeluxe3BurnerCampStove.js";

import CampChairs from "./pages/Camping/CampChairs.js";
import GCIOutdoorFreestyleRockerCampChair from "./pages/Camping/CampChairs/GCIOutdoorFreestyleRockerCampChair.js";
import GCIOutdoorKickbackRockerCampChair from "./pages/Camping/CampChairs/GCIOutdoorKickbackRockerCampChair.js";
import AscendRestLightCampChair from "./pages/Camping/CampChairs/AscendRestLightCampChair.js";
import GCIOutdoorStowawayRockerCampChair from "./pages/Camping/CampChairs/GCIOutdoorStowawayRockerCampChair.js";
import OZTENTKingKokodaLoungeCampChair from "./pages/Camping/CampChairs/OZTENTKingKokodaLoungeCampChair.js";

import FireStarters from "./pages/Camping/FireStarters.js";
import FastFireFireStarters from "./pages/Camping/FireStarters/FastFireFireStarters.js";
import ZippoFireStarterComboKit from "./pages/Camping/FireStarters/ZippoFireStarterComboKit.js";
import CoghlanSMagnesiumFireStarter from "./pages/Camping/FireStarters/CoghlanSMagnesiumFireStarter.js";
import NOMADGrillsFireStarters from "./pages/Camping/FireStarters/NOMADGrillsFireStarters.js";
import ZippoTinderShredsFireStarter from "./pages/Camping/FireStarters/ZippoTinderShredsFireStarter.js";

import SleepingBags from "./pages/Camping/SleepingBags.js";
import AscendHex0LongMummySleepingBag from "./pages/Camping/SleepingBags/AscendHex0LongMummySleepingBag.js";
import AscendHoodoo20MummySleepingBag from "./pages/Camping/SleepingBags/AscendHoodoo20MummySleepingBag.js";
import AscendHex0RegularMummySleepingBag from "./pages/Camping/SleepingBags/AscendHex0RegularMummySleepingBag.js";
import StansportGlacier0MummySleepingBag from "./pages/Camping/SleepingBags/StansportGlacier0MummySleepingBag.js";
import StansportGrizzly10CanvasSleepingBag from "./pages/Camping/SleepingBags/StansportGrizzly10CanvasSleepingBag.js";

import HuntingKnives from "./pages/OutdoorGear/HuntingKnives.js";
import HavalonTalonHuntReplaceableBladeKnifeKit from "./pages/OutdoorGear/HuntingKnives/HavalonTalonHuntReplaceableBladeKnifeKit.js";
import OutdoorEdgeButcherMax11PieceGameProcessingSet from "./pages/OutdoorGear/HuntingKnives/OutdoorEdgeButcherMax11PieceGameProcessingSet.js";
import HavalonKnivesPirantaEdgeReplaceableBladeFoldingKnife from "./pages/OutdoorGear/HuntingKnives/HavalonKnivesPirantaEdgeReplaceableBladeFoldingKnife.js";
import OutdoorEdgeSwingBlazeSwingBladeDropPointSkinnerBigGameGuttingKnife from "./pages/OutdoorGear/HuntingKnives/OutdoorEdgeSwingBlazeSwingBladeDropPointSkinnerBigGameGuttingKnife.js";
import Pursuit7PieceKnifeSet from "./pages/OutdoorGear/HuntingKnives/Pursuit7PieceKnifeSet.js";

import HuntingPacks from "./pages/OutdoorGear/HuntingPacks.js";
import BadlandsATX25HuntingPack from "./pages/OutdoorGear/HuntingPacks/BadlandsATX25HuntingPack.js";
import BadlandsRiseHuntingPack from "./pages/OutdoorGear/HuntingPacks/BadlandsRiseHuntingPack.js";
import CabelasMultiDayHuntingPack from "./pages/OutdoorGear/HuntingPacks/CabelasMultiDayHuntingPack.js";
import CabelasVersaHunt80LHuntingPack from "./pages/OutdoorGear/HuntingPacks/CabelasVersaHunt80LHuntingPack.js";
import MuddyProSeriesLumbar500HuntingPack from "./pages/OutdoorGear/HuntingPacks/MuddyProSeriesLumbar500HuntingPack.js";

import HandheldGPS from "./pages/Electronics/HandheldGPS.js";
import GarminETrex22xHandheldGPSUnit from "./pages/Electronics/HandheldGPS/GarminETrex22xHandheldGPSUnit.js";
import GarminETrexSEHandheldGPSNavigationUnit from "./pages/Electronics/HandheldGPS/GarminETrexSEHandheldGPSNavigationUnit.js";
import GarminGPSMAP64sxHandheldGPSWithNavigationSensors from "./pages/Electronics/HandheldGPS/GarminGPSMAP64sxHandheldGPSWithNavigationSensors.js";
import GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology from "./pages/Electronics/HandheldGPS/GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology.js";
import GarminGPSMAP67HandheldGPS from "./pages/Electronics/HandheldGPS/GarminGPSMAP67HandheldGPS.js";

import SatelliteCommunicators from "./pages/Electronics/SatelliteCommunicators.js";
import ACRElectronicsBivyStickSatelliteCommunicationDevice from "./pages/Electronics/SatelliteCommunicators/ACRElectronicsBivyStickSatelliteCommunicationDevice.js";
import GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnologySC from "./pages/Electronics/SatelliteCommunicators/GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology.js";
import GarminGPSMAP67iGPSHandheldAndSatelliteCommunicator from "./pages/Electronics/SatelliteCommunicators/GarminGPSMAP67iGPSHandheldAndSatelliteCommunicator.js";
import GarminInReachMini2SatelliteCommunicator from "./pages/Electronics/SatelliteCommunicators/GarminInReachMini2SatelliteCommunicator.js";
import ZOLEOSatelliteCommunicator from "./pages/Electronics/SatelliteCommunicators/ZOLEOSatelliteCommunicator.js";

import _2WayRadios from "./pages/Electronics/2WayRadios.js";
import Cobra30MileWalkieTalkie2WayRadio2PackSet from "./pages/Electronics/2WayRadios/Cobra30MileWalkieTalkie2WayRadio2PackSet.js";
import MidlandMXT275MicroMobile2WayRadio from "./pages/Electronics/2WayRadios/MidlandMXT275MicroMobile2WayRadio.js";
import MidlandT77VP5XTalkerExtreme2WayRadios2Pack from "./pages/Electronics/2WayRadios/MidlandT77VP5XTalkerExtreme2WayRadios2Pack.js";
import MidlandXTALKERT299VP4Outfitter2WayRadioPack from "./pages/Electronics/2WayRadios/MidlandXTALKERT299VP4Outfitter2WayRadioPack.js";
import MotorolaTalkaboutT1102WayRadios from "./pages/Electronics/2WayRadios/MotorolaTalkaboutT1102WayRadios.js";

import TrailCams from "./pages/OutdoorGear/TrailCams.js";
import CabelasOutfitterG532MPBlackIRTrailCameraCombo from "./pages/OutdoorGear/TrailCams/CabelasOutfitterG532MPBlackIRTrailCameraCombo.js";
import MoultrieMobileEDGECellularTrailCamera from "./pages/OutdoorGear/TrailCams/MoultrieMobileEDGECellularTrailCamera.js";
import SpyPointFlexMCellularTrailCamera2Pack from "./pages/OutdoorGear/TrailCams/SpyPointFlexMCellularTrailCamera2Pack.js";
import StealthCamRevolver360CellularTrailCamera from "./pages/OutdoorGear/TrailCams/StealthCamRevolver360CellularTrailCamera.js";
import TactacamRevealXProCellularTrailCamera from "./pages/OutdoorGear/TrailCams/TactacamRevealXProCellularTrailCamera.js";


function App() {

  window.addEventListener("orientationchange", (event) => {
    //window.location.reload(true);
    console.log(
      `the orientation of the device is now ${event.target.screen.orientation.angle}`,
    );
  });


  return (


		<BrowserRouter>
    
    <JMOMenu />
      {/* <FloatingDiv dataAdSlot='3584139985'></FloatingDiv> */}
      <Routes>
        {/* <Route index element={<Layout><Home /></Layout>}></Route>           */}
        <Route index element={<Home />}></Route>  
          <Route path="about" element={<About />}></Route>
          <Route path="contact" element={<Contact />}></Route>


          <Route path="Camping/CampingStoves" element={<CampingStoves />}></Route>
          <Route path="Camping/CampingStoves/CampChefProSeriesDeluxe2BurnerCampStove" element={<CampChefProSeriesDeluxe2BurnerCampStove />}></Route>
          <Route path="Camping/CampingStoves/CampChefDeluxeOutdoorCampOvenAndStove" element={<CampChefDeluxeOutdoorCampOvenAndStove />}></Route>
          <Route path="Camping/CampingStoves/CampChefAlpineHeavyDutyCylinderStove" element={<CampChefAlpineHeavyDutyCylinderStove />}></Route>
          <Route path="Camping/CampingStoves/GSIOutdoorsGlacierCampStove" element={<GSIOutdoorsGlacierCampStove />}></Route>
          <Route path="Camping/CampingStoves/CampChefProSeriesDeluxe3BurnerCampStove" element={<CampChefProSeriesDeluxe3BurnerCampStove />}></Route>

          <Route path="Camping/CampChairs" element={<CampChairs />}></Route>
          <Route path="Camping/CampChairs/GCIOutdoorFreestyleRockerCampChair" element={<GCIOutdoorFreestyleRockerCampChair />}></Route>
          <Route path="Camping/CampChairs/GCIOutdoorKickbackRockerCampChair" element={<GCIOutdoorKickbackRockerCampChair />}></Route>
          <Route path="Camping/CampChairs/AscendRestLightCampChair" element={<AscendRestLightCampChair />}></Route>
          <Route path="Camping/CampChairs/GCIOutdoorStowawayRockerCampChair" element={<GCIOutdoorStowawayRockerCampChair />}></Route>
          <Route path="Camping/CampChairs/OZTENTKingKokodaLoungeCampChair" element={<OZTENTKingKokodaLoungeCampChair />}></Route>

          <Route path="Camping/FireStarters" element={<FireStarters />}></Route>
          <Route path="Camping/FireStarters/FastFireFireStarters" element={<FastFireFireStarters />}></Route>
          <Route path="Camping/FireStarters/ZippoFireStarterComboKit" element={<ZippoFireStarterComboKit />}></Route>
          <Route path="Camping/FireStarters/CoghlanSMagnesiumFireStarter" element={<CoghlanSMagnesiumFireStarter />}></Route>
          <Route path="Camping/FireStarters/NOMADGrillsFireStarters" element={<NOMADGrillsFireStarters />}></Route>
          <Route path="Camping/FireStarters/ZippoTinderShredsFireStarter" element={<ZippoTinderShredsFireStarter />}></Route>

          <Route path="Camping/SleepingBags" element={<SleepingBags />}></Route>   
          <Route path="Camping/SleepingBags/AscendHex0LongMummySleepingBag" element={<AscendHex0LongMummySleepingBag />}></Route>
          <Route path="Camping/SleepingBags/AscendHoodoo20MummySleepingBag" element={<AscendHoodoo20MummySleepingBag />}></Route>
          <Route path="Camping/SleepingBags/AscendHex0RegularMummySleepingBag" element={<AscendHex0RegularMummySleepingBag />}></Route>
          <Route path="Camping/SleepingBags/StansportGlacier0MummySleepingBag" element={<StansportGlacier0MummySleepingBag />}></Route>
          <Route path="Camping/SleepingBags/StansportGrizzly10CanvasSleepingBag" element={<StansportGrizzly10CanvasSleepingBag />}></Route>     

          <Route path="OutdoorGear/HuntingKnives" element={<HuntingKnives />}></Route>   
          <Route path="OutdoorGear/HuntingKnives/HavalonTalonHuntReplaceableBladeKnifeKit" element={<HavalonTalonHuntReplaceableBladeKnifeKit />}></Route>
          <Route path="OutdoorGear/HuntingKnives/OutdoorEdgeButcherMax11PieceGameProcessingSet" element={<OutdoorEdgeButcherMax11PieceGameProcessingSet />}></Route>
          <Route path="OutdoorGear/HuntingKnives/HavalonKnivesPirantaEdgeReplaceableBladeFoldingKnife" element={<HavalonKnivesPirantaEdgeReplaceableBladeFoldingKnife />}></Route>
          <Route path="OutdoorGear/HuntingKnives/OutdoorEdgeSwingBlazeSwingBladeDropPointSkinnerBigGameGuttingKnife" element={<OutdoorEdgeSwingBlazeSwingBladeDropPointSkinnerBigGameGuttingKnife />}></Route>
          <Route path="OutdoorGear/HuntingKnives/Pursuit7PieceKnifeSet" element={<Pursuit7PieceKnifeSet />}></Route>

          <Route path="OutdoorGear/HuntingPacks" element={<HuntingPacks />}></Route>   
          <Route path="OutdoorGear/HuntingPacks/BadlandsATX25HuntingPack" element={<BadlandsATX25HuntingPack />}></Route>
          <Route path="OutdoorGear/HuntingPacks/BadlandsRiseHuntingPack" element={<BadlandsRiseHuntingPack />}></Route>
          <Route path="OutdoorGear/HuntingPacks/CabelasMultiDayHuntingPack" element={<CabelasMultiDayHuntingPack />}></Route>
          <Route path="OutdoorGear/HuntingPacks/CabelasVersaHunt80LHuntingPack" element={<CabelasVersaHunt80LHuntingPack />}></Route>
          <Route path="OutdoorGear/HuntingPacks/MuddyProSeriesLumbar500HuntingPack" element={<MuddyProSeriesLumbar500HuntingPack />}></Route>

          <Route path="Electronics/HandheldGPS" element={<HandheldGPS />}></Route>  
          <Route path="Electronics/HandheldGPS/GarminETrex22xHandheldGPSUnit" element={<GarminETrex22xHandheldGPSUnit />}></Route>
          <Route path="Electronics/HandheldGPS/GarminETrexSEHandheldGPSNavigationUnit" element={<GarminETrexSEHandheldGPSNavigationUnit />}></Route>
          <Route path="Electronics/HandheldGPS/GarminGPSMAP64sxHandheldGPSWithNavigationSensors" element={<GarminGPSMAP64sxHandheldGPSWithNavigationSensors />}></Route>
          <Route path="Electronics/HandheldGPS/GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology" element={<GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology />}></Route>
          <Route path="Electronics/HandheldGPS/GarminGPSMAP67HandheldGPS" element={<GarminGPSMAP67HandheldGPS />}></Route>

          <Route path="Electronics/SatelliteCommunicators" element={<SatelliteCommunicators />}></Route>  
          <Route path="Electronics/SatelliteCommunicators/ACRElectronicsBivyStickSatelliteCommunicationDevice" element={<ACRElectronicsBivyStickSatelliteCommunicationDevice />}></Route>
          <Route path="Electronics/SatelliteCommunicators/GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology" element={<GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnologySC />}></Route>
          <Route path="Electronics/SatelliteCommunicators/GarminGPSMAP67iGPSHandheldAndSatelliteCommunicator" element={<GarminGPSMAP67iGPSHandheldAndSatelliteCommunicator />}></Route>
          <Route path="Electronics/SatelliteCommunicators/GarminInReachMini2SatelliteCommunicator" element={<GarminInReachMini2SatelliteCommunicator />}></Route>
          <Route path="Electronics/SatelliteCommunicators/ZOLEOSatelliteCommunicator" element={<ZOLEOSatelliteCommunicator />}></Route>

          <Route path="Electronics/2WayRadios" element={<_2WayRadios />}></Route>  
          <Route path="Electronics/2WayRadios/Cobra30MileWalkieTalkie2WayRadio2PackSet" element={<Cobra30MileWalkieTalkie2WayRadio2PackSet />}></Route>
          <Route path="Electronics/2WayRadios/MidlandMXT275MicroMobile2WayRadio" element={<MidlandMXT275MicroMobile2WayRadio />}></Route>
          <Route path="Electronics/2WayRadios/MidlandT77VP5XTalkerExtreme2WayRadios2Pack" element={<MidlandT77VP5XTalkerExtreme2WayRadios2Pack />}></Route>
          <Route path="Electronics/2WayRadios/MidlandXTALKERT299VP4Outfitter2WayRadioPack" element={<MidlandXTALKERT299VP4Outfitter2WayRadioPack />}></Route>
          <Route path="Electronics/2WayRadios/MotorolaTalkaboutT1102WayRadios" element={<MotorolaTalkaboutT1102WayRadios />}></Route>

          <Route path="OutdoorGear/TrailCams" element={<TrailCams />}></Route>  
          <Route path="OutdoorGear/TrailCams/CabelasOutfitterG532MPBlackIRTrailCameraCombo" element={<CabelasOutfitterG532MPBlackIRTrailCameraCombo />}></Route>
          <Route path="OutdoorGear/TrailCams/MoultrieMobileEDGECellularTrailCamera" element={<MoultrieMobileEDGECellularTrailCamera />}></Route>
          <Route path="OutdoorGear/TrailCams/SpyPointFlexMCellularTrailCamera2Pack" element={<SpyPointFlexMCellularTrailCamera2Pack />}></Route>
          <Route path="OutdoorGear/TrailCams/StealthCamRevolver360CellularTrailCamera" element={<StealthCamRevolver360CellularTrailCamera />}></Route>
          <Route path="OutdoorGear/TrailCams/TactacamRevealXProCellularTrailCamera" element={<TactacamRevealXProCellularTrailCamera />}></Route>


        </Routes>
		</BrowserRouter>

    
  );
}

export default App;