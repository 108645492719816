import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import GarminGPSMAP64sxHandheldGPSWithNavigationSensorsPic from '../images/GarminGPSMAP64sxHandheldGPSWithNavigationSensors.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function GarminGPSMAP64sxHandheldGPSWithNavigationSensors () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

<div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Garmin GPSMAP 64sx Handheld GPS With Navigation Sensors</h1>

            <img alt="Garmin GPSMAP 64sx Handheld GPS With Navigation Sensors" height="130px" src={GarminGPSMAP64sxHandheldGPSWithNavigationSensorsPic}/>

    <Typography align="left" variant="body1">
        The Garmin GPSMAP 64sx is a rugged, handheld GPS device designed for outdoor enthusiasts. It features a high-sensitivity GPS and GLONASS receiver, a quad-helix antenna for superior reception, and a 2.6-inch sunlight-readable color display. The device includes built-in navigation sensors such as a 3-axis compass and barometric altimeter, and it supports wireless connectivity for data sharing and smart notifications. The GPSMAP 64sx is preloaded with TopoActive maps and offers expandable storage via a microSD card slot.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>High-Sensitivity Receiver:</b> The GPSMAP 64sx uses both GPS and GLONASS satellites for faster and more accurate positioning.</li>
<li className="ListItemStyle"><b>Rugged Design:</b> Built to withstand harsh outdoor conditions, it is water-resistant and durable.</li>
<li className="ListItemStyle"><b>Navigation Sensors:</b> Includes a 3-axis compass and barometric altimeter for better navigation and altitude tracking.</li>
<li className="ListItemStyle"><b>Preloaded Maps:</b> Comes with TopoActive maps, providing detailed information for outdoor activities.</li>
<li className="ListItemStyle"><b>Expandable Storage:</b> Supports microSD cards for additional map storage.</li>
<li className="ListItemStyle"><b>Wireless Connectivity:</b> Allows for data sharing and smart notifications via Bluetooth.</li>
<li className="ListItemStyle"><b>Battery Life:</b> Offers up to 16 hours of battery life in GPS mode, which is suitable for long hikes and trips.</li>
<li className="ListItemStyle"><b>Sunlight-Readable Display:</b> The 2.6-inch color screen is easy to read even in bright sunlight.</li>
<li className="ListItemStyle"><b>Waypoint and Track Management:</b> Allows users to mark waypoints, create routes, and track their journey.</li>
<li className="ListItemStyle"><b>Geocaching Friendly:</b> Supports paperless geocaching, making it easier for users to find and log geocaches.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Price:</b> The GPSMAP 64sx is relatively expensive compared to other handheld GPS units.</li>
<li className="ListItemStyle"><b>Weight:</b> At around 8.1 ounces (230 grams), it is heavier than some other handheld GPS devices.</li>
<li className="ListItemStyle"><b>User Interface:</b> The interface can be somewhat outdated and less intuitive compared to modern touchscreen devices.</li>
<li className="ListItemStyle"><b>Limited Internal Storage:</b> The internal storage is limited, necessitating the use of a microSD card for additional maps.</li>
<li className="ListItemStyle"><b>Battery Type:</b> Uses AA batteries, which can be less convenient than rechargeable batteries.</li>
<li className="ListItemStyle"><b>Screen Size:</b> The 2.6-inch display might be considered small by some users.</li>
<li className="ListItemStyle"><b>No Touchscreen:</b> Lacks a touchscreen, which can make navigation through menus slower.</li>
<li className="ListItemStyle"><b>Learning Curve:</b> Can be complex for beginners to learn and use effectively.</li>
<li className="ListItemStyle"><b>Software Updates:</b> Some users report that software updates can be cumbersome and slow.</li>
<li className="ListItemStyle"><b>Limited Customization:</b> Fewer options for customization compared to some other GPS devices.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GarminGPSMAP64sxHandheldGPSWithNavigationSensors;

