import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SpyPointFlexMCellularTrailCamera2PackPic from '../images/SpyPointFlexMCellularTrailCamera2Pack.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';


function SpyPointFlexMCellularTrailCamera2Pack () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>SpyPoint Flex-M Cellular Trail Camera 2-Pack</h1>

            <img alt="SpyPoint Flex-M Cellular Trail Camera 2-Pack" height="130px" src={SpyPointFlexMCellularTrailCamera2PackPic}/>

    <Typography align="left" variant="body1">
        The SpyPoint Flex-M Cellular Trail Camera is designed for outdoor enthusiasts and hunters who want to monitor wildlife activity remotely. This camera offers cellular connectivity, allowing users to receive images and updates directly to their devices. The Flex-M model is known for its ease of use, reliability, and advanced features like high-resolution image capture and fast trigger speed. The 2-pack option provides added value for those needing coverage in multiple locations.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Cellular Connectivity:</b> Allows for remote monitoring and instant image transmission to your device.</li>
<li className="ListItemStyle"><b>High-Resolution Images:</b> Captures clear and detailed photos, enhancing wildlife observation.</li>
<li className="ListItemStyle"><b>Fast Trigger Speed:</b> Quickly captures images when motion is detected, ensuring you don't miss any action.</li>
<li className="ListItemStyle"><b>Multi-Camera Pack:</b> The 2-pack option provides more coverage and value for larger areas.</li>
<li className="ListItemStyle"><b>User-Friendly Interface:</b> Easy setup and operation, suitable for both beginners and experienced users.</li>
<li className="ListItemStyle"><b>Durable Design:</b> Built to withstand harsh outdoor conditions, ensuring longevity and reliability.</li>
<li className="ListItemStyle"><b>Flexible Data Plans:</b> Offers various subscription options to suit different needs and budgets.</li>
<li className="ListItemStyle"><b>Night Vision Capability:</b> Equipped with infrared LEDs for capturing images in low-light conditions.</li>
<li className="ListItemStyle"><b>App Integration:</b> Compatible with a mobile app for easy access to images and camera settings.</li>
<li className="ListItemStyle"><b>Customizable Settings:</b> Allows users to adjust settings like image resolution and trigger sensitivity.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Subscription Costs:</b> Cellular plans can add ongoing costs to the initial purchase.</li>
<li className="ListItemStyle"><b>Battery Life:</b> Frequent image transmission can drain batteries quickly, requiring regular replacement or recharging.</li>
<li className="ListItemStyle"><b>Signal Dependency:</b> Performance can be affected by poor cellular reception in remote areas.</li>
<li className="ListItemStyle"><b>Limited Video Features:</b> Primarily focused on still images, with limited video capabilities.</li>
<li className="ListItemStyle"><b>Potential for False Triggers:</b> Movement from non-targets like wind-blown vegetation can trigger the camera.</li>
<li className="ListItemStyle"><b>Data Usage:</b> High-resolution images can consume data quickly, potentially leading to higher costs.</li>
<li className="ListItemStyle"><b>Initial Setup Complexity:</b> Some users may find the initial setup and configuration challenging.</li>
<li className="ListItemStyle"><b>App Reliability:</b> Occasional app glitches or connectivity issues can affect user experience.</li>
<li className="ListItemStyle"><b>Cost:</b> Higher initial investment compared to non-cellular trail cameras.</li>
<li className="ListItemStyle"><b>Limited Field of View:</b> May require strategic placement to cover desired areas effectively.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default SpyPointFlexMCellularTrailCamera2Pack;

