import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useState, useEffect} from "react";
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import Cobra30MileWalkieTalkie2WayRadio2PackSetPic from './images/Cobra30MileWalkieTalkie2WayRadio2PackSet.png';
import MidlandMXT275MicroMobile2WayRadioPic from './images/MidlandMXT275MicroMobile2WayRadio.png';
import MidlandT77VP5XTalkerExtreme2WayRadios2PackPic from './images/MidlandT77VP5XTalkerExtreme2WayRadios2Pack.png';
import MidlandXTALKERT299VP4Outfitter2WayRadioPackPic from './images/MidlandXTALKERT299VP4Outfitter2WayRadioPack.png';
import MotorolaTalkaboutT1102WayRadiosPic from './images/MotorolaTalkaboutT1102WayRadios.png';


function _2WayRadios () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      manuLink:"https://www.cobra.com/products/rx380",
      imageAlt:"Cobra 30-Mile Walkie Talkie 2-Way Radio 2-Pack Set",
      imageRef:Cobra30MileWalkieTalkie2WayRadio2PackSetPic,
      itemLink:"../Electronics/2WayRadios/Cobra30MileWalkieTalkie2WayRadio2PackSet",
      itemBoldText:"Cobra 30-Mile Walkie Talkie 2-Way Radio 2-Pack Set:",
      itemText:"The Cobra 30-Mile Walkie Talkie 2-Way Radio 2-Pack Set is designed for outdoor enthusiasts, adventurers, and anyone needing reliable communication over long distances. These radios offer a range of up to 30 miles under optimal conditions and come with a variety of features to enhance usability and performance. They are ideal for hiking, camping, and other outdoor activities where cell phone service may be unreliable."
    },
	    {
      manuLink:"https://midlandusa.com/products/mxt275-micromobile-two-way-radio?avad=220733_d3c60c92d&utm_source=avantlink_wildlink.me&utm_medium=affiliate&utm_campaign=bb_affiliate&utm_content=Custom+Link&utm_term=220733_wildlink.me",
      imageAlt:"Midland MXT275 MicroMobile 2-Way Radio",
      imageRef:MidlandMXT275MicroMobile2WayRadioPic,
      itemLink:"../Electronics/2WayRadios/MidlandMXT275MicroMobile2WayRadio",
      itemBoldText:"Midland MXT275 MicroMobile 2-Way Radio:",
      itemText:"The Midland MXT275 MicroMobile 2-Way Radio is a compact and powerful GMRS (General Mobile Radio Service) radio designed for reliable communication over long distances. It is particularly popular among outdoor enthusiasts, off-roaders, and those who need dependable communication in remote areas. The MXT275 features a fully integrated control microphone, making it easy to install and use in vehicles with limited space. It offers 15 high-power GMRS channels and 8 repeater channels, ensuring extensive coverage and clear communication. The radio also includes NOAA weather channels, allowing users to stay informed about weather conditions."
    },
	    {
      manuLink:"https://midlandusa.com/products/x-talker-t77vp5?avad=220733_b3c636881&utm_source=avantlink_wildlink.me&utm_medium=affiliate&utm_campaign=bb_affiliate&utm_content=Custom+Link&utm_term=220733_wildlink.me",
      imageAlt:"Midland T77VP5 X-Talker Extreme 2-Way Radios 2-Pack",
      imageRef:MidlandT77VP5XTalkerExtreme2WayRadios2PackPic,
      itemLink:"../Electronics/2WayRadios/MidlandT77VP5XTalkerExtreme2WayRadios2Pack",
      itemBoldText:"Midland T77VP5 X-Talker Extreme 2-Way Radios 2-Pack:",
      itemText:"The Midland T77VP5 X-Talker Extreme 2-Way Radios are designed for reliable communication in a variety of outdoor and indoor settings. This 2-pack set of radios offers a range of features that make it suitable for activities such as hiking, camping, and event coordination. The radios come with a range of up to 38 miles in open areas with little or no obstruction, and they include 36 channels and 121 privacy codes to minimize interference. Additional features include NOAA weather alerts, hands-free operation, and a rugged design for durability."
    },
	    {
      manuLink:"https://midlandusa.com/products/x-talker-t295vp4-gmrs-two-way-radio?avad=220733_a3c6470bd&utm_source=avantlink_wildlink.me&utm_medium=affiliate&utm_campaign=bb_affiliate&utm_content=Custom+Link&utm_term=220733_wildlink.me",
      imageAlt:"Midland X-TALKER T299VP4 Outfitter 2-Way Radio Pack",
      imageRef:MidlandXTALKERT299VP4Outfitter2WayRadioPackPic,
      itemLink:"../Electronics/2WayRadios/MidlandXTALKERT299VP4Outfitter2WayRadioPack",
      itemBoldText:"Midland X-TALKER T299VP4 Outfitter 2-Way Radio Pack:",
      itemText:"The Midland X-TALKER T299VP4 Outfitter 2-Way Radio Pack is a set of compact, durable, and easy-to-use two-way radios designed for outdoor enthusiasts, families, and professionals who need reliable communication in various environments. These radios offer a range of features, including long-range communication, weather alerts, and multiple channels, making them suitable for hiking, camping, hunting, and other outdoor activities."
    },
	    {
      manuLink:"https://www.motorolasolutions.com/en_us/products/two-way-radios/consumer-two-way-radios/talkabout-t210-series.html",
      imageAlt:"Motorola Talkabout T110 2-Way Radios",
      imageRef:MotorolaTalkaboutT1102WayRadiosPic,
      itemLink:"../Electronics/2WayRadios/MotorolaTalkaboutT1102WayRadios",
      itemBoldText:"Motorola Talkabout T110 2-Way Radios:",
      itemText:"The Motorola Talkabout T110 2-Way Radios are compact, easy-to-use communication devices designed for personal and recreational use. These radios are ideal for activities such as hiking, camping, and neighborhood communication. They offer a range of features that make them suitable for keeping in touch with family and friends over short distances."
    },
	
    ];

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
      <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
        
    <Typography align="left" variant="body1">

	<h1>2-Way Radios</h1>
	<br/>
  2-way radios are a great tool to stay connected with your buddies when you are out having fun in the outdoors. Whether you are letting them know where you are, or if you need help with something, it is great to press a button to talk instead of needing to track them down. Below are some great options for you to consider for your next 2-way radio.

    </Typography>
    <br/>

    {items.map((element)=> (
      <div>
    <Paper elevation={3}
      sx={{
        p: 2,
        margin: 'auto',
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
    <Grid container spacing={12} rowSpacing={1}>
    <Grid item>
    <Link href={element.menuLink} target="_blank">
      <ButtonBase sx={{ width: 128, height: 128 }}>
      <img alt={element.imageAlt} height="160px" className='GridImage' src={element.imageRef}/>
      </ButtonBase>
      </Link>
    </Grid>
    <Grid item xs={12} sm container>
      <Grid item xs container direction="column" spacing={9}>
        <Grid item xs>
          <Typography gutterBottom variant="subtitle1" component="div">
          <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
          
          </Typography>
          <Typography align="center">
            <Link href={element.manuLink} target="_blank">
          <Button variant="contained">More Product Info</Button>
          </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  </Paper>
  <br/>
  </div>
  ))}

    </div>
    </>
  );
}

export default _2WayRadios;

