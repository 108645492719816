import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CampChefAlpineHeavyDutyCylinderStovePic from '../images/CampChefAlpineHeavyDutyCylinderStove.png';
import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';

function CampChefAlpineHeavyDutyCylinderStove () {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>


   {/* <div style={{ marginLeft: mobileOpen ? "260px" : "20px", marginTop: mobileOpen ? "150px" : "150px", marginRight:"20px"}}> */}
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>

          <h1>Camp Chef Alpine Heavy Duty Cylinder Stove</h1>

            <img alt="Camp Chef Alpine Heavy Duty Cylinder Stove" height="130px" src={CampChefAlpineHeavyDutyCylinderStovePic}/>

    <Typography align="left" variant="body1">
        The Camp Chef Alpine Heavy Duty Cylinder Stove is a robust and versatile wood-burning stove designed for outdoor use, particularly in camping and hunting scenarios. It is constructed from durable steel and features a large cooking surface, making it ideal for heating tents and cooking meals in the wilderness. The stove includes adjustable legs for uneven ground, a wide door for easy wood loading, and a chimney pipe for efficient smoke ventilation.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1">
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>
          <list>
              <ul>
				<li className="ListItemStyle"><b>Durability:</b> Made from heavy-duty steel, the stove is built to withstand harsh outdoor conditions and frequent use.</li>
<li className="ListItemStyle"><b>Large Cooking Surface:</b> The spacious top allows for multiple pots and pans, making it convenient for cooking large meals.</li>
<li className="ListItemStyle"><b>Adjustable Legs:</b> The legs can be adjusted to stabilize the stove on uneven ground, ensuring safety and stability.</li>
<li className="ListItemStyle"><b>Efficient Heating:</b> The stove generates substantial heat, making it effective for warming up large tents or shelters.</li>
<li className="ListItemStyle"><b>Wide Door:</b> The large door makes it easy to load wood and manage the fire, improving user convenience.</li>
<li className="ListItemStyle"><b>Chimney Pipe:</b> The included chimney pipe helps to efficiently vent smoke, reducing the risk of smoke inhalation inside the tent.</li>
<li className="ListItemStyle"><b>Versatility:</b> Suitable for various outdoor activities such as camping, hunting, and emergency preparedness.</li>
<li className="ListItemStyle"><b>Portability:</b> Despite its heavy-duty construction, the stove is relatively portable and can be disassembled for easier transport.</li>
<li className="ListItemStyle"><b>Ash Cleanout:</b> Features an ash cleanout tray for easy maintenance and cleaning.</li>
<li className="ListItemStyle"><b>Accessory Compatibility:</b> Compatible with various Camp Chef accessories, allowing for customization and enhanced functionality.</li>

              </ul>
          </list>
        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>
          <list>
              <ul>
			  <li className="ListItemStyle"><b>Weight:</b> The heavy-duty steel construction makes the stove quite heavy, which can be a drawback for backpacking or solo camping.</li>
<li className="ListItemStyle"><b>Assembly Required:</b> Initial setup and assembly can be time-consuming and may require tools.</li>
<li className="ListItemStyle"><b>Size:</b> The stove's large size can be cumbersome to transport and may take up significant space in a vehicle.</li>
<li className="ListItemStyle"><b>Price:</b> It is relatively expensive compared to other portable stoves, which might be a consideration for budget-conscious buyers.</li>
<li className="ListItemStyle"><b>Fuel Consumption:</b> Burns through wood relatively quickly, requiring a steady supply of fuel.</li>
<li className="ListItemStyle"><b>Heat Control:</b> Limited ability to finely control the heat output compared to gas or electric stoves.</li>
<li className="ListItemStyle"><b>Rust Potential:</b> If not properly maintained, the steel can rust, especially in humid or wet conditions.</li>
<li className="ListItemStyle"><b>Ventilation Needs:</b> Requires proper ventilation to avoid smoke buildup, which can be challenging in certain setups.</li>
<li className="ListItemStyle"><b>Learning Curve:</b> May require some practice to efficiently manage the fire and maintain consistent heat.</li>
<li className="ListItemStyle"><b>Bulkiness:</b> Even when disassembled, the components can be bulky and may not fit easily into smaller vehicles or storage spaces.</li>
			  
              </ul>
          </list>
        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default CampChefAlpineHeavyDutyCylinderStove;

